import { useCallback, useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PromosStyle from "styles/promosStyles";
import { Card, Col, Form, Input, Row, Switch, message, DatePicker } from "antd";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import {
  DetailHeader,
} from "components";
import SvgBlogIcon from "components/icons/BlogIcon";
import moment from "moment";
import frLocale from 'antd/es/date-picker/locale/fr_FR';
import { PROMO_TYPES } from "../../constants";
import { createPromoCodeItem, getPromoDetail, patchPromoItem } from "services/promos";

const today = new Date();
today.setHours(0, 0, 0, 0);

const PromoDetail = () => {
  const { t } = useTranslation(["common", "promos"]);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const [isFetchingTag, setIsFetchingTag] = useState(false);

  const history = useHistory();

  const newRecord = useCallback(() => id === "nouvelle", [id]);

  const fetchRecord = () => {
    if (!newRecord()) {
      setIsFetchingTag(true);

      getPromoDetail(id).then(r => {
        setInitialValues({
          total: r.discountAmount,
          maxUsers: r.maxPax,
          minPurchaseAmt: r.minAmount,
          startDate: (r.startDate && new Date(r.startDate)) || null,
          endDate: (r.endDate && new Date(r.endDate)) || null,
          codeName: r.code,
          valid: r.isVisible,
        })
      }).catch(() => {
        message.error(t`common:errors.commonError`, 10);
        history.push("/promos");
      }).finally(() => {
        setIsFetchingTag(false);
      });
    }
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  const validationSchema = yup.object().shape({
    total: yup
      .number()
      .required(t`common:errors.requiredField`)
      .typeError(t`common:errors.typeErrorNumber`)
      .min(0, t("common:errors.minNum", {count: 0}))
      .max(99999, t("common:errors.maxNum", {count: 99999}))
      .test(
        "maxDigitsAfterDecimal",
        t("common:errors.decimalPlaces", {count: 2}),
        (val) => /^\d+(\.\d{1,2})?$/.test(`${val}`)
      ),
    maxUsers: yup
      .number()
      .required(t`common:errors.requiredField`)
      .integer(t`common:errors.integer`)
      .typeError(t`common:errors.typeErrorNumber`)
      .min(0, t("common:errors.minNum", {count: 0}))
      .max(99999, t("common:errors.maxNum", {count: 99999})),
    minPurchaseAmt: yup
      .number()
      .required(t`common:errors.requiredField`)
      .typeError(t`common:errors.typeErrorNumber`)
      .min(0, t("common:errors.minNum", {count: 0}))
      .max(99999, t("common:errors.maxNum", {count: 99999}))
      .test(
        "maxDigitsAfterDecimal",
        t("common:errors.decimalPlaces", {count: 2}),
        (val) => /^\d+(\.\d{1,2})?$/.test(`${val}`)
      ),
    startDate: yup
      .date()
      .required(t`common:errors.requiredField`)
      .nullable()
      .default(() => new Date())
      // .min(today, t`common:errors.todayOrLater`)
      .max(yup.ref('endDate'), t`common:errors.beforeEndDate`),
    endDate: yup
      .date()
      .required(t`common:errors.requiredField`)
      .nullable()
      .min(yup.ref('startDate'), t`common:errors.afterStartDate`)
      .test(
        "endDate",
        t`common:errors.todayOrLater`,
        (val) => moment(val).isSameOrAfter(today)
      ),
    codeName: yup
      .string()
      .trim()
      .required(t`common:errors.requiredField`)
      .min(1, t("common:errors.minChars", {count: 1}))
      .max(50, t("common:errors.maxChars", {count: 50})),
    valid: yup.boolean(),
  });
  
  return (
    <MainLayout>
      <PromosStyle />
      {
        !isFetchingTag &&
        <Formik
          initialValues={{
            total: initialValues?.total || "",
            maxUsers: initialValues?.maxUsers || "",
            minPurchaseAmt: initialValues?.minPurchaseAmt || "",
            startDate: initialValues?.startDate || new Date(),
            endDate: initialValues?.endDate || new Date(),
            codeName: initialValues?.codeName || "",
            valid: initialValues?.valid ?? true,
          }}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            if (newRecord()) {
              const requestBody = {
                code: values.codeName,
                type: PROMO_TYPES.PROMOTIONAL_CODE,
                discountAmount: +values.total,
                minAmount: +values.minPurchaseAmt,
                maxPax: +values.maxUsers,
                startDate: moment(values.startDate).format("YYYY-MM-DD"),
                endDate: moment(values.endDate).format("YYYY-MM-DD"),
                isVisible: values.valid,
              };

              createPromoCodeItem(requestBody).then((resp) => {
                message.success(t`common:message.success`, 5);
                history.push(`/promos/${resp?.id}`);
              }).catch((e) => {
                if (e?.response?.data?.error?.message === "discount_code_should_be_unique") {
                  message.error(t`promos:detail.promoCode.errors.uniqueCode`, 5);
                } else {
                  message.error(t`common:errors.commonError`, 10);
                }
              }).finally(() => {
                setSubmitting(false);
              });
            } else {
              const requestBody = {
                code: values.codeName,
                type: PROMO_TYPES.PROMOTIONAL_CODE,
                discountAmount: +values.total,
                minAmount: +values.minPurchaseAmt,
                maxPax: +values.maxUsers,
                startDate: moment(values.startDate).format("YYYY-MM-DD"),
                endDate: moment(values.endDate).format("YYYY-MM-DD"),
                isVisible: !!values.valid,
              };

              setIsFetchingTag(true);

              patchPromoItem(id, requestBody).then(() => {
                message.success(t`common:message.success`, 5);
                fetchRecord();
              }).catch(() => {
                message.error(t`common:errors.commonError`, 10);
              }).finally(() => {
                setSubmitting(false);
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleSubmit,
            dirty,
            isValid,
            setFieldTouched,
            setFieldValue,
            resetForm,
          }) => {
            const retrieveErrors = (field) =>
              (touched[field] && errors[field]) || "";

            const handleChangeField = (e) => {
              e?.target?.name &&
                !touched[e.target.name] &&
                setFieldTouched(e.target.name);
              handleChange(e);
            };

            const handleChangeFieldByName = (name) => (value) => {
              setFieldTouched(name);
              setFieldValue(name, value);
            };

            const handleChangeDateByName = (name) => (momentValue) => {
              setFieldTouched(name);
              setFieldValue(name, momentValue ? new Date(momentValue._d) : null);
            };

            return (
              <Form layout="vertical">
                <Row gutter={[16, 16]} className="promos-detail-content-container">
                  <DetailHeader
                    icon={<SvgBlogIcon fill={"#fff"} stroke={"#fff"} />} 
                    title={t`promos:title`} 
                    goBack={"/promos"} 
                    handleCancel={resetForm}
                    handleSave={handleSubmit}
                    submitIsDisabled={!isValid || !dirty || isSubmitting}
                    isNewRecord={newRecord()}
                    showFormButtons={true}
                  />
                  <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={12} className="col-1">
                    <Card bordered={false}>
                      <h2>{t`promos:detail.promoCode.title`}</h2>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.total.label")}*`}
                            help={retrieveErrors("total")}
                            validateStatus={retrieveErrors("total") && "error"}
                            className="text-label"
                          >
                            <Input
                              name="total"
                              placeholder={t("promos:detail.promoCode.fields.total.placeholder")}
                              value={values.total}
                              onChange={handleChangeField}
                            />
                          </FormItem>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.maxUsers.label")}*`}
                            help={retrieveErrors("maxUsers")}
                            validateStatus={retrieveErrors("maxUsers") && "error"}
                            className="text-label"
                          >
                            <Input
                              name="maxUsers"
                              placeholder={t("promos:detail.promoCode.fields.maxUsers.placeholder")}
                              value={values.maxUsers}
                              onChange={handleChangeField}
                            />
                          </FormItem>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.minPurchaseAmt.label")}*`}
                            help={retrieveErrors("minPurchaseAmt")}
                            validateStatus={retrieveErrors("minPurchaseAmt") && "error"}
                            className="text-label"
                          >
                            <Input
                              name="minPurchaseAmt"
                              placeholder={t("promos:detail.promoCode.fields.minPurchaseAmt.placeholder")}
                              value={values.minPurchaseAmt}
                              onChange={handleChangeField}
                            />
                          </FormItem>
                          <FormItem
                            label={t("promos:detail.promoCode.fields.valid.label")}
                            help={retrieveErrors("valid")}
                            validateStatus={retrieveErrors("valid") && "error"}
                            className="text-label"
                          >
                            {/* <Input
                              name="valid"
                              placeholder={t("promos:detail.promoCode.fields.valid.placeholder")}
                              value={values.valid}
                              onChange={handleChangeField}
                            /> */}
                            <Switch
                              // checkedChildren={t`rating:table.checkboxStatus.yes`}
                              // unCheckedChildren={t`rating:table.checkboxStatus.no`}
                              checked={values.valid}
                              onChange={handleChangeFieldByName("valid")}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.startDate.label")}*`}
                            help={retrieveErrors("startDate")}
                            validateStatus={retrieveErrors("startDate") && "error"}
                            className="text-label"
                          >
                            <DatePicker
                              name="startDate"
                              onChange={handleChangeDateByName("startDate")}
                              value={values.startDate ? moment(values.startDate, 'DD/MM/YYYY') : null}
                              format="DD/MM/YYYY"
                              locale={frLocale} 
                              className="date-picker-container"
                            />
                          </FormItem>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.endDate.label")}*`}
                            help={retrieveErrors("endDate")}
                            validateStatus={retrieveErrors("endDate") && "error"}
                            className="text-label"
                          >
                            <DatePicker
                              name="endDate"
                              onChange={handleChangeDateByName("endDate")}
                              value={values.endDate ? moment(values.endDate, 'DD/MM/YYYY') : null}
                              format="DD/MM/YYYY"
                              locale={frLocale}
                              className="date-picker-container"
                            />
                          </FormItem>
                          <FormItem
                            label={`${t("promos:detail.promoCode.fields.codeName.label")}*`}
                            help={retrieveErrors("codeName")}
                            validateStatus={retrieveErrors("codeName") && "error"}
                            className="text-label"
                          >
                            <Input
                              name="codeName"
                              placeholder={t("promos:detail.promoCode.fields.codeName.placeholder")}
                              value={values.codeName}
                              onChange={handleChangeField}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      }
    </MainLayout>
  );
};

export default PromoDetail;
