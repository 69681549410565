import { get, patch, post } from "./axios-service";

export function getPromoDetail(id) {
  return get(`discount-code/${id}`);
}

export function createPromoCodeItem(requestBody) {
  return post(`discount-codes`, requestBody);
}

export function patchPromoItem(id, requestBody) {
  return patch(`discount-code/${id}`, requestBody);
}

export function deletePromoItem(id) {
  return patchPromoItem(id, {
    isDeleted: true
  });
}

export function getPromoList(queryString = "") {
  return get(`discount-codes?filter=${queryString}`);
}
