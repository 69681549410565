import * as React from "react";

function SvgPhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.018"
      height="17.888"
      viewBox="0 0 18.018 17.888"
    >
      <path
        id="Path_477"
        data-name="Path 477"
        d="M17.228,13.308l-2.32-2.355a1.506,1.506,0,0,0-1.006-.411,1.474,1.474,0,0,0-.9.323,28.048,28.048,0,0,0-2.517,2.486A13.634,13.634,0,0,1,4.309,7.169l2.348-2.4A1.653,1.653,0,0,0,6.72,2.678,10.882,10.882,0,0,0,4.166.285,1.4,1.4,0,0,0,3.318,0a1.564,1.564,0,0,0-.862.274A5.913,5.913,0,0,0,0,4.941a.309.309,0,0,0,.016.107A23.032,23.032,0,0,0,12.383,17.565a.3.3,0,0,0,.117.023h.012a7.29,7.29,0,0,0,4.442-1.91l.01-.006s0-.006.005-.008a5.54,5.54,0,0,0,.478-.535A1.458,1.458,0,0,0,17.228,13.308Zm-.279,1.462c-.068.088-.14.168-.211.249l-1.561-1.532a.307.307,0,1,0-.429.438L16.3,15.453a6.88,6.88,0,0,1-3.752,1.517A22.416,22.416,0,0,1,.615,4.9,5.271,5.271,0,0,1,2.793.787.962.962,0,0,1,3.319.613a.8.8,0,0,1,.464.15l.026.02A10.6,10.6,0,0,1,6.2,3c.5.813.082,1.278.028,1.332l-.061.062L4.6,2.86a.307.307,0,1,0-.429.438L5.736,4.833,3.716,6.9a.306.306,0,0,0-.055.352A13.784,13.784,0,0,0,10.463,14a.306.306,0,0,0,.333-.082,31.326,31.326,0,0,1,2.579-2.569.876.876,0,0,1,.528-.2.915.915,0,0,1,.57.23l2.316,2.35A.84.84,0,0,1,16.949,14.77Z"
        transform="translate(0.15 0.15)"
        fill="#b0b0b0"
        stroke="#b0b0b0"
        strokeWidth="0.3"
      />
    </svg>
  );
}

export default SvgPhoneIcon;
