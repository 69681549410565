import * as React from "react";

function SvgUnfilledCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.472"
      height="12.472"
      viewBox="0 0 12.472 12.472"
    >
      <g
        id="Ellipse_501"
        data-name="Ellipse 501"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      >
        <circle cx="6.236" cy="6.236" r="6.236" stroke="none" />
        <circle cx="6.236" cy="6.236" r="5.736" fill="none" />
      </g>
    </svg>
  );
}

export default SvgUnfilledCircleIcon;
