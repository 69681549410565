import { useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import {
  Col,
  Modal,
  Radio,
  message,
  Popover,
  TimePicker,
  DatePicker,
  Input,
  Select,
  Checkbox,
  Spin,
} from "antd";
import styled from "styled-components";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  areIntervalsOverlapping,
  isToday,
  differenceInWeeks,
  startOfMonth,
  isAfter,
  getDate,
} from "date-fns";
import { fr, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import {
  CALENDAR,
  SCHEDULE_REPEAT_END_DATE,
  SCHEDULE_REPEAT_END_NEVER,
  SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY,
  SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY,
  SCHEDULE_REPEAT_ON_FRIDAY,
  SCHEDULE_REPEAT_ON_MONDAY,
  SCHEDULE_REPEAT_ON_SATURDAY,
  SCHEDULE_REPEAT_ON_SUNDAY,
  SCHEDULE_REPEAT_ON_THURSDAY,
  SCHEDULE_REPEAT_ON_TUESDAY,
  SCHEDULE_REPEAT_ON_WEDNESDAY,
  SCHEDULE_REPEAT_TYPE_DAY,
  SCHEDULE_REPEAT_TYPE_MONTH,
  SCHEDULE_REPEAT_TYPE_ONE_TIME,
  SCHEDULE_REPEAT_TYPE_WEEK,
} from "../../constants";
import { TextButton } from "components";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";

const { Option } = Select;

const locales = {
  fr,
  en: enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const DragAndDropCalendar = withDragAndDrop(Calendar);

const retrieveDayNumberInMonth = (date) =>
  differenceInWeeks(date, startOfMonth(date)) + 1;

let eventInFocus = null;

export const CustomCalendar = styled(
  ({
    className,
    editable = true,
    selectable = false,
    resizable = true,
    events = [],
    calendarView = Views.WEEK,
    views = [Views.WEEK, Views.MONTH],
    dayLayoutAlgorithm = "no-overlap",
    popup = true,
    toolbarComponent,
    eventWrapperComponent,
    style,
    setEvents = (e) => console.log("events :>> ", e),
    eventTitle = "",
    newEventRef = null,
    loading = false,
    dateInView = new Date(),
    handleCreateEvent = (e) =>
      new Promise((resolve) => resolve(console.log("new event create", e))),
    handleUpdateEvent = (e) =>
      new Promise((resolve) => resolve(console.log("event update", e))),
    handleDeleteEvent = (e, allOccurrences) =>
      new Promise((resolve) =>
        resolve(console.log("event delete", e, allOccurrences))
      ),
    handleViewChanged = (view) => console.log("view changed :>> ", view),
    handleOnNavigate = (date) => console.log("on navigate :>> ", date),
    ...rest
  }) => {
    const { t, i18n } = useTranslation();
    const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
    const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
    const [draggedEvent, setDraggedEvent] = useState(null);
    // const [recurrence, setRecurrence] = useState(SCHEDULE_REPEAT_TYPE_ONE_TIME);
    const deleteActivityBtnRef = useRef(null);

    const SCHEDULE_REPEAT_TYPES = [
      // {
      //   value: SCHEDULE_REPEAT_TYPE_ONE_TIME,
      //   label: t`common:schedule.oneTime`,
      // },
      {
        key: "day",
        value: SCHEDULE_REPEAT_TYPE_DAY,
        label: t`common:schedule.day`,
      },
      {
        key: "week",
        value: SCHEDULE_REPEAT_TYPE_WEEK,
        label: t`common:schedule.week`,
      },
      {
        key: "month",
        value: SCHEDULE_REPEAT_TYPE_MONTH,
        label: t`common:schedule.month`,
      },
      /* {
        key: "year",
        value: SCHEDULE_REPEAT_TYPE_YEAR,
        label: t`common:schedule.year`,
      }, */
    ];

    const toggleRecurrenceModal = (event) => {
      if (editable) {
        /* if (event) {
          setRecurrence(event.repeatType || recurrence);
        } */

        eventInFocus = { ...event };
        setShowRecurrenceModal(!showRecurrenceModal);
      }
    };

    const onView = (view) => {
      handleViewChanged(view);
    };

    const handleRecurrenceEditOrNew = async () => {
      if (editable) {
        if (eventInFocus) {
          // let newEvents = [];
          let successful = false;

          if (eventInFocus.id == null) {
            /* NEW EVENT */
            const eventToCreate = { ...eventInFocus, id: -events.length };
            console.log("eventToCreate :>> ", eventToCreate);
            // newEvents = [...events, eventToCreate];
            await handleCreateEvent(eventToCreate).then(
              () => (successful = true)
            );
          } else {
            /* EDIT EVENT */
            // newEvents = events.map((event) =>
            //   event.id == eventInFocus.id ? eventInFocus : event
            // );
            await handleUpdateEvent(eventInFocus).then(
              () => (successful = true)
            );
          }

          if (successful) {
            // setEvents(newEvents);
            toggleRecurrenceModal(null);
            // setRecurrence(SCHEDULE_REPEAT_TYPE_ONE_TIME);
          }
        }
      }
    };

    const isEventOverlapping = ({ id, start, end }) =>
      id == null
        ? false
        : events.some(
            (e) =>
              e.id !== id &&
              areIntervalsOverlapping(
                { start, end },
                { start: e.start, end: e.end }
              )
          );

    const handleDragStart = (event) => {
      if (editable) {
        setDraggedEvent(event);
      }
    };

    const dragFromOutsideItem = () => {
      if (editable) {
        draggedEvent();
      }
    };

    const onDropFromOutside = ({ start, end, allDay }) => {
      if (editable) {
        const event = {
          id: draggedEvent.id,
          title: draggedEvent.title,
          start,
          end,
          allDay: allDay,
        };

        setDraggedEvent(null);
        moveEvent({ event, start, end, isAllDay: allDay });
      }
    };

    const moveEvent = ({
      event,
      start,
      end,
      isAllDay: droppedOnAllDaySlot,
    }) => {
      if (editable) {
        if (isEventOverlapping({ id: event.id, start, end })) {
          message.error(t("common:schedule.errors.overlappingActivity"), 5);
        } else {
          // let allDay = event.allDay;

          // if (!event.allDay && droppedOnAllDaySlot) {
          //   allDay = true;
          // } else if (event.allDay && !droppedOnAllDaySlot) {
          //   allDay = false;
          // }

          const nextEvents = events.map((existingEvent) => {
            return existingEvent.id === event.id
              ? // ? { ...existingEvent, start, end, allDay }
                { ...existingEvent, start, end }
              : existingEvent;
          });

          setEvents(nextEvents);
          // handleUpdateEvent({ ...event, start, end, allDay });
          handleUpdateEvent({ ...event, start, end });

          // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
        }
      }
    };

    const onEventResize = ({ event, start, end }) => {
      if (editable) {
        if (isEventOverlapping({ id: event.id, start, end })) {
          message.error(t("common:schedule.errors.overlappingActivity"), 5);
        } else {
          const nextEvents = events.map((existingEvent) => {
            return existingEvent.id == event.id
              ? { ...existingEvent, start, end }
              : existingEvent;
          });

          setEvents(nextEvents);
          handleUpdateEvent({ ...event, start, end });

          //alert(`${event.title} was resized to ${start}-${end}`)
        }
      }
    };

    const newEvent = ({ start, end }) => {
      // if (editable && calendarView === Views.WEEK) {
      if (editable) {
        const id = null;

        if (isEventOverlapping({ id, start, end })) {
          message.error(t("common:schedule.errors.overlappingActivity"), 5);
        } else {
          toggleRecurrenceModal({
            id,
            title: eventTitle,
            start,
            end,
            repeatType: SCHEDULE_REPEAT_TYPE_DAY,
            repeat: "NO_REPEAT",
            repeatAmount: 1,
            repeatWeekDays: [],
            repeatMonthType: SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY,
            repeatEnd: SCHEDULE_REPEAT_END_NEVER,
            repeatEndDate: null,
          });
        }
      }
    };

    // const handleDeleteEvent = () => {
    //   if (editable) {
    //     const eventToDeleteIndex = events.findIndex(
    //       (event) => event.id === eventInFocus.id
    //     );

    //     if (eventToDeleteIndex >= 0) {
    //       const newEvents = [...events];
    //       newEvents.splice(eventToDeleteIndex, 1);
    //       setEvents(newEvents);
    //       console.log("newEvents :>> ", newEvents);
    //     }

    //     showRecurrenceModal && toggleRecurrenceModal(null);
    //   }
    // };

    const deleteEvent = (e, event, allOccurrences = false) => {
      e.stopPropagation();
      e.preventDefault();
      eventInFocus = event;

      handleDeleteEvent(eventInFocus, allOccurrences);
    };

    const onShowMore = (events, date) => console.log(date);

    const renderToolbar = ({
      date,
      label,
      view,
      views,
      onNavigate,
      onView,
    }) => {
      const DAY_NAV_OPTS = [
        {
          key: "opt1",
          label: t`common:calendar.previous`,
          onClick: () => onNavigate(CALENDAR.NAVIGATE.PREVIOUS),
          className: "",
        },
        {
          key: "opt2",
          label: t`common:calendar.today`,
          onClick: () => onNavigate(CALENDAR.NAVIGATE.TODAY),
          className: isToday(date) ? "rbc-active" : "",
        },
        {
          key: "opt3",
          label: t`common:calendar.next`,
          onClick: () => onNavigate(CALENDAR.NAVIGATE.NEXT),
          className: "",
        },
      ];

      return (
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            {DAY_NAV_OPTS.map((opt) => (
              <button
                key={opt.key}
                type="button"
                onClick={opt.onClick}
                className={opt.className}
              >
                {opt.label}
              </button>
            ))}
          </span>
          <span className="rbc-toolbar-label">{label}</span>
          <span className="rbc-btn-group">
            {views.map((v, index) => (
              <button
                key={`${index}`}
                type="button"
                className={v === view ? "rbc-active" : ""}
                onClick={() => onView(v)}
              >
                {t(`common:calendar.${v}`)}
              </button>
            ))}
          </span>
        </div>
      );
    };

    const renderEventWrapper = (eventProps) => {
      const { event, children, style } = eventProps;

      return (
        <div className={`event-wrapper-container ${event?.className || ""}`}>
          {editable && (
            <Popover
              className="event-delete-pop-confirm"
              placement="bottom"
              title={t`common:schedule.deleteTimeSlotTitle`}
              content={
                <div className="delete-activity-popover-content">
                  <a
                    className="delete-activity-link-btn delete-activity-delete-all-link-btn"
                    onClick={(e) => deleteEvent(e, event, true)}
                  >{t`common:deleteAllRecurrences`}</a>
                  <a
                    className="delete-activity-link-btn delete-activity-delete-link-btn"
                    onClick={(e) => deleteEvent(e, event, false)}
                  >{t`common:yes`}</a>
                </div>
              }
            >
              <IconButton
                ref={deleteActivityBtnRef}
                aria-label="delete activity"
                className="delete-activity-btn"
                style={{
                  top: `calc(${style?.top || 0}% - 8px)`,
                }}
              >
                <Cancel color="error" />
              </IconButton>
            </Popover>
          )}
          {children}
        </div>
      );
    };

    const validationSchema = yup.object().shape({
      startDate: yup
        .string()
        .nullable()
        .required(t`common:errors.fieldIsRequired`),
      startTime: yup
        .string()
        .nullable()
        .required(t`common:errors.fieldIsRequired`),
      endTime: yup
        .string()
        .nullable()
        .required(t`common:errors.fieldIsRequired`)
        .when("startTime", (startTime, schema) =>
          startTime
            ? schema.test({
                name: "endTimeMustBeAfterStartTime",
                message: t`common:schedule.errors.endTimeMustBeAfterStartTime`,
                test(value) {
                  return value
                    ? isAfter(new Date(value), new Date(startTime))
                    : true;
                },
              })
            : schema
        ),
      repeatAmount: yup
        .number()
        .nullable()
        .when("repeat", (repeat, schema) => {
          if (repeat === "REPEAT") {
            return schema
              .required(t`common:errors.fieldIsRequired`)
              .positive(t`common:errors.positiveNumber`)
              .integer(t`common:errors.integerOnly`)
              .required(t`common:errors.fieldIsRequired`)
              .min(1, t("common:errors.minNumber", { count: 1 }))
              .max(
                10000,
                t("common:errors.maxNumber", {
                  count: 10000,
                })
              );
          }

          return schema;
        }),
      // repeatType: yup.,
      repeatWeekDays: yup
        .array()
        .when(["repeat", "repeatType"], (repeat, repeatType) => {
          if (repeat === "REPEAT" && repeatType === SCHEDULE_REPEAT_TYPE_WEEK) {
            return yup
              .array()
              .min(1, t`common:schedule.errors.selectAtleastOneDay`);
          }

          return yup.array();
        }),
      repeatEndDate: yup
        .string()
        .nullable()
        .when(["repeat", "repeatEnd", "startDate"], (...args) => {
          const [repeat, repeatEnd, startDate, schema] = args;

          if (
            repeat === "REPEAT" &&
            repeatEnd === SCHEDULE_REPEAT_END_DATE &&
            startDate
          ) {
            return schema.required(t`common:errors.fieldIsRequired`).test({
              name: "endDateMustBeAfterStartDate",
              message: t`common:schedule.errors.endDateMustBeAfterStartDate`,
              test(value) {
                const normalizedStartDate = new Date(startDate);
                const normalizedEndDate = new Date(value);
                normalizedStartDate.setHours(0);
                normalizedStartDate.setMinutes(0);
                normalizedStartDate.setSeconds(0);
                normalizedEndDate.setHours(0);
                normalizedEndDate.setMinutes(0);
                normalizedEndDate.setSeconds(0);

                return value
                  ? isAfter(normalizedEndDate, normalizedStartDate)
                  : false;
              },
            });
          }

          return schema;
        }),
    });

    const renderScheduleModal = () => {
      const initialValues = {
        startDate: eventInFocus?.start
          ? moment(eventInFocus.start.toISOString())
          : null,
        startTime: eventInFocus?.start
          ? moment(eventInFocus.start.toISOString())
          : null,
        endTime: eventInFocus?.end
          ? moment(eventInFocus.end.toISOString())
          : null,
        repeat: eventInFocus?.repeat || "NO_REPEAT",
        repeatAmount: eventInFocus?.repeatAmount || 1,
        repeatType: eventInFocus?.repeatType || SCHEDULE_REPEAT_TYPE_DAY,
        repeatWeekDays: eventInFocus?.repeatWeekDays || [],
        repeatMonthType:
          eventInFocus?.repeatMonthType || SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY,
        repeatEnd: eventInFocus?.repeatEnd || SCHEDULE_REPEAT_END_NEVER,
        repeatEndDate: eventInFocus?.repeatEndDate
          ? moment(eventInFocus.repeatEndDate.toISOString())
          : null,
      };

      return (
        <Modal
          visible={showRecurrenceModal}
          centered
          footer={null}
          closable={true}
          onCancel={() => toggleRecurrenceModal(null)}
          maskClosable={true}
          destroyOnClose={true}
          bodyStyle={{
            maxHeight: "calc(100vh - 10rem)",
            height: "auto",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            minWidth: "16rem",
            width: "100%",
            maxWidth: "20rem",
            padding: "3.5rem 1.5rem 1.5rem",
          }}
          className="custom-calendar-schedule-modal"
        >
          <Formik
            initialValues={{
              startDate: initialValues.startDate,
              startTime: initialValues.startTime,
              endTime: initialValues.endTime,
              repeat: initialValues.repeat,
              repeatAmount: initialValues.repeatAmount,
              repeatType: initialValues.repeatType,
              repeatWeekDays: initialValues.repeatWeekDays,
              repeatMonthType: initialValues.repeatMonthType,
              repeatEnd: initialValues.repeatEnd,
              repeatEndDate: initialValues.repeatEndDate,
            }}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              const startDateTime = values.startDate.toDate();
              startDateTime.setHours(values.startTime.toDate().getHours());
              startDateTime.setMinutes(values.startTime.toDate().getMinutes());
              startDateTime.setSeconds(values.startTime.toDate().getSeconds());
              const endDateTime = values.startDate.toDate();
              endDateTime.setHours(values.endTime.toDate().getHours());
              endDateTime.setMinutes(values.endTime.toDate().getMinutes());
              endDateTime.setSeconds(values.endTime.toDate().getSeconds());

              eventInFocus = {
                id: eventInFocus.id || null,
                title: eventInFocus.title || eventTitle,
                start: startDateTime,
                end: endDateTime,
                repeatAmount: values.repeatAmount,
                repeat: values.repeat,
                repeatType: values.repeatType,
                repeatWeekDays: values.repeatWeekDays,
                repeatMonthType: values.repeatMonthType,
                repeatEnd: values.repeatEnd,
                repeatEndDate: values.repeatEndDate,
              };
              handleRecurrenceEditOrNew();
              /* {
                  "startDate": "2021-07-14T03:48:10.709Z",
                  "startTime": "2021-07-23T22:00:00.014Z",
                  "endTime": "2021-07-23T23:00:00.583Z",
                  "repeat": "NO_REPEAT",
                  "repeatAmount": 1,
                  "repeatType": "DAY",
                  "repeatWeekDays": [],
                  "repeatMonthType": "DATE",
                  "repeatEnd": "NEVER",
                  "repeatEndDate": null
                } */

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              dirty,
              isValid,
              setFieldTouched,
              setFieldValue,
            }) => {
              const retrieveErrors = (field) =>
                (touched[field] && errors[field]) || "";
              const handleChangeField = (e) => {
                e?.target?.name &&
                  !touched[e.target.name] &&
                  setFieldTouched(e.target.name);
                handleChange(e);
              };

              const handleChangeFieldByName = (name, value) => {
                setFieldTouched(name);
                setFieldValue(name, value);
              };

              const REPEAT_ACTIVITY_OPTS = [
                {
                  key: "noRepeat",
                  label: t`common:schedule.noRepeat`,
                  value: "NO_REPEAT",
                },
                {
                  key: "repeat",
                  label: t`common:schedule.repeatEvery`,
                  value: "REPEAT",
                },
              ];

              const WEEK_DAYS_OPTS = [
                {
                  key: SCHEDULE_REPEAT_ON_MONDAY,
                  label: t`common:days.mon`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_MONDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_TUESDAY,
                  label: t`common:days.tue`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_TUESDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_WEDNESDAY,
                  label: t`common:days.wed`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_WEDNESDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_THURSDAY,
                  label: t`common:days.thu`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_THURSDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_FRIDAY,
                  label: t`common:days.fri`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_FRIDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_SATURDAY,
                  label: t`common:days.sat`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_SATURDAY,
                },
                {
                  key: SCHEDULE_REPEAT_ON_SUNDAY,
                  label: t`common:days.sun`.split("")[0],
                  value: SCHEDULE_REPEAT_ON_SUNDAY,
                },
              ];

              const REPEAT_MONTH_OPTS = [
                {
                  key: SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY,
                  // label: t("common:schedule.onDay", {
                  //   date: values.startDate?.toDate()
                  //     ? t(
                  //         `common:schedule.dayNames.${getDay(
                  //           values.startDate.toDate()
                  //         )}`
                  //       )
                  //     : t`common:schedule.dayNames.1`,
                  // }),
                  label: t("common:schedule.onDay", {
                    date: values.startDate?.toDate()
                      ? getDate(values.startDate.toDate())
                      : 1,
                  }),
                  value: SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY,
                },
                {
                  key: SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY,
                  label: t(
                    `common:schedule.onDayOfMonth.${
                      Number.isNaN(
                        retrieveDayNumberInMonth(values.startDate?.toDate())
                      )
                        ? 1
                        : retrieveDayNumberInMonth(values.startDate?.toDate())
                    }`,
                    {
                      day: values.startDate?.toDate()
                        ? t(
                            `common:schedule.dayNames.${getDay(
                              values.startDate.toDate()
                            )}`
                          )
                        : t`common:schedule.dayNames.1`,
                    }
                  ),
                  value: SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY,
                },
              ];

              const SCHEDULE_REPEAT_END_OPTS = [
                {
                  key: "never",
                  label: t`common:schedule.never`,
                  value: SCHEDULE_REPEAT_END_NEVER,
                  className: "",
                },
                {
                  key: "endDate",
                  label: (
                    <DatePicker
                      value={values.repeatEndDate}
                      onChange={(value) =>
                        handleChangeFieldByName("repeatEndDate", value)
                      }
                      placeholder={t("common:schedule.repeatEndDate")}
                      disabled={values.repeatEnd !== SCHEDULE_REPEAT_END_DATE}
                      inputReadOnly={true}
                      bordered={true}
                      className="custom-calendar-date-picker"
                    />
                  ),
                  value: SCHEDULE_REPEAT_END_DATE,
                  className: "custom-calendar-repeat-end-date-picker",
                },
              ];

              const repeatOptsAreDisabled = values.repeat !== "REPEAT";

              return (
                <Form {...rest}>
                  <FormItem
                    // label={t("common:schedule.startDate")}
                    required
                    help={retrieveErrors("startDate")}
                    validateStatus={retrieveErrors("startDate") && "error"}
                  >
                    <DatePicker
                      value={values.startDate}
                      onChange={(value) =>
                        handleChangeFieldByName("startDate", value)
                      }
                      placeholder={t("common:schedule.startDate")}
                      inputReadOnly={true}
                      bordered={true}
                      className="custom-calendar-date-picker"
                    />
                  </FormItem>
                  <FormItem
                    // label={t("common:schedule.startTime")}
                    required
                    help={retrieveErrors("startTime")}
                    validateStatus={retrieveErrors("startTime") && "error"}
                  >
                    <TimePicker
                      minuteStep={15}
                      format="HH:mm"
                      value={values.startTime}
                      onChange={(value) =>
                        handleChangeFieldByName("startTime", value)
                      }
                      placeholder={t("common:schedule.startTime")}
                      inputReadOnly={true}
                      bordered={true}
                      className="custom-calendar-time-picker"
                    />
                  </FormItem>
                  <FormItem
                    // label={t("common:schedule.endTime")}
                    required
                    help={retrieveErrors("endTime")}
                    validateStatus={retrieveErrors("endTime") && "error"}
                  >
                    <TimePicker
                      minuteStep={15}
                      format="HH:mm"
                      value={values.endTime}
                      onChange={(value) =>
                        handleChangeFieldByName("endTime", value)
                      }
                      placeholder={t("common:schedule.endTime")}
                      inputReadOnly={true}
                      bordered={true}
                      className="custom-calendar-time-picker"
                    />
                  </FormItem>
                  {!eventInFocus.id && (
                    <>
                      <FormItem
                        // label={t("common:schedule.repeatEvery")}
                        required
                        help={
                          retrieveErrors("repeat") ||
                          retrieveErrors("repeatAmount")
                        }
                        validateStatus={
                          (retrieveErrors("repeat") ||
                            retrieveErrors("repeatAmount")) &&
                          "error"
                        }
                      >
                        <Select
                          value={values.repeat}
                          onChange={(value) =>
                            handleChangeFieldByName("repeat", value)
                          }
                          bordered={true}
                          className="custom-calendar-repeat-select"
                        >
                          {REPEAT_ACTIVITY_OPTS.map((opt) => (
                            <Option key={opt.key} value={opt.value}>
                              {opt.label}
                            </Option>
                          ))}
                        </Select>
                        <div className="custom-calendar-basic-repeat-container">
                          <Input
                            name="repeatAmount"
                            type="number"
                            value={values.repeatAmount}
                            min={1}
                            max={10000}
                            onChange={handleChangeField}
                            disabled={repeatOptsAreDisabled}
                            bordered={true}
                          />
                          <Select
                            value={values.repeatType}
                            onChange={(value) =>
                              handleChangeFieldByName("repeatType", value)
                            }
                            disabled={repeatOptsAreDisabled}
                            bordered={true}
                          >
                            {SCHEDULE_REPEAT_TYPES.map((type) => (
                              <Option key={type.key} value={type.value}>
                                {type.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </FormItem>
                      {values.repeatType === SCHEDULE_REPEAT_TYPE_WEEK && (
                        <FormItem
                          help={retrieveErrors("repeatWeekDays")}
                          validateStatus={
                            retrieveErrors("repeatWeekDays") && "error"
                          }
                        >
                          <Checkbox.Group
                            options={WEEK_DAYS_OPTS}
                            value={values.repeatWeekDays}
                            onChange={(value) =>
                              handleChangeFieldByName("repeatWeekDays", value)
                            }
                            disabled={repeatOptsAreDisabled}
                            className="custom-calendar-week-days-repeat-chkbx"
                          />
                        </FormItem>
                      )}
                      {values.repeatType === SCHEDULE_REPEAT_TYPE_MONTH && (
                        <FormItem
                          help={retrieveErrors("repeatMonthType")}
                          validateStatus={
                            retrieveErrors("repeatMonthType") && "error"
                          }
                        >
                          <Select
                            value={values.repeatMonthType}
                            onChange={(value) =>
                              handleChangeFieldByName("repeatMonthType", value)
                            }
                            disabled={repeatOptsAreDisabled}
                            bordered={true}
                            className="custom-calendar-repeat-month-select"
                          >
                            {REPEAT_MONTH_OPTS.map((type) => (
                              <Option key={type.key} value={type.value}>
                                {type.label}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      )}
                      {!repeatOptsAreDisabled && (
                        <FormItem
                          label={t`common:schedule.endOfRecurrence`}
                          help={retrieveErrors("repeatEndDate")}
                          validateStatus={
                            retrieveErrors("repeatEndDate") && "error"
                          }
                          className={`custom-calendar-repeat-end-container`}
                        >
                          <Radio.Group
                            name="repeatEnd"
                            onChange={handleChangeField}
                            value={values.repeatEnd}
                            disabled={repeatOptsAreDisabled}
                          >
                            {SCHEDULE_REPEAT_END_OPTS.map(
                              ({ key, label, value, className }) => (
                                <Radio
                                  key={key}
                                  value={value}
                                  className={`custom-calendar-radio ${className}`}
                                >
                                  {label}
                                </Radio>
                              )
                            )}
                          </Radio.Group>
                        </FormItem>
                      )}
                    </>
                  )}
                  <TextButton
                    htmlType="submit"
                    variant="darkBlue"
                    width="100%"
                    rounded
                    disabled={!isValid || isSubmitting || !dirty || loading}
                    className="custom-calendar-schedule-modal-submit-btn"
                  >
                    {t`common:save`}
                  </TextButton>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      );
    };

    return (
      <>
        {renderScheduleModal()}
        <Col span={24} className={className}>
          <button
            ref={newEventRef}
            className="invisible-new-event-btn"
            onClick={() => newEvent({ start: null, end: null })}
          ></button>
          <div className="calendar-container">
            {loading && (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}
            <DragAndDropCalendar
              selectable={selectable}
              resizable={resizable}
              localizer={localizer}
              events={events}
              // events={loading ? [] : events}
              view={calendarView}
              onEventDrop={moveEvent}
              onEventResize={onEventResize}
              onSelectSlot={newEvent}
              onSelectEvent={toggleRecurrenceModal}
              // onDragStart={console.log}
              onView={onView}
              // defaultView={defaultView}
              views={views}
              dayLayoutAlgorithm={dayLayoutAlgorithm}
              // defaultDate={new Date(2021, 6, 14)}
              date={dateInView}
              popup={popup}
              dragFromOutsideItem={
                displayDragItemInCell ? dragFromOutsideItem : null
              }
              onDropFromOutside={onDropFromOutside}
              onShowMore={onShowMore}
              handleDragStart={handleDragStart}
              onNavigate={handleOnNavigate}
              culture={i18n.language}
              components={{
                toolbar: toolbarComponent || renderToolbar,
                eventWrapper: eventWrapperComponent || renderEventWrapper,
              }}
              style={style}
              messages={{
                showMore: (total) => (
                  <div
                    className="show-more-text"
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onClick={() => handleViewChanged(Views.WEEK)}
                  >
                    {`+${total}`}
                  </div>
                ),
              }}
              {...rest}
            />
          </div>
        </Col>
      </>
    );
  }
)`
  /* height: 100%; */
  /* max-height: 64vh; */
  min-height: 40rem;

  .invisible-new-event-btn {
    display: none;
  }

  .calendar-container {
    min-width: 54rem;
    height: 100%;
    position: relative;
    isolation: isolate;

    .rbc-calendar {
      /* min-height: 48rem; */
    }

    .loading-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => `${theme.colors.white}DD`};
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rbc-month-row {
    .event-wrapper-container {
      position: relative;
    }
  }

  .event-wrapper-container {
    &:hover,
    &:focus {
      .delete-activity-btn {
        opacity: 1;
      }
    }

    .delete-activity-btn {
      opacity: 0;
      position: absolute;
      padding: 0;
      background: ${({ theme }) => theme.colors.white};
      right: -8px;
      z-index: 1;
      transition: opacity ease 200ms;

      @media (max-width: 48em) {
        opacity: 1;
      }
    }
  }

  .show-more-text {
    cursor: pointer;
    font-size: 1.2rem;
  }

  @media (max-width: 63.25em) {
    overflow-x: scroll;
  }
`;
