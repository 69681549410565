import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Form, Input, message, Modal, Row, Select, Spin, Switch, Table, Tabs } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { CustomColorPicker, FileUploader, ParameterHeader, TextButton } from "components";
import { DeleteIcon, PartnerIcon } from "components/icons";
import { DOCUMENT_CATEGORIES, ERRORS } from "../../../constants";
import { format } from "date-fns";
import { Formik } from "formik";
import MainLayout from "layouts/main";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createPartner, createPartnerAdmin, deletePartnerDocument, generatePartnerApiKey, getPartnerById, getPartnerDocumentList, getPartners, updateCreditBalance, updatePartner, updatePartnerAdmin, uploadPartnerDocument } from "services/partner";
import PartnerStyle from "styles/partnerStyle";
import * as yup from "yup";
import { fullImageUrl } from "utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useTheme } from "definitions/styled-components";
import { partnerExclusivityList, setPartnerActivityExclusivity } from "services/advert";

const { TabPane } = Tabs;
const { Option } = Select;
const MAX_FILE_SIZE = 5;
const LIMIT = 10;

const Partners = () => {
  const { t, i18n } = useTranslation(["common", "partners"]);
  const { theme } = useTheme();
  const [administrators, setAdministrators] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerColors, setPartnerColors] = useState(null);
  const [listFiles, setListFiles] = useState([]);
  const [partnerItem, setPartnerItem] = useState(null);
  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);
  const [isUploadDocModalVisible, setIsUploadDocModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [isFetchingPartner, setIsFetchingPartner] = useState(false);
  const [addPartner, setAddPartner] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isNewAdmin, setIsNewAdmin] = useState(false);
  const [showDeleteDocDialog, setShowDeleteDocDialog] = useState(false);
  const [showGenerateApiDialog, setShowGenerateApiDialog] = useState(false);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const selectedAdminId = useRef(null);
  const selectedAdmin = useRef(null);
  const docBlob = useRef(null);
  const docFile = useRef(null);
  const docIdToDelete = useRef(null);
  const selectedPartnerId = useRef(null);
  const currentUploadedDocName = useRef(null);
  const [partnerActivities, setPartnerActivities] = useState([]);
  const [exclusivity, setExclusivity] = useState("");



  const columns = [
    {
      title: t`partners:files.title`,
      dataIndex: "filename",
      render: (filename: string) => filename && filename.split(".")?.length > 0 && filename.split(".")[0]
    },
    {
      title: t`partners:files.fileType`,
      dataIndex: "fileType",
      align: "center" as "center",
    },
    {
      title: t`partners:files.publishedDate`,
      dataIndex: "createdAt",
      render: (date: string) => format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH:mm`),
      align: "center" as "center",
    },
    {
      title: t`partners:files.delete`,
      render: (record) => {
        return (
          <Button 
            type="primary"
            icon={<DeleteIcon fill={theme.colors.white} stroke={theme.colors.white}/>}
            className="partner-icon-button-delete"
            onClick={(e) => openDeleteFileDialog(e, record.id)} />
        );
      },
      align: "center" as "center"
    },
  ];

  //exclusiveTable
  const activityColumns = [
    {
      title: t`partners:exclusiveTable.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      title: t`partners:exclusiveTable.name`,
      dataIndex: "name",
    },
    {
      title: t`partners:exclusiveTable.vendor`,
      dataIndex: "seller",
      align: "center" as "center",
    },
    {
      title: t`partners:exclusiveTable.category`,
      dataIndex: "categoryName",
      align: "center" as "center",
    },
    {
      title: t`partners:exclusiveTable.status`,
      dataIndex: "isPublished",
      render: (tag) => (
        <>
         {tag ? <CheckCircleOutlined style={{color: "green"}}/> : <CloseCircleOutlined style={{color: "red"}} />}
        </>
      ),
      align: "center" as "center",
    },
    {
      title: t`partners:exclusiveTable.exclusivity`,
      dataIndex: "isExclusive",
      sorter: (a, b) => b.isExclusive - a.isExclusive,
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren="O"
            unCheckedChildren="N"
            checked={record.isExclusive}
            key={`${record.id}${exclusivity}` || record.id}
            onChange={(e) => onHandleActivityPopular(e, record)}
          />
        );
      },
    }
  ];

  const onHandleActivityPopular = async (checked, record) => {
    let payload = null;
    if (record.exclusivityId) {
      payload = {
        activityId: record.id,
        isExclusive: checked,
        partnerId: record.partnerId || selectedPartnerId.current,
        id: record.exclusivityId
      }
    } else {
      payload = {
        activityId: record.id,
        isExclusive: checked,
        partnerId: record.partnerId || selectedPartnerId.current,
      }
    }

    const result = await setPartnerActivityExclusivity(payload);

    if (result) {
      const idx = partnerActivities.findIndex(x => x.id === record.id);
      const list = partnerActivities.find(x => x.id === record.id);
      list.isExclusive = checked;
      list.exclusivityId = result.exclusivityId;
      partnerActivities[idx] = list;
      setExclusivity(`${record.id}${checked}`)
    }
  }

  const partnerValidationSchema = yup.object().shape({
    partnerName: yup
      .string(),
      // .required(t`partners:errors.partnerName`),
    code: yup
      .string()
      .required(t`partners:errors.partnerCode`),
    yellow: yup.string(),
    green: yup.string(),
    blue: yup.string(),
    salmon: yup.string(),
    isActive: yup.boolean()
  });

  const adminValidationSchema = yup.object().shape({
    firstname: yup.string(),
    lastname: yup.string(),
    email: yup
            .string()
            .email(t`common:errors.emailIncorrect`),
    phone: yup.number(),
    isActive: yup.boolean()
  });

  const documentUploadValidationSchema = yup.object().shape({
    filename: yup.string(),
    category: yup.string(),
    document: yup.string(),
  });

  const creditsValidationSchema = yup.object().shape({
    newCredit: yup.number()
  });
  
  useEffect(() => {
    retrievePartners();
  }, []);

  const retrievePartners = () => {
    getPartners().then((resp) => {
      console.log('GET PARTNERS >>>>> :>> ', resp);
      setPartners(resp);
    }).catch((e) => {
      console.log('GET PARTNERS e?.response?.data :>> ', e?.response?.data);
    });
  };


  const openDeleteFileDialog = (e, index) => {
    e.stopPropagation();
    console.log('index :>> ', index);
    docIdToDelete.current = index;
    setShowDeleteDocDialog(true);
  }

  const handleOpenAdminModal = (adminId) => () => {
    selectedAdminId.current = adminId;
    const selectedAdminFound = administrators?.find((admin) => admin.id === adminId);
    if (selectedAdminFound) {
      selectedAdmin.current = selectedAdminFound;
      setIsAdminModalVisible(true);
      setIsNewAdmin(false);
    }
  }

  const handleCreateNewAdmin = () => {
    setIsAdminModalVisible(true);
    setIsNewAdmin(true);
  };

  const renderPartnerItem = (item) => (
    <div
      className={`partner-item-container${item.id === selectedPartnerId.current ? ` partner-item-selected` : ""}`}
      key={`${item.id}`}
      onClick={showPartnerDetail(item.id)}>
      <div className="partner-item-logo-container">
        {item.logo && <img src={fullImageUrl(item.logo, "sm")} alt="partner logo" width="50"/>}
        {/* <ByNeosilverIcon /> */}
      </div>
      <div className="partner-item-info-container">
        <label>{item.name}</label>
        <p>
          {item?.partnerUsers && item.partnerUsers?.[0]?.lastConnectedDate &&
            t("partners:list.description", 
              {
                connectionDate: format(new Date(item.partnerUsers[0]?.lastConnectedDate), "dd/MM/yyyy"), 
                connectionTime: format(new Date(item.partnerUsers[0]?.lastConnectedDate), "HH:mm")
              }
            )
          }
        </p>
      </div>
    </div>
  );

  const renderAdminItem = (item) => (
    <div
      className={`partner-admin-item-container${item.id === selectedAdminId?.current ? " partner-admin-item-selected": ""}`}
      key={`${item.id}`}
      onClick={handleOpenAdminModal(item.id)}>
      <Avatar size={50} src={item.avatar} icon={<UserOutlined />} />
      <div>
        <label>{[item.firstname, item.name].filter(Boolean).join(" ")}</label>
        {
          item.lastConnectedDate && (
            <p>{t("partners:detail.description", 
            {
              connectionDate: format(new Date(item.lastConnectedDate), "dd/MM/yyyy"), 
              connectionTime: format(new Date(item.lastConnectedDate), "HH:mm")
            })}
            </p>
          )
        }
      </div>
    </div>
  );

  const handleSubmitAdmin = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const payload = {
      email: values.email,
      name: values.lastname,
      firstname: values.firstname,
      phoneNumber: values.phone
    };

    //new
    if (isNewAdmin) {
      payload["partnerId"] = partnerItem?.id;
      createPartnerAdmin(payload)
        .then((resp) => {
          console.log('resp :>> ', resp);
          setAdministrators([...administrators, resp]);
          message.success(t`partners:success.partnerAdminCreated`, 5);
          resetForm();
          setIsAdminModalVisible(false);
        })
        .catch((e) => {
          console.log('e :>> ', e?.response?.data?.error?.message);
          if (e?.response?.data?.error?.message === ERRORS.DUPLICATE_EMAIL) {
            message.error(t`common:errors.duplicateEmail`);
          }
          else {
            message.error(t`partners:error.partnerAdminCreated`, 5);
          }
        })
        .finally(() => setSubmitting(false));
    }
    else {
      //modification
      payload["isActive"] = values.isActive;

      updatePartnerAdmin(selectedAdminId?.current, payload)
        .then((resp) => {
          console.log('resp :>> ', resp);
          selectedAdmin.current = resp;
          message.success(t`partners:success.partnerAdminModified`, 5);
          setIsAdminModalVisible(false);
        })
        .catch((e) => {
          console.log('e :>> ', e);
          message.error(t`partners:error.partnerAdminModified`, 5);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleAddPartner = () => {
    selectedPartnerId.current = null;
    setIsFetchingPartner(true);
    //display form on the right side
    setAddPartner(true);
    setIsNew(true);
    setPartnerItem(null);
    setPartnerColors(null);
    setListFiles([]);
    setAdministrators([]);
    setListFiles([]);
    setTimeout(() => setIsFetchingPartner(false), 0);
  };

  const showPartnerDetail = (partnerId) => () => {
    selectedPartnerId.current = partnerId;
    setIsFetchingPartner(true);
    setIsNew(false);

    return Promise.allSettled([
      getPartnerById(selectedPartnerId.current)
        .then((resp) => {
          console.log('resp :>> ', resp);
          const colors = resp?.partnerThemes.map((item) => ({
            [item.originalColor]: {
              newColor: item.newColor,
              id: item.id
            }
          }))

          setPartnerColors(Object.assign({}, ...colors));
          setAdministrators(resp?.partnerUsers);
          setPartnerItem(resp);
          setAddPartner(true);
        })
        .catch((e) => {
          console.log('e :>> ', e);
        })
        .finally(() => setIsFetchingPartner(false)),
      getPartnerDocumentList(selectedPartnerId.current)
        .then((resp) => {
          console.log('getPartnerDocumentList resp :>> ', resp);
          setListFiles(resp);
        })
        .catch((e) => {
          console.log('e :>> ', e);
        }),
      partnerExclusivityList({partnerId: selectedPartnerId.current})
        .then((resp) => {
          setPartnerActivities(resp);
        })
        .catch((e) => {
          console.log('e :>> ', e);
        })
    ]);    
  }

  const handleSubmitPartner = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const requestBody = {
      name: values.partnerName,
      mutualCode: values.code,
      isActive: values.isActive,
    }

    //new
    if (isNew) {
      requestBody["partnerThemes"]= [
        {
          originalColor: "yellow",
          newColor: values.yellow
        },
        {
          originalColor: "green",
          newColor: values.green
        },
        {
          originalColor: "blue",
          newColor: values.blue
        },
        {
          originalColor: "salmon",
          newColor: values.salmon
        }
      ];
      createPartner(requestBody)
      .then((resp) => {
        console.log('resp :>> ', resp);
        message.success(t`partners:success.partnerCreated`, 10);
        const newPartners = [...partners, resp];
        setPartners(newPartners);
        setPartnerItem(resp);
        selectedPartnerId.current = resp?.id;
        // resetForm();
      })
      .catch((e) => {
        console.log('e :>> ', e);
        message.error(t`partners:errors.partnerCreated`, 10);
      })
      .finally(() => {
        setSubmitting(false);
        setIsNew(false);
      });
    }
    else {
      //modification
      requestBody["partnerThemes"] = [
        {
          id: partnerColors?.yellow?.id,
          originalColor: "yellow",
          newColor: values.yellow
        },
        {
          id: partnerColors?.green?.id,
          originalColor: "green",
          newColor: values.green
        },
        {
          id: partnerColors?.blue?.id,
          originalColor: "blue",
          newColor: values.blue
        },
        {
          id: partnerColors?.salmon?.id,
          originalColor: "salmon",
          newColor: values.salmon
        }
      ];
      if (selectedPartnerId.current) {
        updatePartner(selectedPartnerId.current, requestBody)
        .then((resp) => {
          console.log('updatePartner resp >>> :>> ', resp);
          message.success(t`partners:success.partnerModified`, 5);
        })
        .catch((e) => {
          console.log('e :>> ', e);
          message.error(t`partners:error.partnerModified`, 5);
        });
      }
    }
  };

  const renderPartnerDetail = useCallback(() => (
    <>
    {
      !isFetchingPartner &&
      <Formik 
      initialValues={{
        partnerName: partnerItem?.name || "",
        code: partnerItem?.mutualCode || "",
        isActive: partnerItem?.isActive || false,
        yellow: partnerColors?.yellow?.newColor || "",
        green: partnerColors?.green?.newColor || "",
        blue: partnerColors?.blue?.newColor || "",
        salmon: partnerColors?.salmon?.newColor || "",
      }}
      validateOnChange={true}
      validationSchema={partnerValidationSchema}
      onSubmit={handleSubmitPartner}>
      {({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
        handleSubmit,
        dirty,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        const retrieveErrors = (field) =>
          (touched[field] && errors[field]) || "";

        const handleChangeField = (e) => {
          e?.target?.name &&
            !touched[e.target.name] &&
            setFieldTouched(e.target.name);
          handleChange(e);
        };

        const handleChangeFieldByName = (name) => (value) => {
          setFieldTouched(name);
          setFieldValue(name, value);
        };

        return (
          <Form layout="vertical" onFinish={handleSubmit}>
            <h2>{!isNew && values.partnerName}</h2>
            <FormItem
              label={t("partners:detail.name")}
              help={retrieveErrors("partnerName")}
              validateStatus={retrieveErrors("partnerName") && "error"}
            >
              <Input
                name="partnerName"
                value={values.partnerName}
                onChange={handleChangeField}
              />
            </FormItem>
            <FormItem
              label={values.isActive ? t`partners:active` : t`partners:inactive`}
              help={retrieveErrors("isActive")}
              validateStatus={retrieveErrors("isActive") && "error"}
            >
              <Switch
                checked={values.isActive}
                onChange={handleChangeFieldByName("isActive")}
              />
            </FormItem>
            <FormItem
              label={t("partners:detail.code")}
              help={retrieveErrors("code")}
              validateStatus={retrieveErrors("code") && "error"}
            >
              <Input
                name="code"
                value={values.code}
                onChange={handleChangeField}
              />
            </FormItem>
            <div className="partner-color-container">
              <FormItem
                label={t("partners:detail.blue")}
                className="tag-content"
              >
              <CustomColorPicker 
                initialColor={values.blue || "#003466"}
                onChange={handleChangeFieldByName("blue")}
                />
                <Input
                  name="blue"
                  value={values.blue}
                />
              </FormItem>
              <FormItem
                label={t("partners:detail.salmon")}
                className="tag-content"
              >
                <CustomColorPicker
                  initialColor={values.salmon || "#F7A58D"}
                  onChange={handleChangeFieldByName("salmon")}
                />
                <Input
                  name="salmon"
                  value={values.salmon}
                />
              </FormItem>
              <FormItem
                label={t("partners:detail.yellow")}
                className="tag-content"
              >
                <CustomColorPicker 
                  initialColor={values.yellow || "#FFB93E"}
                  onChange={handleChangeFieldByName("yellow")}
                  />
                <Input
                  name="yellow"
                  value={values.yellow}
                />
              </FormItem>
              <FormItem
                label={t("partners:detail.green")}
                className="tag-content"
              >
                <CustomColorPicker
                  initialColor={values.green || "#3E9363"}
                  onChange={handleChangeFieldByName("green")}
                  />
                <Input
                  name="green"
                  value={values.green}
                />
              </FormItem>
              
            </div>
            <div className="partner-card-footer" style={{minHeight: partnerItem ? "50px" : "200px"}}>
              <TextButton rounded variant="salmon" width="14rem" htmlType="submit" disabled={!isValid || isSubmitting || !dirty}>
                {t`common:save`}
              </TextButton>
            </div>
          </Form>
        );
      }}
    </Formik>
    }
    </>
  ), [partnerItem, partnerColors, isFetchingPartner]);


  const handleUploadDocument = (values) => {
    setIsDocumentsLoading(true);
    const payload = new FormData();
    let fileType;

    if (values.document) { //base64
      const fileName = docFile?.current?.name;
      fileType = docFile?.current?.type;
      payload.append("doc", docBlob?.current, fileName);
    }

    const queryString = JSON.stringify({
      where: {
        category: values.category,
        partnerId: partnerItem?.id,
        filename: values.filename
      }
    });

    uploadPartnerDocument(queryString, payload, fileType)
      .then((resp) => {
        console.log('resp :>> ', resp);
        currentUploadedDocName.current = resp?.partnerDoc?.filename?.split(".")[0];
        const newDocuments = [...listFiles, resp?.partnerDoc];
        setListFiles(newDocuments);
        setIsUploadDocModalVisible(false);
        // message.success(t`partners:success.uploadPartnerDoc`, 5);
        setSuccessModalVisible(true);
      })
      .catch((e) => {
        console.log('e :>> ', e);
        message.error(t`partners:error.uploadPartnerDoc`, 5);
      })
      .finally(() => setIsDocumentsLoading(false));
  };

  const deleteDocument = () => {
    setIsDocumentsLoading(true);

    deletePartnerDocument(docIdToDelete.current)
      .then((resp) => {
        console.log('resp :>> ', resp);
        const newDocuments = [...listFiles];
        const deletedIndex = newDocuments.findIndex((item) => item.id === docIdToDelete.current)
        newDocuments.splice(deletedIndex, 1);
        setListFiles(newDocuments);

        message.success(t`partners:success.deletedPartnerDoc`, 5);

        docIdToDelete.current = undefined;
        closeDeleteDocDialog();
        setIsDocumentsLoading(false);
      })
      .catch((e) => {
        console.log('e :>> ', e);
        message.error(t`partners:error.deletedPartnerDoc`, 5);
      });
  };

  const closeDeleteDocDialog = () => setShowDeleteDocDialog(false);

  const renderDeleteTagDialog = () => (
    <Dialog
      open={showDeleteDocDialog}
      onClose={closeDeleteDocDialog}
      className="partner-delete-dialog-container"
    >
      <img src="/icons/delete.svg" alt="supprimer" width={80} />
      <DialogTitle>
        {t`partners:deleteDocModal.title`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t`partners:deleteDocModal.content`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton
          onClick={closeDeleteDocDialog}
          variant="red"
          type="outlined"
          width="10rem"
          rounded
        >
          {t`common:cancel`}
        </TextButton>
        <TextButton
          onClick={deleteDocument}
          variant="red"
          width="10rem"
          rounded
        >
          {t`partners:files.delete`}
        </TextButton>
      </DialogActions>
    </Dialog>
  );

  const handleGenerateApiKey = () => {
    generatePartnerApiKey(selectedPartnerId.current)
      .then((resp) => {
        console.log('resp :>> ', resp);
        setPartnerItem({...partnerItem, apiKey: resp.apiKey});
        closeGenerateApiKeyDialog();
        message.success(t`partners:success.apiKeyGenerated`, 5);
      })
      .catch((e) => {
        console.log('e?.response?.data?.error :>> ', e?.response?.data?.error);
        message.success(t`partners:error.apiKeyGenerated`, 5);
      });
  };

  const closeGenerateApiKeyDialog = () => setShowGenerateApiDialog(false);

  const renderGenerateApiKeyDialog = () => (
    <Dialog
      open={showGenerateApiDialog}
      onClose={closeGenerateApiKeyDialog}
      className="partner-delete-dialog-container"
    >
      <img src="/icons/token.svg" alt="supprimer" width={80} />
      <DialogTitle>
        {t`partners:apiKeyModal.title`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t`partners:apiKeyModal.content`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton
          onClick={closeGenerateApiKeyDialog}
          variant="red"
          type="outlined"
          width="10rem"
          rounded
        >
          {t`common:cancel`}
        </TextButton>
        <TextButton
          onClick={handleGenerateApiKey}
          variant="red"
          width="10rem"
          rounded
        >
          {t`partners:buttons.generate`}
        </TextButton>
      </DialogActions>
    </Dialog>
  );

  const handleSubmitCredits = (values, { setSubmitting }) => {
    setSubmitting(true);

    const payload = {
      id: selectedPartnerId?.current,
      amount: values.newCredit
    };

    updateCreditBalance(payload)
    .then((resp) => {
      console.log('resp :>> ', resp);
      setPartnerItem({
        ...partnerItem,
        creditBalance: resp?.creditBalance
      });
      setIsFetchingPartner(true);
    })
    .catch((e) => {
      console.log('e :>> ', e);
    })
    .finally(() => {
      setSubmitting(false);
      setIsFetchingPartner(false);
    });
  };

  const renderCreditsForm = useCallback(() => (
    <>
    {
      !isFetchingPartner ?
      <Formik 
        initialValues={{
          newCredit: 0,
          amountRemaining: partnerItem?.creditBalance || 0
        }}
        validateOnChange={true}
        validationSchema={creditsValidationSchema}
        onSubmit={handleSubmitCredits}>
        {({
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
          setFieldTouched,
          setFieldValue,
        }) => {
          const retrieveErrors = (field) =>
            (touched[field] && errors[field]) || "";

          const handleChangeField = (e) => {
            e?.target?.name &&
              !touched[e.target.name] &&
              setFieldTouched(e.target.name);
            handleChange(e);
          };

          return (
            <Form layout="vertical" onFinish={handleSubmit}>
              <FormItem
                label={t("partners:credits.newCredit")}
                help={retrieveErrors("newCredit")}
                validateStatus={retrieveErrors("newCredit") && "error"}
              >
                <Input
                  name="newCredit"
                  value={values.newCredit}
                  type="number"
                  onChange={handleChangeField}
                />
              </FormItem>
              <FormItem
                label={t("partners:credits.balance")}
              >
                <Input
                  name="amountRemaining"
                  value={values.amountRemaining}
                  readOnly
                />
              </FormItem>
              <div className="partner-card-footer">
              <TextButton rounded variant="salmon" width="14rem" htmlType="submit" disabled={!isValid || isSubmitting || !dirty}>
                {t`partners:credits.add`}
              </TextButton>
            </div>
            </Form>
          )
        }}
      </Formik>
      : <Spin />
    }
    </>
  ), [partnerItem, isFetchingPartner]);

  return (
    <>
      <PartnerStyle />
      <MainLayout className="partner-main-container">
        <ParameterHeader icon={<PartnerIcon />} title={t`partners:title`} goBack={"/parametres"} />
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Card className="partner-card">
              <h2>{t`partners:list.title`}</h2>
              <div className="partner-list-container">
                {partners?.map(renderPartnerItem)}
              </div>
              <div className="partner-card-footer">
                <TextButton rounded variant="salmon" width="14rem" onClick={handleAddPartner}>
                  {t`partners:buttons.addAccount`}
                </TextButton>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Tabs
              defaultActiveKey="1"
              type="card"
            >
              <TabPane tab={t`partners:tabs.detail`} key="1">
                <Card className="partner-card">
                  {
                    renderPartnerDetail()
                    // ||
                    //   <h2>{t`partners:detail.title`}</h2>
                  }
                </Card>
                {
                  partnerItem &&
                    <Card className="partner-api-key-container">
                      <h2>{t`partners:apiKey.title`}</h2>
                      <div className="partner-api-key-content">
                        <label>{t`partners:apiKey.field`}</label>
                        <p>
                          {partnerItem?.apiKey}
                        </p>
                      </div>
                      <TextButton 
                        variant="salmon"
                        rounded
                        type="outlined"
                        onClick={() => setShowGenerateApiDialog(true)}>
                          {t`partners:buttons.generateKey`}
                      </TextButton>
                    </Card>
                }
                {renderGenerateApiKeyDialog()}
              </TabPane>
              {
                partnerItem &&
                <>
                  <TabPane tab={t`partners:detail.adminAccounts`} key="2">
                    <Card className="partner-card">
                      <h2>{t`partners:detail.adminAccounts`}</h2>
                      {administrators?.map(renderAdminItem)}
                      <div className="partner-card-footer">
                        <TextButton
                            rounded
                            variant="salmon"
                            width="16rem"
                            disabled={partners.length <= 0}
                            onClick={handleCreateNewAdmin}>
                          {t`partners:buttons.addAdmin`}
                        </TextButton>
                      </div>
                    </Card>
                  </TabPane>
                  <TabPane tab={t`partners:tabs.files`} key="3">
                    <Card className="partner-card">
                      <div className="partner-document-header-container">
                        <h2>{t`partners:tabs.files`}</h2>
                        <TextButton
                          rounded
                          variant="salmon"
                          width="12rem"
                          onClick={() => setIsUploadDocModalVisible(true)}>{t`partners:buttons.addFile`}</TextButton>
                      </div>
                      <Table
                        rowKey={record => record.id}
                        loading={isDocumentsLoading}
                        columns={columns}
                        dataSource={listFiles}
                        locale={{ emptyText: t`common:table.emptyText` }}
                        pagination={{
                          pageSize: LIMIT,
                          defaultPageSize: LIMIT,
                          total: listFiles?.length,
                          showSizeChanger: false
                        }}
                        onRow={(record) => {
                          return {
                            onClick: () => {window.open(`${process.env.REACT_APP_S3_CDN_URL}${record.url}`, "_blank")},
                          };
                        }}
                      />
                    </Card>
                  </TabPane>
                  <TabPane tab={t`partners:tabs.exclusives`} key="4">
                    <Card className="partner-card">
                    <Table
                        rowKey={record => record.id}
                        columns={activityColumns}
                        dataSource={partnerActivities}
                        locale={{ emptyText: t`common:table.emptyText` }}
                        pagination={{
                          pageSize: LIMIT,
                          defaultPageSize: LIMIT,
                          total: partnerActivities?.length,
                          showSizeChanger: false
                        }}
                      />
                    </Card>
                  </TabPane>
                  <TabPane tab={t`partners:tabs.credits`} key="5">
                    <Card className="partner-card">
                      {renderCreditsForm()}
                    </Card>
                  </TabPane>
                </>
                
              }
            </Tabs>
          </Col>
        </Row>
        {renderDeleteTagDialog()}
        <Modal title={<h2>{isNewAdmin ? t`partners:detail.newAdmin` : t`partners:detail.adminAccounts`}</h2>} 
              visible={isAdminModalVisible}
              onCancel={() => setIsAdminModalVisible(false)}
              centered
              destroyOnClose={true}
              footer={null}
        >
          <Formik
            initialValues={{
              firstname: selectedAdmin?.current?.firstname || "",
              lastname: selectedAdmin?.current?.name || "",
              email: selectedAdmin?.current?.email || "",
              phone: selectedAdmin?.current?.phoneNumber || "",
              isActive: selectedAdmin?.current?.isActive || false,
            }}
            validateOnChange={true}
            validationSchema={adminValidationSchema}
            onSubmit={handleSubmitAdmin}>
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleSubmit,
              dirty,
              isValid,
              setFieldTouched,
              setFieldValue,
              resetForm,
            }) => {
              const retrieveErrors = (field) =>
                (touched[field] && errors[field]) || "";

              const handleChangeField = (e) => {
                e?.target?.name &&
                  !touched[e.target.name] &&
                  setFieldTouched(e.target.name);
                handleChange(e);
              };

              const handleChangeFieldByName = (name) => (value) => {
                setFieldTouched(name);
                setFieldValue(name, value);
              };
              
              return (
                <Form layout="vertical" onFinish={handleSubmit}>
                  {
                    !isNewAdmin && 
                    <FormItem
                      label={values.isActive ? t`partners:active` : t`partners:inactive`}
                      help={retrieveErrors("isActive")}
                      validateStatus={retrieveErrors("isActive") && "error"}
                    >
                      <Switch
                        checked={values.isActive}
                        onChange={handleChangeFieldByName("isActive")}
                      />
                    </FormItem>
                  }
                  <FormItem
                    label={t("partners:detail.firstname")}
                    help={retrieveErrors("firstname")}
                    validateStatus={retrieveErrors("firstname") && "error"}
                  >
                    <Input
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChangeField}
                    />
                  </FormItem>
                  <FormItem
                    label={t("partners:detail.lastname")}
                    help={retrieveErrors("lastname")}
                    validateStatus={retrieveErrors("lastname") && "error"}
                  >
                    <Input
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChangeField}
                    />
                  </FormItem>
                  <FormItem
                    label={t("partners:detail.email")}
                    help={retrieveErrors("email")}
                    validateStatus={retrieveErrors("email") && "error"}
                  >
                    <Input
                      name="email"
                      value={values.email}
                      onChange={handleChangeField}
                    />
                  </FormItem>
                  <FormItem
                    label={t("partners:detail.phone")}
                    help={retrieveErrors("phone")}
                    validateStatus={retrieveErrors("phone") && "error"}
                  >
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={handleChangeField}
                    />
                  </FormItem>
                  <div className="partner-card-footer">
                    <TextButton 
                      variant="salmon" 
                      rounded width="12rem" 
                      htmlType="submit" 
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}>
                      {t`common:save`}
                    </TextButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          title={<h2>{t`partners:files.modal.title`}</h2>}
          visible={isUploadDocModalVisible}
          onCancel={() => setIsUploadDocModalVisible(false)}
          centered
          destroyOnClose
          footer={null}
          className="partner-modal"
        >
          <Formik
            initialValues={{
              filename: "",
              category: "",
              document: "",
            }}
            validateOnChange={true}
            validationSchema={documentUploadValidationSchema}
            onSubmit={handleUploadDocument}>
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleSubmit,
              dirty,
              isValid,
              setFieldTouched,
              setFieldValue,
              resetForm,
            }) => {
              const retrieveErrors = (field) =>
                (touched[field] && errors[field]) || "";

              const handleChangeField = (e) => {
                e?.target?.name &&
                  !touched[e.target.name] &&
                  setFieldTouched(e.target.name);
                handleChange(e);
              };

              const handleChangeFieldByName = (name) => (value) => {
                setFieldTouched(name);
                setFieldValue(name, value);
              };

              const handleDocumentUpload = (name) => ({ blob, base64, file }) => {
                setFieldValue(name, base64);
                docBlob.current = blob;
                docFile.current = file;
              };
              
              return (
                <Form layout="vertical" onFinish={handleSubmit}>
                  <div className="partner-document-upload-fields">
                    <div>
                      <FormItem
                        label={t("partners:files.modal.name")}
                        help={retrieveErrors("filename")}
                        validateStatus={retrieveErrors("filename") && "error"}
                      >
                        <Input
                          name="filename"
                          value={values.filename}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                      <FormItem
                        label={t("partners:files.modal.category")}
                        help={retrieveErrors("category")}
                        validateStatus={retrieveErrors("category") && "error"}
                      >
                        <Select
                          value={values.category}
                          onChange={handleChangeFieldByName("category")}
                          bordered={true}
                        >
                          {Object.values(DOCUMENT_CATEGORIES).map((opt: any) => (
                            <Option key={opt} value={opt}>
                              {t(`partners:files.categories.${opt}`)}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                    <FormItem
                      label={t("partners:files.modal.preview")}
                      help={retrieveErrors("document")}
                      validateStatus={retrieveErrors("document") && "error"}
                    >
                      <FileUploader
                        acceptedFileExts={[".pdf", ".xlsx", ".xls"]}
                        maxFileSize={MAX_FILE_SIZE * 1024 * 1024}
                        uploadFile={handleDocumentUpload("document")}
                      >
                        {
                          values.document ?
                            (<img src={fullImageUrl(values.document)} alt="" className="category-image-preview" />)
                          :
                            <div className="partner-document-container">
                              <PlusOutlined />
                              <div>{t`partners:files.upload`}</div>
                            </div>
                        }
                      </FileUploader>
                    </FormItem>
                  </div>
                  <div className="partner-card-footer">
                    <TextButton 
                      variant="green" 
                      type="outlined" 
                      rounded
                      width="12rem"
                      onClick={() => setIsUploadDocModalVisible(false)}
                    >
                      {t`common:cancel`}
                    </TextButton>
                    <TextButton 
                      variant="green"
                      rounded width="12rem" 
                      htmlType="submit" 
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}>
                      {t`partners:buttons.validate`}
                    </TextButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          title={null}
          visible={successModalVisible}
          onCancel={() => setSuccessModalVisible(false)}
          className="pro-validated-call-modal"
          footer={null}
          closable
          maskClosable
          centered
        >
          <img
            src="/icons/green-tick.svg"
            alt="green tick"
            className="green-tick"
          />
          
          <div className="validated-call-title">
            <h2>{t`partners:files.fileUploaded`}</h2>
            <p>{t("partners:files.description", { filename: currentUploadedDocName.current})}</p>
          </div>
        </Modal>
      </MainLayout>
    </>
  );
};

export default Partners;