import React, { useEffect, useRef, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PromosStyle from "styles/promosStyles";
import { Button, Card, Col, Dropdown, Menu, Row, Space, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { format } from "date-fns";
import { EditOutlined } from "@material-ui/icons";
import { DeleteIcon } from "components/icons";
import { TextButton } from "components";
import { localizeCurrency } from "utils";
import { getPromoList, deletePromoItem } from "services/promos";
import { useTheme } from "definitions/styled-components";

const PromoList = () => {
  const { i18n, t } = useTranslation(["common", "promos"]);
  const history = useHistory();
  const { theme } = useTheme();
  const [limit, setLimit] = useState(25);
  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDeletePromoDialog, setShowDeletePromoDialog] = useState(false);
  const promoIndexToDelete = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: t`promos:columns.type`,
      dataIndex: "type",
      render: (type) => t(`promos:types.${type}`),
      sorter: (a, b) => {
        return a.type > b.type ? 1 : -1;
      }
    },
    {
      title: t`promos:columns.code`,
      dataIndex: "code",
      sorter: (a, b) => {
        return a.code > b.code ? 1 : -1;
      }
    },
    {
      title: t`promos:columns.client`,
      dataIndex: "client",
      align: "center" as "center",
    },
    {
      title: t`promos:columns.buyer`,
      dataIndex: "buyer",
      align: "center" as "center",
      sorter: (a, b) => a.createdAt > b.createdAt ? 1 : -1,
    },
    {
      title: t`promos:columns.total`,
      dataIndex: "discountAmount",
      render: (discountAmount) => `${localizeCurrency(
        +discountAmount || 0,
        i18n.language
      )}€`,
      align: 'right' as "right"
    },
    {
      title: t`promos:columns.remainingAmt`,
      dataIndex: "remaining",
      render: (remaining) => `${localizeCurrency(
        +remaining || 0,
        i18n.language
      )}`,
      align: 'right' as "right"
    },
    {
      title: t`promos:columns.expiryDate`,
      dataIndex: "endDate",
      render: (endDate) => endDate ? format(new Date(endDate), `dd/MM/yyyy ${t("common:at")} HH:mm`) : "",
      align: "center" as "center"
    },
    {
      title: t`promos:columns.edit`,
      render: (record) => {
        return (
          <Button type="primary" icon={<EditOutlined />} className="promos-icon-button-edit" onClick={handleEditPromo(record.id)}/>
        );
      },
      align: 'center' as "center"
    },
    {
      title: t`promos:columns.delete`,
      render: (record) => {
        return (
          <Button type="primary" icon={<DeleteIcon  fill={theme.colors.white} stroke={theme.colors.white}/>} className="promos-icon-button-delete" onClick={() => openDeletePromoDialog(record.id)}/>
        );
      },
      align: "center" as "center"
    },
  ];

  const openDeletePromoDialog = (index) => {
    promoIndexToDelete.current = index;
    setShowDeletePromoDialog(true);
  }

  const closeDeletePromoDialog = () => setShowDeletePromoDialog(false);

  const handleEditPromo = (id) => () => history.push(`/promos/${id}`);

  useEffect(() => {
    getPromoList().then((resp) => {
      resp.filter((item) => !item.isDeleted);
      setAllData(resp);
      setTotalRecords(resp.length);
    }).catch((e) => console.log(e));
  }, []);

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let searchParams = null;
    searchParams = {
      where: {
        code: searchTerm,
      }
    }
    console.log('searchParams :>> ', searchParams);
    getPromoList(JSON.stringify(searchParams)).then((resp) => {
      console.log("SEARCH FETCH",resp);
      setAllData(resp);
      setTotalRecords(resp.length);
    }).catch((e) => console.log(e));
  };

  const renderNewRecordMenu = () => (
    <Menu>
      <Menu.Item key="newPromoCode">
        <a href="/promos/nouvelle">
          {t`promos:newPromoCode`}
        </a>
      </Menu.Item>
      <Menu.Item key="newGiftCard">
        <a href="/promos/nouvelle">
          {t`promos:newGiftCard`}
        </a>
      </Menu.Item>
    </Menu>
  );

  const renderDeletePromoDialog = () => (
    <Dialog
      open={showDeletePromoDialog}
      onClose={closeDeletePromoDialog}
    >
      <DialogTitle>
        {t`promos:deletePromo.title`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t`promos:deletePromo.content`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton
          onClick={closeDeletePromoDialog}
          variant="darkBlue"
          width="10rem"
          rounded
        >
          {t`promos:deleteModal.no`}
        </TextButton>
        <TextButton
          onClick={handleDeletePromoItem}
          variant="darkBlue"
          type="outlined"
          width="10rem"
          rounded
        >
          {t`promos:deleteModal.yes`}
        </TextButton>
      </DialogActions>
    </Dialog>
  );

  const handleDeletePromoItem = () => {
    if (promoIndexToDelete) {
      setIsLoading(true);
      const deletedPromoItemId = promoIndexToDelete?.current;
      deletePromoItem(deletedPromoItemId)
        .then((resp) => {
          getPromoList().then((resp) => {
            setAllData(resp);
            setTotalRecords(resp.length);
          }).catch((e) => console.log(e));
        })
        .catch((e) => console.log(e))
        .finally(()=> {
          setIsLoading(false);
          closeDeletePromoDialog()
        });
    }
  };
  
  return (
    <MainLayout>
      <PromosStyle />
      <Card>
        <Row gutter={[16, 8]} justify="start">
          <Col>
            <h1 className="promos-title">{t`promos:title`}</h1>
          </Col>
          <Col>
            <Dropdown overlay={renderNewRecordMenu} placement="bottomCenter" arrow>
              <Button type="primary" icon={<PlusOutlined />} shape="circle" className="create-promos-button" />
            </Dropdown>
          </Col>
        </Row>
        <Row justify="end" gutter={[16, 8]} className="promos-list-header">
          <Col className="search-text-container">
            <Space>
              <p className="search-text">{t("common:search")}:</p>
              <TextField radius="0.8125rem" onPressEnter={handleSearch} />
            </Space>
          </Col>
        </Row>
        {renderDeletePromoDialog()}
        <Table
          rowKey={record => record.id}
          loading={isLoading}
          columns={columns}
          dataSource={allData}
          locale={{
            filterTitle: t`common:table.filterTitle`,
            filterConfirm: t`common:table.filterConfirm`,
            filterReset: t`common:table.filterReset`,
            filterEmptyText: t`common:table.filterEmptyText`,
            emptyText: t`common:table.emptyText`,
            selectAll: t`common:table.selectAll`,
            selectInvert: t`common:table.selectInvert`,
            selectNone: t`common:table.selectNone`,
            selectionAll: t`common:table.selectionAll`,
            sortTitle: t`common:table.sortTitle`,
            expand: t`common:table.expand`,
            collapse: t`common:table.collapse`,
            triggerDesc: t`common:table.triggerDesc`,
            triggerAsc: t`common:table.triggerAsc`,
            cancelSort: t`common:table.cancelSort`,
          }}
          pagination={{
            pageSize: limit,
            defaultPageSize: limit,
            total: totalRecords,
            showSizeChanger: false
          }}
        />
      </Card>
    </MainLayout>
  );
};

export default PromoList;
