import * as React from "react";

function SvgNotificationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.186'
      height='25.908'
      viewBox='0 0 22.186 25.908'
    >
      <g id='noun_notification_1890223' transform='translate(-12.5 -6.1)'>
        <g
          id='Group_2274'
          data-name='Group 2274'
          transform='translate(12.5 6.1)'
        >
          <path
            id='Path_1381'
            data-name='Path 1381'
            d='M35.923,25.5a5.8,5.8,0,0,0-5.929,5.039.6.6,0,0,0,.534.652h.059a.607.607,0,0,0,.593-.534,4.621,4.621,0,0,1,4.773-3.943.6.6,0,0,0,.593-.593A.647.647,0,0,0,35.923,25.5Z'
            transform='translate(-24.807 -19.749)'
            fill='#b5b5b5'
          />
          <path
            id='Path_1382'
            data-name='Path 1382'
            d='M30.493,49.9a.6.6,0,0,0-.593.593v2.579a.593.593,0,0,0,1.186,0V50.493A.6.6,0,0,0,30.493,49.9Z'
            transform='translate(-24.742 -36.916)'
            fill='#b5b5b5'
          />
          <path
            id='Path_1383'
            data-name='Path 1383'
            d='M34.554,28.955c0-.03.03-.03.03-.059s.03-.03.03-.059.03-.03.03-.059,0-.03.03-.059,0-.03,0-.059v-.237c0-.03,0-.03-.03-.059s0-.03-.03-.059c0-.03-.03-.03-.03-.059s-.03-.03-.03-.059v-.03l-2.46-3.172V17.275A8.511,8.511,0,0,0,26.847,9.42V9.361A3.251,3.251,0,0,0,25.9,7.049,3.2,3.2,0,0,0,23.586,6.1a3.251,3.251,0,0,0-3.261,3.261V9.42a8.511,8.511,0,0,0-5.247,7.855v7.707l-2.46,3.172v.03c0,.03-.03.03-.03.059s-.03.03-.03.059,0,.03-.03.059c0,.03,0,.03-.03.059v.3c0,.03,0,.03.03.059,0,.03.03.03.03.059s.03.03.03.059.03.03.03.059l.03.03c0,.03.03.03.059.03l.03.03h0c.059.03.089.059.148.089h.03a.376.376,0,0,0,.178.03h6.67a4.076,4.076,0,0,0,3.854,2.846,4.011,4.011,0,0,0,3.854-2.846H34.05a.376.376,0,0,0,.178-.03h.03a.648.648,0,0,0,.148-.089h0l.03-.03c.03,0,.03-.03.059-.03C34.554,28.984,34.554,28.984,34.554,28.955Zm-20.216-.949L16,25.842H31.234l1.66,2.164ZM23.616,7.375a2.014,2.014,0,0,1,1.453.593,2.044,2.044,0,0,1,.563,1.126,8.939,8.939,0,0,0-4.091,0A2.127,2.127,0,0,1,23.616,7.375Zm-7.322,9.99a7.322,7.322,0,0,1,14.644,0v7.322H16.294Zm7.351,13.488a2.88,2.88,0,0,1-2.609-1.66h5.217A2.932,2.932,0,0,1,23.646,30.852Z'
            transform='translate(-12.5 -6.1)'
            fill='#b5b5b5'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgNotificationIcon;
