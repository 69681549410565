import * as React from "react";

function SvgBackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.667"
      height="10.63"
      viewBox="0 0 6.667 10.63"
    >
      <g
        id="Group_2445"
        data-name="Group 2445"
        transform="translate(-89.525 -140.615)"
      >
        <path
          id="Path_1256"
          data-name="Path 1256"
          d="M1298.171,1464.772l-3.848,4.062,3.848,3.74"
          transform="translate(-1203.394 -1322.743)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default SvgBackIcon;
