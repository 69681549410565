import { atom } from "recoil";
// import { adminLogin } from "store/selectors/userSelector";

export const currentUser = atom({
  key: "currentUser",
  default: "",
});

export const loggedInUserData = atom({
  key: "loggedInUser",
  default: {
    email: "",
    password: "",
  },
});

export const authState = atom({
  key: "authState",
  default: {
    token: "",
    // currentUser: null,
  },
});

// export const userListState = atom<string>({
//   key: "userListState",
//   default: adminLogin,
// });

// export const menuCollapsedState = atom({
//   key: "menuCollapsedState",
//   default: false,
// });
