import * as React from "react";

function SvgFilesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.347"
      height="38.11"
      viewBox="0 0 33.347 38.11"
    >
      <path
        id="Icon_metro-files-empty"
        data-name="Icon metro-files-empty"
        d="M36.275,15.217a33.74,33.74,0,0,0-3.245-3.711A33.755,33.755,0,0,0,29.32,8.262c-1.919-1.408-2.851-1.57-3.384-1.57H12.24A2.981,2.981,0,0,0,9.263,9.669V37.061a2.981,2.981,0,0,0,2.977,2.977H34.868a2.981,2.981,0,0,0,2.977-2.977V18.6c0-.533-.163-1.464-1.57-3.384Zm-4.929-2.026a32.485,32.485,0,0,1,2.7,3.029h-5.73V10.49a32.461,32.461,0,0,1,3.029,2.7Zm4.117,23.87a.6.6,0,0,1-.6.6H12.24a.6.6,0,0,1-.6-.6V9.669a.6.6,0,0,1,.6-.6h13.7V17.41A1.191,1.191,0,0,0,27.127,18.6h8.337ZM24.556,3.5c-1.919-1.408-2.851-1.57-3.384-1.57H7.476A2.981,2.981,0,0,0,4.5,4.905V32.3a2.982,2.982,0,0,0,2.382,2.917V4.905a.6.6,0,0,1,.6-.6H25.6c-.359-.295-.708-.568-1.04-.811Z"
        transform="translate(-4.499 -1.928)"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFilesIcon;
