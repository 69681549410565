import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";
import { padding } from "styled-system";

export type ITextButton = ButtonProps;

const retrieveHoverBrightness = (variant, defaultValue) => {
  if (defaultValue) return defaultValue;
  if (variant === "yellow") return 1.1;
  if (variant === "green") return 1.2;
  if (variant === "blue") return 1.5;
  if (variant === "salmon") return 1.1;
  if (variant === "pink") return 0.95;
  if (variant === "white") return 1.1;
  if (variant === "darkBlue") return 1.5;
  if (variant === "red") return 1.1;
};

export const TextButton = styled(
  ({
    padding,
    rounded,
    uppercase,
    width,
    type,
    variant,
    hoverBrightness,
    lineHeight,
    style,
    ...rest
  }) => {
    return <Button {...rest} data-testid="btn" style={style || {}} />;
  }
)`
  --btn-height: ${(props) => props.height || "3rem"};

  font-family: "Glacial Indifference";
  font-size: 1.125rem;
  font-weight: bold;
  line-height: ${(props) => props.lineHeight || "1.5"};
  border-radius: ${({ rounded }) =>
    rounded ? "calc(var(--btn-height) / 2)" : "0"};
  box-shadow: var(--box-shadow);
  border: ${({ variant = "yellow", type, theme }) =>
    type === "outlined"
      ? `0.2em solid ${variant === "white" ? theme.colors.transparent : theme.colors[variant]
      }`
      : "none"};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  color: ${({ variant = "yellow", type, theme }) => {
    return `${variant === "white"
        ? theme.colors.shades.grey[18]
        : type === "outlined"
          ? theme.colors[variant]
          : theme.colors.white
      } !important`;
  }};
  background: ${({ variant = "yellow", type, theme }) =>
    theme.colors[type === "outlined" ? "white" : variant]};
  height: var(--btn-height);
  max-width: 100%;
  width: ${(props) => props.width || "20.25rem"};
  ${padding}

  &:hover:enabled,
  &:focus:enabled {
    filter: ${({ variant = "yellow", hoverBrightness }) =>
    `brightness(${retrieveHoverBrightness(variant, hoverBrightness)})}`};
    color: ${({ variant = "yellow", type, theme }) =>
    theme.colors[type === "outlined" ? variant : "white"]};
    background: ${({ variant = "yellow", type, theme }) =>
    `${theme.colors[type === "outlined" ? "white" : variant]} !important`};
    border: ${({ variant = "yellow", type, theme }) =>
    type === "outlined"
      ? variant === "white"
        ? `0.2em solid ${theme.colors.shades.grey[18]} !important`
        : `0.2em solid ${theme.colors[variant]} !important`
      : "none !important"};
  }

  &:disabled {
    color: ${({ theme }) => `${theme.colors.white} !important`};
    border-color: ${({ theme }) =>
    `${theme.colors.shades.grey[43]} !important`};
    background: ${({ theme }) => `${theme.colors.shades.grey[43]} !important`};
  }

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }
`;
