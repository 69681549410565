import * as React from "react";

function SvgMessagerieIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.224"
      height="23.52"
      viewBox="0 0 28.224 23.52"
    >
      <path
        id="Path_471"
        data-name="Path 471"
        d="M-239.54,380.374a.609.609,0,0,0-.638.573.589.589,0,0,0,.638.573h10.248a.609.609,0,0,0,.638-.573.589.589,0,0,0-.638-.573h-4.5V377.54h9.71a2.18,2.18,0,0,0,2.285-2.05v-6.574a.607.607,0,0,0-.638-.573.589.589,0,0,0-.638.573v4.4h-22.747v-10.1a.964.964,0,0,1,1.008-.9h9.946a.609.609,0,0,0,.638-.573.607.607,0,0,0-.638-.573h-9.946a2.18,2.18,0,0,0-2.285,2.05v12.273a2.179,2.179,0,0,0,2.285,2.05h9.71v2.834Zm-6.25-4.885v-1.055h22.747v1.055a.964.964,0,0,1-1.008.9h-20.7A.974.974,0,0,1-245.79,375.489Zm12.365-15.409v5.458a2.179,2.179,0,0,0,2.285,2.05h.672v2.051a.53.53,0,0,0,.37.513.679.679,0,0,0,.235.06.756.756,0,0,0,.437-.151l2.856-2.473h5.41a2.18,2.18,0,0,0,2.285-2.05v-5.488A2.179,2.179,0,0,0-221.161,358h-10.013A2.193,2.193,0,0,0-233.425,360.081Zm13.306,0v5.458a.964.964,0,0,1-1.008.9h-5.678a.756.756,0,0,0-.437.151l-1.982,1.719v-1.266a.607.607,0,0,0-.638-.573h-1.277a.963.963,0,0,1-1.008-.9v-5.488a.964.964,0,0,1,1.008-.9h10.013A.944.944,0,0,1-220.119,360.081Zm-6.854,2.895a.779.779,0,0,1,.806-.724.779.779,0,0,1,.806.724.779.779,0,0,1-.806.724A.74.74,0,0,1-226.974,362.975Zm-3.091,0a.779.779,0,0,1,.806-.724.779.779,0,0,1,.806.724.779.779,0,0,1-.806.724A.759.759,0,0,1-230.065,362.975Zm6.182,0a.779.779,0,0,1,.806-.724.779.779,0,0,1,.806.724.779.779,0,0,1-.806.724A.759.759,0,0,1-223.882,362.975Z"
        transform="translate(247.1 -358)"
        fill="#b0b0b0"
      />
    </svg>
  );
}

export default SvgMessagerieIcon;
