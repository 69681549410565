import React from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import ParametersStyle from "styles/parametersStyles";
import { ParameterCard } from "components";
import { AccountIcon, AnnoncesIcon, EmailParamIcon, FileIcon, MaintenanceIcon, NotificationIcon, ParameterIcon, ParameterWhiteIcon, PartnerIcon, UsersIcon } from "components/icons";

const Parameters: React.FC = () => {

  const { t } = useTranslation(["common", "parameters"]);

  return (
    <MainLayout>
      <ParametersStyle />
      <Card bordered={false}>
        <h1
          style={{ fontSize: "40pt", color: "#636363" }}
        >{t`parameters:title`}</h1>

        <Row gutter={[32, 32]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<ParameterIcon fill={"#fff"} stroke={"fff"} />} title={t`parameters:card.general.title`} description={t`parameters:card.general.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<AccountIcon />} title={t`parameters:card.compte.title`} description={t`parameters:card.compte.description`} path='/parametres/comptes-admin'/>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<MaintenanceIcon />} title={t`parameters:card.maintenance.title`} description={t`parameters:card.maintenance.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<UsersIcon fill={"#fff"} stroke={"#fff"} />} title={t`parameters:card.users.title`} description={t`parameters:card.users.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<AnnoncesIcon fill={"#fff"} stroke={"#fff"}/>}  title={t`parameters:card.announcement.title`} description={t`parameters:card.announcement.description`} path='/parametres/annonce'/>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<FileIcon />}  title={t`parameters:card.files.title`} description={t`parameters:card.files.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<EmailParamIcon />}  title={t`parameters:card.emails.title`} description={t`parameters:card.emails.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<NotificationIcon />} title={t`parameters:card.notifications.title`} description={t`parameters:card.notifications.description`} />
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <ParameterCard icon={<PartnerIcon />} 
              title={t`parameters:card.partners.title`} 
              description={t`parameters:card.partners.description`} 
              path="/parametres/partenaires" />
          </Col>
        </Row>
      </Card>
    </MainLayout>
  );
};

export default Parameters;
