import * as React from "react";

function SvgRatingTickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.994"
      height="8.686"
      viewBox="0 0 10.994 8.686"
    >
      <path
        id="Path_569"
        data-name="Path 569"
        d="M15215.154-2148.171l2.953,3.834s3.418-6.527,6.992-7.459"
        transform="translate(-15214.593 2152.284)"
        fill="none"
        stroke="blue"
        strokeLinecap="round"
        strokeWidth="0.8"
      />
    </svg>
  );
}

export default SvgRatingTickIcon;
