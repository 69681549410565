import "antd/dist/antd.css";
import { StyledThemeProvider } from "definitions/styled-components";
import { RecoilRoot } from "recoil";
import "./i18n";
import SignIn from "pages/signin";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import UserList from "pages/users";
import UserDetail from "pages/users/detail";
import Dashboard from "pages/dashboard";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import "./fonts/style.css";
import { Space, Spin } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import Parameters from "pages/parameters";
import ParametreAnnonces from "pages/parameters/annonces";
import Categories from "pages/parameters/annonces/categories";
import AdvertList from "pages/annonces";
import AdvertDetail from "pages/annonces/detail";
import AdminList from "pages/parameters/comptes";
import AdminDetail from "pages/parameters/comptes/detail";
import RatingList from "pages/evaluations";
import RatingDetail from "pages/evaluations/detail";
import FeaturedList from "pages/featured";
import FeaturedDetail from "pages/featured/detail";
import PromoList from "pages/promos";
import PromoDetail from "pages/promos/detail";
import TransactionDetail from "pages/transaction/detail";
import TransactionList from "pages/transaction";
import Notifications from "pages/notifications";
import ForgotPassword from "pages/forgotPassword";
import ResetPassword from "pages/resetPassword";
import AccountActivation from "pages/accountActivation";
import Messages from "pages/messages";
import { GlobalContextProvider } from "contexts";
import Partners from "pages/parameters/partenaires";
import ProTransactionList from "pages/proTransactions";
import CheckOut from "pages/proTransactions/checkout";
import Cookies from 'js-cookie'

function App(): JSX.Element {
  const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      if (getIsLoggedIn()) {
        next();
      }
      next.redirect("/connexion");
    } else {
      next();
    }
  };

  const getIsLoggedIn = () => {
    // return sessionStorage.getItem("token");
    return Cookies.get("token");
    // return localStorage.getItem("authKey");
  };

  const waitOneSecond = (to, from, next) => {
    setTimeout(next, 1000);
  };

  const GLOBAL_GUARDS = [requireLogin, waitOneSecond];

  const renderLoader = () => {
    const antIcon = <SyncOutlined style={{ fontSize: 64, color: "#003466" }} spin />
    return (
      <Space style={{ width: "100%", height: "100%", position: "relative", zIndex: 1, top: 300, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "#003466"
          }}
          indicator={antIcon}
        />
      </Space>
    );
  };

  return (
    <StyledThemeProvider>
      <RecoilRoot>
        {/* <Router>
          <Switch>
          <Route path="/">
          <Dashboard />
          </Route>
          <Route path="/login">
          <Login />
          </Route>
          <Route path="/users">
          <UserList />
          </Route>
          </Switch>
        </Router> */}
        <GlobalContextProvider>
          <Router>
            <GuardProvider
              guards={GLOBAL_GUARDS}
              loading={() => renderLoader()}
            // error={NotFound}
            >
              <Switch>
                <GuardedRoute path="/connexion" exact component={SignIn} />
                <GuardedRoute
                  path="/"
                  exact
                  component={Dashboard}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/tableau-de-bord"
                  exact
                  component={Dashboard}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/notifications"
                  exact
                  component={Notifications}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/transactions"
                  exact
                  component={TransactionList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/transactions/:id"
                  exact
                  component={TransactionDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/utilisateurs"
                  exact
                  component={UserList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/utilisateurs/:id"
                  exact
                  component={UserDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/annonces"
                  exact
                  component={AdvertList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/annonces/:id"
                  exact
                  component={AdvertDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/evaluations"
                  exact
                  component={RatingList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/evaluations/:id"
                  exact
                  component={RatingDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/parametres"
                  exact
                  component={Parameters}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/parametres/annonce"
                  exact
                  component={ParametreAnnonces}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/parametres/annonce/categories"
                  exact
                  component={Categories}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/parametres/comptes-admin"
                  exact
                  component={AdminList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/parametres/comptes-admin/:id"
                  exact
                  component={AdminDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/messageries"
                  exact
                  component={Messages}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/a-la-une"
                  exact
                  component={FeaturedList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/a-la-une/:id"
                  exact
                  component={FeaturedDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/promos"
                  exact
                  component={PromoList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/promos/:id"
                  exact
                  component={PromoDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/mot-de-passe-oublie"
                  exact
                  component={ForgotPassword}
                  meta={{ auth: false }}
                />
                <GuardedRoute
                  path="/reinitialisation-mot-de-passe"
                  exact
                  component={ResetPassword}
                  meta={{ auth: false }}
                />
                <GuardedRoute
                  path="/activation-compte"
                  exact
                  component={AccountActivation}
                  meta={{ auth: false }}
                />
                <GuardedRoute
                  path="/parametres/partenaires"
                  exact
                  component={Partners}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/pro-transactions"
                  exact
                  component={ProTransactionList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/confirmation-checkout"
                  exact
                  component={CheckOut}
                  meta={{ auth: true }}
                />
              </Switch>
            </GuardProvider>
          </Router>
        </GlobalContextProvider>
      </RecoilRoot>
    </StyledThemeProvider>
  );
}

export default App;
