import { createGlobalStyle } from "styled-components";

const NotificationStyle = createGlobalStyle`
.notification-list-header {
  margin: 0 0 1rem 0;

  .featured-title {
    margin-bottom: 0;
    font-family: var(--font-glacial);
    font-weight: 600;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[18]};
  }

  .filter-dropdown {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.shades.grey[25]};
    font-family: var(--font-glacial);
    font-weight: 200;
    font-size: 0.75rem;
  }

  .search-text-container {
    display: flex;
    align-items: center;

    .search-text {
      margin-bottom: 0;
      font-family: var(--font-glacial);
      font-weight: 200;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.shades.grey[25]};
    }
  }
}

.notification-avatar-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;

  .notification-avatar {
    border: ${({ theme }) => `0.125rem solid ${theme.colors.purple}` };
  }

  .ant-badge {
    position: absolute;
    left: 0;

    .ant-badge-status-dot {
      --size: 0.625rem;

      border: 0.03125rem solid white;
      width: var(--size);
      height: var(--size);
    }
  }
}

.notification-icon-button-redirect {
  &.ant-btn-primary {
    background: ${({ theme }) => theme.colors.darkBlue};
    border-color: ${({ theme }) => theme.colors.darkBlue};
  }
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);
}

.filter-menu-title {
  color: ${({ theme }) => theme.colors.shades.grey[2]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.9375rem;
}

.MuiButtonBase-root.filter-menu-item {
  border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  height: 2.75rem;
  background-color: ${({ theme }) => `${theme.colors.white}80`};
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  font-size: 12px;
  vertical-align: sub;
}
`;

export default NotificationStyle;
