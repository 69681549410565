import React from "react";
// import { AvatarProps } from "antd/lib/avatar";
import styled from "styled-components";
import { StarIcon } from "../icons";
import Rating from "@material-ui/lab/Rating";
import { SvgIcon } from "@material-ui/core";

// export type IProfileInfo = AvatarProps;

export const CustomMuiStarIcon = styled(({ ...rest }) => (
  <SvgIcon>
    <StarIcon {...rest} />
  </SvgIcon>
))``;

export const StarRating = styled(
  ({
    defaultRating,
    ratingPrecision,
    ratingValue,
    maxRating,
    readOnly,
    className,
    ...rest
  }) => (
    <div className={className}>
      <Rating
        {...rest}
        name="star-rating"
        defaultValue={defaultRating || 4.5}
        value={ratingValue || 0}
        precision={ratingPrecision || 0.5}
        max={maxRating || 5}
        emptyIcon={<CustomMuiStarIcon mode={0} />}
        icon={<CustomMuiStarIcon mode={1} />}
        readOnly={readOnly || true}
        className="star-rating-container"
      />
    </div>
  )
)`
.star-rating-container {
  margin: 0.7rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.7rem;
}
`;
