import * as React from "react";

function SvgHandShake(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244.201"
      height="154.253"
      viewBox="0 0 244.201 154.253"
    >
      <defs>
        <filter
          id="Path_484"
          x="106.881"
          y="34.625"
          width="137.321"
          height="119.129"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Subtraction_1"
          x="0"
          y="0"
          width="126.132"
          height="154.253"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Ellipse_174"
          x="186.543"
          y="50.939"
          width="33.227"
          height="33.227"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-3" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Ellipse_175"
          x="176.953"
          y="77.68"
          width="28.767"
          height="28.767"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-4" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Ellipse_176"
          x="23.524"
          y="14.831"
          width="35.839"
          height="35.839"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-5" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_515"
          x="44.034"
          y="134.702"
          width="160.423"
          height="19.524"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-6" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_1942"
        data-name="Group 1942"
        transform="translate(9.502 6.5)"
      >
        <g id="Group_1482" data-name="Group 1482" transform="translate(0 0)">
          <path
            id="Subtraction_4"
            data-name="Subtraction 4"
            d="M130.806,77.832H67.627c-.078-.162-.153-.321-.221-.472H24.1a50.34,50.34,0,0,1-3.352-3.822A119.187,119.187,0,0,1,7.64,52.526,78.922,78.922,0,0,1,.782,32.943C-.553,25.871-.177,19.824,1.9,14.969A19.684,19.684,0,0,1,7.2,7.729a26.834,26.834,0,0,1,9.413-5.043A51.793,51.793,0,0,1,32.3,0,30.473,30.473,0,0,1,45.265,2.672a24.789,24.789,0,0,1,5.864,3.945c1.764,1.55,3.409,3.3,5,5,3.471,3.7,7.059,7.522,12.151,9.593,6.592,2.685,14.353,3.083,21.859,3.468h.007c5.644.29,11.481.59,16.763,1.862a31.831,31.831,0,0,1,7.527,2.765,24.236,24.236,0,0,1,6.5,4.966,26.425,26.425,0,0,1,4.61,7.241,61.564,61.564,0,0,1,4.319,15.937,111.652,111.652,0,0,1,1.166,15.518,44.643,44.643,0,0,1-.228,4.865Z"
            transform="translate(40.863 57.178)"
            fill="#002bff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
            opacity="0.45"
          />
          <g transform="matrix(1, 0, 0, 1, -9.5, -6.5)" filter="url(#Path_484)">
            <path
              id="Path_484-2"
              data-name="Path 484"
              d="M324.494,1761.389c-6.509,7.742-17.483,9-23.869,17.421-6.835,9.013-8.767,22.093-13.586,32.736H214.549a22.1,22.1,0,0,1-1.885-9.646c.326-17.294,16.373-24.937,22.452-39.646,3.562-8.616,3.765-18.461,8.191-26.67,4.044-7.5,11.285-12.867,19.1-16.25a119.365,119.365,0,0,1,23.694-7.208c20.926-4.053,44.668-2.371,45.806,21.588C332.359,1743.259,330.843,1753.826,324.494,1761.389Z"
              transform="translate(-96.78 -1669.79)"
              fill="#9f8cff"
              opacity="0.45"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, -9.5, -6.5)"
            filter="url(#Subtraction_1)"
          >
            <path
              id="Subtraction_1-2"
              data-name="Subtraction 1"
              d="M93.029,135.254H62.154a32.97,32.97,0,0,1-13.746-11.613,25.912,25.912,0,0,1-2.939-6.634c-.692-2.311-1.179-4.743-1.649-7.095-1.024-5.121-2.082-10.417-5.337-14.983-4.215-5.916-10.624-10.454-16.823-14.842C17,76.786,12.179,73.372,8.34,69.4a32.71,32.71,0,0,1-4.971-6.491A25.474,25.474,0,0,1,.419,55.038a28.169,28.169,0,0,1-.142-8.855C1.791,34.071,9.014,20.737,12.049,15.566a36.975,36.975,0,0,1,9.536-11.11A21.066,21.066,0,0,1,34.34,0a22.52,22.52,0,0,1,6.987,1.162A25.845,25.845,0,0,1,47.9,4.373a29.969,29.969,0,0,1,5.191,4.513A77.491,77.491,0,0,1,61.22,20.157c3.337,5.258,6.787,10.695,11.742,14.828A73.463,73.463,0,0,0,83.4,41.842C87.88,44.435,92.515,47.115,96.2,50.9a33.726,33.726,0,0,1,6.961,10.876,48.75,48.75,0,0,1,3.2,12.773,127.356,127.356,0,0,1,.185,25.549,85.254,85.254,0,0,1-4.082,20.051c-2.346,6.692-5.522,11.773-9.438,15.1Z"
              transform="translate(9.5 6.5)"
              fill="#2a00ff"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
              opacity="0.45"
            />
          </g>
          <path
            id="Path_486"
            data-name="Path 486"
            d="M218.341,1801.064c11.622-3.017,15.97-10.38,15.97-10.38l1.6-4.969h-4.614s-3.371,7.719-13.841,12.953c0,0-2.484-1.331-4.081.71s0,3.194,0,3.194a3.219,3.219,0,0,0,3.9-.621"
            transform="translate(-106.282 -1717.789)"
            fill="#f7a58d"
          />
          <path
            id="Path_487"
            data-name="Path 487"
            d="M260.838,1743.3s-3.066-1.894.143-3.9c0,0,2.506-1.078,4.132,0,0,0,1.085-1.289,1.69-.507s3.815,10.074-2.2,10.376"
            transform="translate(-131.003 -1693.845)"
            fill="#47235f"
          />
          <path
            id="Path_488"
            data-name="Path 488"
            d="M180.391,1785.987h2.71s.218,21.637.024,22.879-.777,0-.777,0S180.185,1793.329,180.391,1785.987Z"
            transform="translate(-89.247 -1717.792)"
            fill="#f7a58d"
          />
          <path
            id="Path_489"
            data-name="Path 489"
            d="M151.955,1911.256l-.313,2.814,2.814-.156.409-1.886Z"
            transform="translate(-74.086 -1782.259)"
            fill="#e9e9ea"
          />
          <path
            id="Path_490"
            data-name="Path 490"
            d="M176.377,1911.608l.274,1.719h2.032l.234-2.071Z"
            transform="translate(-87.137 -1782.259)"
            fill="#e9e9ea"
          />
          <path
            id="Path_491"
            data-name="Path 491"
            d="M168.113,1740.047s-2.946-.268-3.415-.67-3.683-.2-3.548.67a1.574,1.574,0,0,1-1.473,1.673c-.8.067.691,7.232.691,7.232l.783.6,4.852-3.011a1.829,1.829,0,0,0,.865-1.474C166.935,1743.548,167.188,1740.679,168.113,1740.047Z"
            transform="translate(-78.2 -1694.121)"
            fill="#e84153"
          />
          <path
            id="Path_492"
            data-name="Path 492"
            d="M167.866,1777.839a4.667,4.667,0,0,1-2.3-.019c.085,4.247.316,7.506.581,9.943a23,23,0,0,1-7.317-8.314l-3.916,2.192a23.807,23.807,0,0,0,12.069,11.441c.028.113.052.217.076.312a1.677,1.677,0,0,1-.109,1.134,1.8,1.8,0,0,1-1.625,1.025,2.215,2.215,0,0,1-.25-.019c-3.264-.453-11.483-1.587-11.909-1.578-.534.01-.51-.746-.51-.746l-.888-20.076a2.807,2.807,0,0,1,.652-1.9,13.7,13.7,0,0,1,4.629-2.47,9.423,9.423,0,0,1,2.82-.435,1.263,1.263,0,0,1,.194.014c.85.118,7.289,1.181,7.8,6.774C167.866,1775.118,168.414,1777.447,167.866,1777.839Z"
            transform="translate(-74.151 -1708.714)"
            fill="#3e9363"
          />
          <path
            id="Path_493"
            data-name="Path 493"
            d="M162.872,1764.973a4.259,4.259,0,0,0,2.911,2.245,2.9,2.9,0,0,0,3.1-1.807l-1.2-.438v-2.289l-3.822-1.54v3.393Z"
            transform="translate(-80.011 -1705.419)"
            fill="#dc937e"
          />
          <path
            id="Path_494"
            data-name="Path 494"
            d="M162.654,1746.138s3.616.871,4.687-.87c0,0,0,3.95.536,4.486s1.607,3.281-.6,4.553-5.089-1.54-5.089-1.54-1.138-2.683-.67-2.814.536-.2.938.4S161.771,1745.988,162.654,1746.138Z"
            transform="translate(-79.236 -1697.249)"
            fill="#f7a58d"
          />
          <path
            id="Path_495"
            data-name="Path 495"
            d="M169.589,1805.5a24.85,24.85,0,0,1-12.884-11.441l4.18-2.192s2.032,4.365,7.811,8.314A43.447,43.447,0,0,0,169.589,1805.5Z"
            transform="translate(-76.757 -1721.008)"
            fill="#455f51"
          />
          <path
            id="Path_496"
            data-name="Path 496"
            d="M280.791,1911.864l.313,2.813-2.814-.156-.409-1.886Z"
            transform="translate(-140.693 -1782.58)"
            fill="#e9e9ea"
          />
          <path
            id="Path_497"
            data-name="Path 497"
            d="M257.131,1912.215l-.274,1.72h-2.032l-.234-2.071Z"
            transform="translate(-128.404 -1782.58)"
            fill="#e9e9ea"
          />
          <path
            id="Path_498"
            data-name="Path 498"
            d="M264.46,1823.249s-12.815.634-13.956,0c0,0-3.933,38.824,1.269,42.631h2.068a1.28,1.28,0,0,0,1.28-1.318c-.168-5.557-.679-27.078,1.217-33.583a.266.266,0,0,1,.522.049c.448,5.775,2.573,30.926,5.826,34.853l2.309-.49a1.672,1.672,0,0,0,1.312-1.862C265.5,1857.651,262.831,1836.284,264.46,1823.249Z"
            transform="translate(-125.496 -1735.825)"
            fill="#44245f"
          />
          <path
            id="Path_499"
            data-name="Path 499"
            d="M252.555,1776.459c-.1,10.893-1.123,15.962-1.523,17.539a1.7,1.7,0,0,0,.11,1.139h0c.329.7,1.092.64,1.875.531,3.265-.453,11.484-1.114,11.911-1.107.532.01.51-.743.51-.743l.884-20.077a2.789,2.789,0,0,0-.652-1.9,13.635,13.635,0,0,0-4.627-2.471,9.46,9.46,0,0,0-2.82-.434,1.58,1.58,0,0,0-.192.016c-.836.113-7.29,1.172-7.806,6.773,0,0-.545,2.329,0,2.722s3.217-.2,3.217-.2V1775.7"
            transform="translate(-125.972 -1709.033)"
            fill="#ffb93e"
          />
          <path
            id="Path_500"
            data-name="Path 500"
            d="M276.426,1791.662s3.132.38,4.746-.57c0,0,.284,17.084-2.373,20.975,0,0,.958,3.964-1.573,5.211s-1.937-3.068-1.937-3.068.135-.288-.882-.307-.23-.576-.23-.576a3.575,3.575,0,0,1,1.994-.863S278.06,1804.273,276.426,1791.662Z"
            transform="translate(-138.585 -1720.319)"
            fill="#f7a58d"
          />
          <path
            id="Path_501"
            data-name="Path 501"
            d="M273.887,1772.571a42.54,42.54,0,0,0,4.121,1.179c1.607.321,3.155,6.572,3.164,7.57s-4.671,1.162-4.671,1.162l-2.614-1.984Z"
            transform="translate(-138.585 -1711.209)"
            fill="#ffb93e"
          />
          <path
            id="Path_502"
            data-name="Path 502"
            d="M165.312,1865.11h-2.064a1.279,1.279,0,0,1-1.28-1.313v0c.165-5.56.68-27.081-1.219-33.586a.265.265,0,0,0-.52.052c-.449,5.772-2.574,30.927-5.824,34.852l-2.31-.491a1.67,1.67,0,0,1-1.313-1.861c.8-5.876,3.458-27.388,1.828-40.426,0,0,12.83.779,13.973.146C166.583,1822.478,170.518,1861.3,165.312,1865.11Z"
            transform="translate(-73.624 -1735.341)"
            fill="#584897"
          />
          <path
            id="Path_503"
            data-name="Path 503"
            d="M267.1,1765.024v-3.449a1.186,1.186,0,0,0-1.9-.949l-1.45,1.088a1.187,1.187,0,0,0-.474.949v2.621a1.187,1.187,0,0,0,1.147,1.187,3.781,3.781,0,0,0,2.205-.5A1.139,1.139,0,0,0,267.1,1765.024Z"
            transform="translate(-132.985 -1705.031)"
            fill="#dc937e"
          />
          <path
            id="Path_504"
            data-name="Path 504"
            d="M265.638,1746.746s-3.616.87-4.687-.87c0,0,0,3.95-.536,4.486s-1.607,3.281.6,4.553,5.089-1.54,5.089-1.54,1.138-2.683.669-2.814-.536-.2-.937.4S266.522,1746.6,265.638,1746.746Z"
            transform="translate(-131.09 -1697.56)"
            fill="#f7a58d"
          />
          <path
            id="Path_505"
            data-name="Path 505"
            d="M157.937,1793.256l3.625-2.641s5.386,9.166,19.574,11.03c0,0,3.166-1.968,5.26,0s-2.36,4.35-2.36,4.35a3.743,3.743,0,0,1-3.262-1.916A24.458,24.458,0,0,1,157.937,1793.256Z"
            transform="translate(-77.407 -1720.33)"
            fill="#f7a58d"
          />
          <path
            id="Path_506"
            data-name="Path 506"
            d="M159.457,1822.334s11.586,3.891,12.111,6.229-.4-6.084-.4-6.084Z"
            transform="translate(-78.209 -1736.738)"
            fill="#443c7a"
            opacity="0.3"
          />
          <path
            id="Path_507"
            data-name="Path 507"
            d="M173.066,1869.568a8.67,8.67,0,0,1-.751.521c.222-5.626.916-27.405-1.642-33.988a.361.361,0,0,0-.394-.191c.044-.736.07-1.147.07-1.147s1.686-1.152,1.826.052A116.331,116.331,0,0,1,173.066,1869.568Z"
            transform="translate(-83.919 -1741.638)"
            fill="#443c7a"
          />
          <path
            id="Path_508"
            data-name="Path 508"
            d="M258.869,1871.063a8.717,8.717,0,0,0,.751.521c-.223-5.626-.916-27.405,1.642-33.988a.36.36,0,0,1,.394-.191c-.044-.737-.07-1.147-.07-1.147s-1.686-1.152-1.826.052A116.33,116.33,0,0,0,258.869,1871.063Z"
            transform="translate(-130.191 -1742.427)"
            fill="#341d49"
          />
          <g
            transform="matrix(1, 0, 0, 1, -9.5, -6.5)"
            filter="url(#Ellipse_174)"
          >
            <ellipse
              id="Ellipse_174-2"
              data-name="Ellipse 174"
              cx="7.613"
              cy="7.613"
              rx="7.613"
              ry="7.613"
              transform="translate(195.54 56.94)"
              fill="#fff"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, -9.5, -6.5)"
            filter="url(#Ellipse_175)"
          >
            <ellipse
              id="Ellipse_175-2"
              data-name="Ellipse 175"
              cx="5.384"
              cy="5.384"
              rx="5.384"
              ry="5.384"
              transform="translate(185.95 83.68)"
              fill="#fff"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, -9.5, -6.5)"
            filter="url(#Ellipse_176)"
          >
            <ellipse
              id="Ellipse_176-2"
              data-name="Ellipse 176"
              cx="8.92"
              cy="8.92"
              rx="8.92"
              ry="8.92"
              transform="translate(32.52 20.83)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -9.5, -6.5)" filter="url(#Path_515)">
            <path
              id="Path_515-2"
              data-name="Path 515"
              d="M71.211,0c39.329,0,71.211.341,71.211.762s-31.882.762-71.211.762S0,1.183,0,.762,31.882,0,71.211,0Z"
              transform="translate(53.03 140.7)"
              fill="#2d4090"
            />
          </g>
          <path
            id="Path_511"
            data-name="Path 511"
            d="M149.937,1918.3h4.132a.535.535,0,0,0,.528-.616c-.156-1.08-.7-3.2-2.593-3.232s-2.445,2.156-2.6,3.245A.533.533,0,0,0,149.937,1918.3Z"
            transform="translate(-72.904 -1783.944)"
            fill="#382255"
          />
          <path
            id="Path_512"
            data-name="Path 512"
            d="M175.266,1914.151h2.918a6.259,6.259,0,0,0,3.526,2.657,1.246,1.246,0,0,1,.892,1.47h-7.863a1.187,1.187,0,0,1,.118-1.588C175.225,1916.379,175.266,1914.151,175.266,1914.151Z"
            transform="translate(-86.133 -1783.787)"
            fill="#382255"
          />
          <path
            id="Path_513"
            data-name="Path 513"
            d="M280.6,1918.9h-4.132a.536.536,0,0,1-.528-.616c.156-1.08.7-3.2,2.593-3.231s2.445,2.156,2.6,3.245A.533.533,0,0,1,280.6,1918.9Z"
            transform="translate(-139.665 -1784.264)"
            fill="#382255"
          />
          <path
            id="Path_514"
            data-name="Path 514"
            d="M251.983,1914.759h-2.918a6.256,6.256,0,0,1-3.526,2.657,1.247,1.247,0,0,0-.891,1.471h7.863a1.187,1.187,0,0,0-.118-1.588C252.024,1916.986,251.983,1914.759,251.983,1914.759Z"
            transform="translate(-123.149 -1784.108)"
            fill="#382255"
          />
          <path
            id="Path_516"
            data-name="Path 516"
            d="M162.291,1738.962V1734.3s-.888,4.886-2,4.664S162.291,1738.962,162.291,1738.962Z"
            transform="translate(-35.944 -1669.63)"
            fill="#cfa253"
          />
          <path
            id="Path_517"
            data-name="Path 517"
            d="M136.724,1760.332c-1.051,1.314,1.577-.745,1.577-2.585s-.832-1.665-.832-1.665S137.775,1759.017,136.724,1760.332Z"
            transform="translate(-29.27 -1675.285)"
            fill="#dc937e"
          />
        </g>
        <path
          id="Path_518"
          data-name="Path 518"
          d="M1074.539,280.656c-.867-6.136,0-4.442,0-4.442v1.467s-2.243,17.172-3.338,17.347,3.767-.263,3.767-.263A87.306,87.306,0,0,0,1074.539,280.656Z"
          transform="translate(-936.653 -209.086)"
          fill="#cfa253"
        />
        <path
          id="Path_519"
          data-name="Path 519"
          d="M1010.866,277.971V274.95a32.134,32.134,0,0,0,1.762,3.174C1012.876,278.289,1010.866,277.971,1010.866,277.971Z"
          transform="translate(-919.619 -209.106)"
          fill="#455f51"
        />
      </g>
    </svg>
  );
}
export default SvgHandShake;
