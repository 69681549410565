import React from "react";
import { Header } from "components";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";
import SiderBar from "components/sider";

const MainLayout = styled(({ children, ...rest }) => (
  <Layout {...rest}>
    <Header />
    <Layout id="main-layout">
      <SiderBar />
      <Content className="main-content">{children}</Content>
    </Layout>
  </Layout>
))`
  min-height: 100vh;
  position: relative;

  .main-content {
    padding-left: 95px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 1rem;
    background: ${({ theme }) => theme.colors.white};
    overflow-x: hidden;
  }
`;

export default MainLayout;
