import { createGlobalStyle } from "styled-components";

const MessageStyle = createGlobalStyle`
.main-content {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 75px !important;
}

.message-sender-name {
  font-size: 0.75rem;
  font-family: var(--font-glacial);
  font-weight: 600;
}

.message-body {
  font-size: 13px;
  font-family: var(--font-glacial);
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-input {
  padding: 5px;
  background-color: #fff;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.message-main-content {
  --border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[54]}`};

  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: row;

  .message-aside {
    border-right: var(--border);
    background-color: ${({ theme }) => theme.colors.white};
    width: 25rem;
    display: flex;
    flex-direction: column;

    .message-title {
      font-size: 1rem;
      font-family: var(--font-glacial);
      font-weight: 600;
      color: ${({ theme }) => theme.colors.shades.grey[27]};
      padding: 1.9rem 0.5rem;
      border-bottom: var(--border);
    }

    .message-filter {
      padding: 1rem 0.5rem;
      border-bottom: var(--border);
      display: flex;
      justify-content: flex-end;
    }

    .message-conversation {
      display: block;
      height: 100%;
      overflow-y: auto;
      width: 100%;
      margin: 1rem 0 0 0;

      .conversation-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem 1rem;
        cursor: pointer;

        &.selected {
          background-color: #FFF7E7;
          border-left: 5px solid #FFB93E !important;
          transition-timing-function: ease-in;
          transition: 0.25s;
        }

        .conversation-avatar-container {
          position: relative;

          .conversation-avatar {
            border: ${({ theme }) => `0.125rem solid ${theme.colors.purple}` };
          }

          .ant-badge {
            position: absolute;
            left: 0;

            .ant-badge-status-dot {
              --size: 0.625rem;

              border: 0.03125rem solid white;
              width: var(--size);
              height: var(--size);
            }
          }
        }

        .conversation-content-container {
          flex: 1;
          min-width: 0;

          label {
            font-family: var(--font-glacial);
            font-size: 0.9375rem;
            font-weight: 600;
            color: #515151;
          }

          p {
            font-family: var(--font-glacial);
            font-size: 0.9375rem;
            font-weight: 400;
            color: ${({ theme }) => `${theme.colors.shades.grey[10]}AB`};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          }
        }

        .conversation-secondary-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.5rem;

          .conversation-date {
            font-family: var(--font-glacial);
            font-size: 0.625rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.shades.grey[28]};
          }
          
          .ant-tag {
            border-radius: 0.4375rem;
            text-align: center;
            font-weight: 600;
          }
        }
      }

      .ant-divider-horizontal {
        margin: 0 !important;
      }
    }
  }
}

.message-sender {
  height: 64px;
  margin-top: 0px;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
}

.message-sender.selected {
  background-color: #FFF7E7;
  border-left: 5px solid #FFB93E !important;
  transition-timing-function: ease-in;
  transition: 0.25s;
}

.messages-wrapper{
  background-color: ${({ theme }) => theme.colors.shades.grey[70]};
}

.site-layout {
  .conversation-title {
    padding: 2rem 1rem;
    font-family: var(--font-glacial);
    font-size: 0.875rem;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.shades.grey[36]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .conversation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background-color: ${({ theme }) => theme.colors.shades.blue[2]};
    color: ${({ theme }) => theme.colors.white};
    padding: 1rem 2rem;

    .ant-avatar {
      border: ${({ theme }) => `0.0625rem solid ${theme.colors.white}`};
    }

    .conversation-sender-details-container {
      display: flex;
      flex-direction: column;

      label {
        font-family: var(--font-glacial);
        font-size: 1rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.white};
      }

      p {
        font-family: var(--font-glacial);
        font-size: 0.825rem;
        font-weight: 400;
      }
    }
  }

  .conversation-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    .conversation-date-container {
      display: flex;
      justify-content: center;

      p {
        font-family: var(--font-glacial);
        font-size: 0.75rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.shades.grey[34]};
      }
    }

    .conversation-content-container {
      --border-radius: 0.375rem;

      margin: 1rem;
      border-radius: var(--border-radius);

      .ant-card {
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);

        .ant-card-body {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .conversation-sender-details {
            font-size: 0.625rem;
            font-weight: 400;
            font-family: var(--font-glacial);
            font-style: italic;
            color: ${({ theme }) => theme.colors.shades.grey[36]};
            display: flex;
            flex-direction: row;
            gap: 0.3rem;
          }
        }
      }
    }

    .conversation-footer {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
    }
  }
}
`;

export default MessageStyle;
