import { createGlobalStyle } from "styled-components";

const PartnerStyle = createGlobalStyle`

.partner-main-container {
  .ant-layout {
    .main-content {
      background: none;
    }
  }

  .partner-card {
    box-shadow: var(--box-shadow);
    margin: 0.3rem 0 0 0;
  }

  .partner-document-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;
  }

  .partner-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[45]}`};
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;

    .partner-item-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .partner-item-info-container {
      border-left: 1px solid grey;
      padding: 0 0 0 0.5rem;
      margin: 0 0 0 0.5rem;

      label {
        font-family: var(--font-glacial);
        font-size: 1rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.grey[24]};
      }

      p {
        font-family: var(--font-glacial);
        font-size: 0.8125rem;
        font-weight: 200;
        color: ${({ theme }) => theme.colors.grey[44]};
      }
    }

    &:hover,
    &:focus,
    &:active {
      border: ${({ theme }) => `2px solid ${theme.colors.darkBlue}`};
    }
  }

  .partner-item-selected {
    border: ${({ theme }) => `2px solid ${theme.colors.darkBlue}`};
  }

  .partner-administrator-container {
    margin: 2rem 0 0 0;
  }

  .partner-admin-item-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[45]}`};
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: ${({ theme }) =>
			`0rem 0 0.1875rem ${theme.colors.shadow}`};
    margin: 1rem 0 0 0;

    .ant-avatar {
      border: ${({ theme }) => `1px solid ${theme.colors.shades.blue[4]}`};
    }

    label {
      font-family: var(--font-glacial);
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.grey[45]};
    }

    p {
      font-family: var(--font-glacial);
      font-size: 0.8125rem;
      font-weight: 200;
      color: ${({ theme }) => theme.colors.grey[44]};
    }

    &:hover,
    &:focus,
    &:active {
      border: ${({ theme }) => `2px solid ${theme.colors.blue}`};
    }
  }

  .partner-admin-item-selected {
    border: ${({ theme }) => `2px solid ${theme.colors.blue}`};
  }

  .partner-list-container {
    max-height: 60vh;
    overflow-y: auto;
  }

  .partner-color-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .tag-content {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
      }
    }
  }

  .partner-api-key-container {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      .partner-api-key-content {
        label {
          font-family: var(--font-glacial);
          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.darkBlue};
        }

        p {
          padding: 0.5rem;
          border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[45]}`};
        }
      }

      button {
        align-self: center;
      }
    }
  }
}

.ant-form-item-explain {
  min-height: 0;
}

.ant-form-item-label {
  label {
    font-family: var(--font-glacial);
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }
}

.partner-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0 0 0;
  gap: 1rem;
  align-items: center;
}

.partner-modal {
  .ant-modal-content {
    border-radius: 1rem;

    .ant-modal-header {
      border-radius: 1rem 1rem 0 0;

      .ant-modal-title {
        text-align: center;

        h2 {
          margin: 0;
        }
      }
    }

    .partner-document-upload-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;
    }

    .partner-document-container {
      border: ${({ theme }) => `1px dashed ${theme.colors.darkBlue}`};
      padding: 2.5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.partner-icon-button-delete {
  &.ant-btn-primary {
    background: ${({ theme }) => theme.colors.shades.red[8]};
    border-color: ${({ theme }) => theme.colors.shades.red[8]};
  }
}

.partner-delete-dialog-container {
  .MuiPaper-rounded {
    border-radius: 1rem;
    padding: 1rem;
  }

  .MuiDialog-paperScrollPaper {
    align-items: center;
  }

  .MuiDialogTitle-root {
    text-align: center;

    h2 {
      font-weight: 600;
      font-family: var(--font-glacial);
      font-size: 1.75rem;
    }
  }

  .MuiDialogContent-root {
    margin: 0.5rem 0 1rem 0;
    
    p {
      font-size: 1.75rem;
      font-family: var(--font-glacial);
      color: ${({ theme }) => theme.colors.darkBlue};
      text-align: center;
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
  }
}

.pro-validated-call-modal {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      --el-offset: 6.5rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .green-tick {
        position: absolute;
        margin: 0 auto;
        top: calc(-1 * var(--el-offset));
        left: 0;
        right: 0;
        max-width: 100%;
        z-index: 2;
      }

      .validated-call-title {
        position: absolute;
        top: var(--el-offset);
        font-weight: 400;
        font-family: var(--font-glacial);
        display: block;
        width: fit-content;
        margin: 0 auto;
        background: ${({ theme }) => theme.colors.white};
        padding: 1.5em 3em;
        border-radius: 4.5em;
        max-width: 100%;
        text-align: center;
        z-index: 1;

        h2 {
          text-transform: uppercase;
          font-size: 1.875rem;
          color: ${({ theme }) => theme.colors.shades.blue[5]};
        }

        p {
          font-size: 1.1875rem;
          color: ${({ theme }) => theme.colors.shades.blue[5]};
        }
      }
    }
  }
}

.pro-validated-call-modal {
  &.loading {
    .ant-modal-content {
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.ant-switch-checked {
  background-color: ${({ theme }) => theme.colors.green};
}

`;

export default PartnerStyle;
