import { createGlobalStyle } from "styled-components";

const AdminStyle = createGlobalStyle`
.section-title {
  font-size: 20pt;
  font-family: var(--font-glacial);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkBlue};
  padding: 10px;
}

.admin-user-detail-container {
  .admin-card {
    box-shadow: var(--box-shadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .admin-form-container {
      width: 100%;

      .text-label {
        font-size: 0.875rem;
        font-family: var(--font-glacial);
        font-weight: 600;
        color: ${({ theme }) => theme.colors.darkBlue};
        margin: 0.5rem;
      }
    }
  
    .admin-card-heading {
      /* padding: 1rem; */
    }

    .profile-picture-image-uploader {
      width: auto;

      div.ant-upload.ant-upload-select.ant-upload-select-picture-card {
        border-radius: 50%;
        margin: 0;
        width: 100%;
        height: 100%;
        border: none;

        span.ant-upload {
          img.avatar-img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            padding: 5px;
            background: ${({ theme }) => theme.colors.blue};
            box-shadow: var(--box-shadow);
          }
        }
      }
    }

    .upload-avatar-actions {
      margin: 1rem 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .avatar-file-input {
        display: none;
      }
    }
  }
}

.ant-switch-checked {
  background-color: ${({ theme }) => theme.colors.green};
}

/* .dates-availability {
  width: 46px;
  max-width: 100%;
  height: 22px;
  border-radius: 20px;
  backgroundColor: red; 
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12pt;
} */

.page-header-container {
  max-width: 100%;
  margin-bottom: 10px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-space-item {
    min-width: 0;

    &:first-child {
      min-width: 2.5rem;
    }
  }
}

.advert-card .personal-profile-heading {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
}

/* .advert-info-container {
  margin: 1.5rem 0 0 0;
} */

/* .advert-box-header {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 16pt;
} */

.ant-form-item-label > label {
  color: ${({ theme }) => theme.colors.darkBlue};
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-color: ${({ theme }) => theme.colors.darkBlue};
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${({ theme }) => theme.colors.darkBlue};
}`;

export default AdminStyle;
