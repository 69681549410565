import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ForgotPasswordStyle from "styles/passwordStyles";
import { Card, Col, Form, Row, message } from "antd";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import { Header, TextField, Logo, TextButton } from "components";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { resetPassword } from "services/admin";

const ResetPassword = () => {
  const { t } = useTranslation(["common", "password", "signin"]);
  const { id } = useParams();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onClickCancel = () => {
    history.goBack();
  };

  const handleClickShowPassword = () => {
    console.log('showPassword :>> ', showPassword);
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required(t`common:errors.requiredField`),
    confirmPassword: yup
                      .string()
                      .oneOf([yup.ref('password'), null], t`common:errors.passwordMatch`)
                      .required(t`common:errors.requiredField`)
  });

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const token = new URLSearchParams(window.location.search).get("token");

    if (token) {
      const payload = {
        token: token,
        password: values.password,
        confirmPassword: values.confirmPassword
      }
  
      resetPassword(payload).then((resp) => {
        console.log('resp :>> ', resp);
        message.success(t`password:resetPassword.success.passwordChanged`,5);
      }).catch((e) => {
        console.log('e?.response?.data?.error :>> ', e?.response?.data?.error);
      }).finally(() => setSubmitting(false));
    }
  };
  
  return (
    <>
    <Header />
    <ForgotPasswordStyle />
    <Layout id="main-layout">
      <Content className="main-content">
        <h1 className="page-description-title">
          {t("password:resetPassword.title")}
        </h1>
        <p className="page-description-subtitle">
          {t("password:resetPassword.subTitle")}
        </p>
        <Card bordered={false}>
          <Row gutter={[16, 16]} justify="center" className="forgot-password-container">
            <Logo style={{width: "15rem"}}/>
            <p className="content-paragraph">
              {t("password:resetPassword.form.passwordText")}
              <br />
              {t("password:resetPassword.form.passwordTextCritera")}
            </p>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleSubmit,
              dirty,
              isValid,
              setFieldTouched,
            }) => {
              const retrieveErrors = (field) =>
                (touched[field] && errors[field]) || "";

              const handleChangeField = (e) => {
                e?.target?.name &&
                  !touched[e.target.name] &&
                  setFieldTouched(e.target.name);
                handleChange(e);
              };

              return (
                <Form layout="vertical" onFinish={handleSubmit}>
                  <FormItem
                    help={retrieveErrors("password")}
                    validateStatus={retrieveErrors("password") && "error"}
                    className="text-label"
                  >
                    <TextField name="password"
                      placeholder={t("password:resetPassword.form.newPassword")}
                      value={values.password}
                      type="password"
                      onChange={handleChangeField}
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                  </FormItem>
                  <FormItem
                    help={retrieveErrors("confirmPassword")}
                    validateStatus={retrieveErrors("confirmPassword") && "error"}
                    className="text-label"
                  >
                    <TextField name="confirmPassword"
                      placeholder={t("password:resetPassword.form.newConfirmPassword")}
                      value={values.confirmPassword}
                      type="password"
                      onChange={handleChangeField}
                      handleClickShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                      showPassword={showConfirmPassword}/>
                  </FormItem>
                  <Row justify="center" gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} className="btns-container">
                      <Row justify="center">
                        <TextButton
                          onClick={onClickCancel}
                          rounded={true}
                          variant="salmon"
                          width="16rem"
                          type="outlined"
                        >
                          {t("common:buttons.cancel")}
                        </TextButton>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Row justify="center">
                        <TextButton
                          htmlType="submit"
                          rounded={true}
                          variant="salmon"
                          width="16rem"
                          disabled={!dirty || !isValid || isSubmitting}
                        >
                          {t`common:buttons.send`}
                        </TextButton>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          </Row>
        </Card>
      </Content>
    </Layout>
    </>
  );
};

export default ResetPassword;
