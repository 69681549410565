import { createGlobalStyle } from "styled-components";

const DashboardStyle = createGlobalStyle`

.dashboard-header {
  background: ${({ theme }) => theme.colors.shades.blue[1]};
  border-radius: 0.1875rem;
  box-shadow: var(--box-shadow);
  .ant-card-body {
    padding: 0;
    color: ${({ theme }) => theme.colors.violet};
    font-weight: 200;
  }
}

.dashboard-my-notifications {
  box-shadow: var(--box-shadow);

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;

    .dashboard-my-notifications-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 1rem 1rem;

      h3 {
        font-family: var(--font-glacial);
        font-size: 1.125rem;
        font-weight: 600;
      }

      .dashboard-notification-calendar-container {
        position: relative;
        background: ${({ theme }) => theme.colors.salmon};
        padding: 10px 5px;
        border-radius: 5px;

        .calendar-vertical-bar {
          --width: 9px;
          --height: 15px;
          border-radius: calc(var(--width) / 2);
          background: ${({ theme }) => theme.colors.salmon};
          width: var(--width);
          height: var(--height);
          position: absolute;
          top: calc(var(--height) / -2);
          border: ${({ theme }) => `2px solid ${theme.colors.white}`};

          &.vertical-bar-left {
            left: 5px;
          }

          &.vertical-bar-right {
            right: 5px;
          }
        }


        .dashboard-notification-calendar-date {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: ${({ theme }) => theme.colors.white};
          padding: 3px 12px;
          border-radius: 3px;

          label {
            font-family: var(--font-glacial);
            font-weight: 600;
            line-height: 1;
          }

          .calendar-date {
            font-size: 13px;
            color: ${({ theme }) => theme.colors.shades.grey[28]};
          }

          .calendar-month {
            font-size: 9px;
            color: ${({ theme }) => theme.colors.shades.grey[43]};
          }
        }
      }
    }

    .dashboard-notification-date-separator {
      background-color: ${({ theme }) => theme.colors.shades.grey[64]};
      padding: 0.5rem 1rem;
      font-family: var(--font-glacial);
      font-size: 0.8125rem;
      color: ${({ theme }) => theme.colors.shades.grey[36]};
      font-weight: 400;
    }

    .dashboard-notification-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding: 1rem 1rem 0 1rem;

      .dashboard-notification-avatar-container {
        position: relative;

        .dashboard-notification-avatar {
          border: ${({ theme }) => `0.125rem solid ${theme.colors.purple}` };
        }

        .ant-badge {
          position: absolute;
          left: 0;

          .ant-badge-status-dot {
            --size: 0.625rem;

            border: 0.03125rem solid white;
            width: var(--size);
            height: var(--size);
          }
        }
      }

      .dashboard-notification-content-container {
        flex: 1;
        min-width: 0;

        label {
          font-family: var(--font-glacial);
          font-size: 0.9375rem;
          font-weight: 600;
          color: #515151;
        }

        p {
          font-family: var(--font-glacial);
          font-size: 0.9375rem;
          font-weight: 400;
          color: ${({ theme }) => `${theme.colors.shades.grey[10]}AB`};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }
      }

      .ant-btn-primary.blue-icon-button {
        --color: ${({ theme }) => theme.colors.darkBlue};
        border-color: var(--color);
        background-color: var(--color);
      }

      .ant-btn-primary.pink-icon-button {
        --color: ${({ theme }) => theme.colors.shades.salmon[1]};
        border-color: var(--color);
        background-color: var(--color);
      }

      .ant-btn-primary.yellow-icon-button {
        --color: ${({ theme }) => theme.colors.yellow};
        border-color: var(--color);
        background-color: var(--color);
      }

      .ant-btn-icon-only {
        border-radius: 0.1875rem;
      }
    }

    .dashboard-notification-date {
      font-family: var(--font-glacial);
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.shades.grey[36]};
      text-align: right;
      display: block;
      padding: 0 0.5rem 0.5rem 0.5rem;
    }

    .ant-divider-horizontal {
      margin: 0;
    }

    .dashboard-notification-see-all {
      text-decoration: underline;
      font-family: var(--font-glacial);
      font-size: 0.8125rem;
      color: ${({ theme }) => theme.colors.shades.grey[27]};
      font-weight: 400;
      display: flex;
      justify-content: center;
      margin: 1rem 0 0 0;
    }
  }
}

.kpi-progress-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 30px;
}

.kpi-card {
  box-shadow: var(--box-shadow);
}

.percentage {
  font-family: var(--font-glacial);
  font-size: 15px;
  color: #17db45;
  text-align: right;
  font-weight: bold;
}

.dashboard-kpi-total-text {
  font-family: var(--font-glacial);
  font-size: 16pt;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
}

.dashboard-kpi-count-text {
  font-family: var(--font-glacial);
  font-size: 14pt;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
}

.link-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.link-style {
  font-family: var(--font-glacial);
  font-size: 14pt;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-decoration: underline;
}

.dashboard-stats{
  margin-top: 10px;
}

.stats-header {
  font-family: var(--font-glacial);
  font-size: 18pt;
  color: ${({ theme }) => theme.colors.shades.grey[19]};
  font-weight: 600;
}

h1 {
  &.yellow-text {
    font-family: "Glacial Indifference";
    font-size: 77px;
    color: #ffb93e;
    font-weight: 800;
    margin-bottom: 0;
    text-align: center;
  }
}

h3 {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: "Glacial Indifference";
  font-weight: bold;

  &.card-label {
    font-size: 20px;
  }

  &.blue-text {
    font-size: 25px;
    text-align: center;
  }
}

.display-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dashboard-greeting-text{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  h3{
    color: ${({ theme }) => theme.colors.purple};
    font-size: 18px;
    font-family: 'Gilroy';
  }
}
.dashboard-greeting{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .handshake{
    display: flex;
    align-items: flex-end;
    position: relative;
    bottom: -13px;
    width: auto;
    height: auto;
    overflow: hidden;
  }
}

`;

export default DashboardStyle;
