import * as React from "react";

function SvgTransferIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.87'
      height='24.086'
      viewBox='0 0 25.87 24.086'
    >
      <g
        id='_6002219_business_finance_payment_transfer_icon'
        data-name='6002219_business_finance_payment_transfer_icon'
        transform='translate(-7.02 -9)'
      >
        <path
          id='Path_1588'
          data-name='Path 1588'
          d='M31.228,34.533a1.231,1.231,0,0,1-.868-2.1l4.664-4.67-4.67-4.67A1.231,1.231,0,1,1,32.1,21.354l5.537,5.537a1.231,1.231,0,0,1,0,1.741L32.1,34.17A1.23,1.23,0,0,1,31.228,34.533Z'
          transform='translate(-8.874 -6.72)'
          fill='#b0b0b0'
        />
        <path
          id='Path_1589'
          data-name='Path 1589'
          d='M21.03,9A11.9,11.9,0,0,0,9.448,18.425h2.13a9.8,9.8,0,0,1,10.889-7.216A9.932,9.932,0,1,1,11.449,23.137H9.35a11.854,11.854,0,0,0,23.52-2.8A11.94,11.94,0,0,0,21.03,9Z'
          transform='translate(0 0)'
          fill='#b0b0b0'
        />
        <path
          id='Path_1590'
          data-name='Path 1590'
          d='M13.409,32.222H10.631a3.611,3.611,0,1,1,0-7.222h2.778a3.611,3.611,0,1,1,0,7.222Zm-2.778-5a1.389,1.389,0,1,0,0,2.778h2.778a1.389,1.389,0,1,0,0-2.778Z'
          transform='translate(0 -7.217)'
          fill='#b0b0b0'
        />
      </g>
    </svg>
  );
}

export default SvgTransferIcon;
