import * as React from "react";

function SvgGiftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.049'
      height='21.377'
      viewBox='0 0 24.049 21.377'
    >
      <g
        id='Carte_Cadeau'
        data-name='Carte Cadeau'
        transform='translate(-7050 3910)'
      >
        <g
          id='Rectangle_2360'
          data-name='Rectangle 2360'
          transform='translate(7050 -3903)'
          fill='#fff'
          stroke='#b0b0b0'
          strokeWidth='1'
        >
          <rect width='24.049' height='14.377' rx='3' stroke='none' />
          <rect
            x='0.5'
            y='0.5'
            width='23.049'
            height='13.377'
            rx='2.5'
            fill='none'
          />
        </g>
        <line
          id='Line_530'
          data-name='Line 530'
          x2='23.049'
          transform='translate(7050.5 -3893.522)'
          fill='none'
          stroke='#b0b0b0'
          strokeWidth='1.5'
        />
        <g
          id='Ellipse_580'
          data-name='Ellipse 580'
          transform='translate(7054.546 -3910)'
          fill='#fff'
          stroke='#b0b0b0'
          strokeWidth='1.5'
        >
          <circle cx='4' cy='4' r='4' stroke='none' />
          <circle cx='4' cy='4' r='3.25' fill='none' />
        </g>
        <g
          id='Ellipse_581'
          data-name='Ellipse 581'
          transform='translate(7061.503 -3910)'
          fill='#fff'
          stroke='#b0b0b0'
          strokeWidth='1.5'
        >
          <circle cx='4' cy='4' r='4' stroke='none' />
          <circle cx='4' cy='4' r='3.25' fill='none' />
        </g>
        <line
          id='Line_531'
          data-name='Line 531'
          x2='6'
          y2='6'
          transform='translate(7060.981 -3904.447)'
          fill='none'
          stroke='#b0b0b0'
          strokeWidth='1.5'
        />
        <line
          id='Line_532'
          data-name='Line 532'
          x1='6'
          y2='6'
          transform='translate(7057.068 -3904.447)'
          fill='none'
          stroke='#b0b0b0'
          strokeWidth='1.5'
        />
        <g
          id='Rectangle_2361'
          data-name='Rectangle 2361'
          transform='translate(7061.524 -3905.596)'
          fill='#fff'
          stroke='#b0b0b0'
          strokeWidth='1'
        >
          <rect width='1' height='2' stroke='none' />
          <rect x='0.5' y='0.5' height='1' fill='none' />
        </g>
      </g>
    </svg>
  );
}

export default SvgGiftIcon;
