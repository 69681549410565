import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ForgotPasswordStyle from "styles/passwordStyles";
import { Card, Col, Form, Row, message } from "antd";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import { Header, TextField, Logo, TextButton } from "components";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { forgetPassword } from "services/admin";

const ForgotPassword = () => {
  const { t } = useTranslation(["common", "password", "signin"]);
  const { id } = useParams();
  const history = useHistory();

  const onClickCancel = () => {
    history.goBack();
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required(t`common:errors.requiredField`).email(t`common:errors.emailIncorrect`),
  });

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      email: values.email
    }

    forgetPassword(payload).then((resp) => {
      message.success(t`password:form.successMessage`,5);
    }).catch((e) => {
      console.log('e?.response?.data?.error :>> ', e?.response?.data?.error);
      if(e?.response?.data?.error.message === "userNotFound") {
        message.error(t`password:errors.userNotFound`);
      }
      else if (e?.response?.data?.error?.message === "userNotFound") {
        message.error(t("forgotPassword:errors.emailNotFound"), 5);
      } else {
        message.error(t("forgotPassword:errors.generalError"), 5);
      }
    }).finally(() => setSubmitting(false));
  };
  
  return (
    <>
    <Header />
    <ForgotPasswordStyle />
    <Layout id="main-layout">
      <Content className="main-content">
        <h2>{t`password:title`}</h2>
        <Card bordered={false}>
          <Row gutter={[16, 16]} justify="center" className="forgot-password-container">
            <Logo style={{width: "15rem"}}/>
          <p className="content-paragraph">
            {t("password:form.enterEmail")}
            <br />
            {t("password:form.initialisePwd")}
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleSubmit,
              dirty,
              isValid,
              setFieldTouched,
            }) => {
              const retrieveErrors = (field) =>
                (touched[field] && errors[field]) || "";

              const handleChangeField = (e) => {
                e?.target?.name &&
                  !touched[e.target.name] &&
                  setFieldTouched(e.target.name);
                handleChange(e);
              };

              return (
                <Form layout="vertical" onFinish={handleSubmit}>
                  <FormItem
                    help={retrieveErrors("email")}
                    validateStatus={retrieveErrors("email") && "error"}
                    className="text-label"
                  >
                    <TextField name="email"
                      placeholder={t("signin:form.email")}
                      value={values.email}
                      onChange={handleChangeField}/>
                  </FormItem>
                  <Row justify="center" gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} className="btns-container">
                      <Row justify="center">
                        <TextButton
                          onClick={onClickCancel}
                          rounded={true}
                          variant="salmon"
                          width="16rem"
                          type="outlined"
                        >
                          {t("common:buttons.cancel")}
                        </TextButton>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Row justify="center">
                        <TextButton
                          htmlType="submit"
                          rounded={true}
                          variant="salmon"
                          width="16rem"
                          disabled={!dirty || !isValid || isSubmitting}
                        >
                          {t`common:buttons.send`}
                        </TextButton>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          </Row>
        </Card>
      </Content>
    </Layout>
    </>
  );
};

export default ForgotPassword;
