import { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { Row, Col, } from "antd";
import styled from "styled-components";
import { CarouselNextIcon, CarouselPreviousIcon } from "components/icons";

export const CustomCarousel = styled(({ children, xOffset = 64, ...rest }) => {
  const carouselContentRef = useRef(null);
  const [previousIsDisabled, setPreviousIsDisabled] = useState(true);
  const [nextIsDisabled, setNextIsDisabled] = useState(true);
  const scrollTo = (left) =>
    carouselContentRef?.current?.scrollTo({
      left,
      behavior: "smooth",
    });
  const previous = () => {
    if (carouselContentRef?.current) {
      const newScrolledXPosition =
        carouselContentRef.current.scrollLeft - xOffset;
      scrollTo(newScrolledXPosition);
    }
  };
  const next = () => {
    if (carouselContentRef?.current) {
      const newScrolledXPosition =
        carouselContentRef.current.scrollLeft + xOffset;
      scrollTo(newScrolledXPosition);
    }
  };

  const onScroll = () => {
    setPreviousIsDisabled(
      !carouselContentRef?.current?.scrollLeft ||
        carouselContentRef?.current?.scrollLeft <= 0
    );

    const scrollContainerWidth =
      carouselContentRef?.current?.scrollWidth -
      carouselContentRef?.current?.clientWidth;
    setNextIsDisabled(
      carouselContentRef?.current?.scrollLeft >= scrollContainerWidth
    );
  };

  useEffect(() => {
    onScroll();
  }, [carouselContentRef?.current]);

  return (
    <Row {...rest}>
      <Col className="main-carousel-container">
        <IconButton
          className="main-carousel-arrow main-carousel-arrow-left"
          aria-label="previous"
          onClick={previous}
          disabled={previousIsDisabled}
        >
          <CarouselPreviousIcon disabled={previousIsDisabled} />
        </IconButton>
        <Row
          ref={carouselContentRef}
          className="carousel-content-container"
          onScroll={onScroll}
        >
          {children}
        </Row>
        <IconButton
          className="main-carousel-arrow main-carousel-arrow-right"
          aria-label="next"
          onClick={next}
          disabled={nextIsDisabled}
        >
          <CarouselNextIcon disabled={nextIsDisabled} />
        </IconButton>
      </Col>
    </Row>
  );
})`
  .main-carousel-container {
    position: relative;
    max-width: 100%;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .carousel-content-container {
      flex: 1;
      padding: 0 1rem;
      overflow-x: scroll;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: nowrap;
    }

    .main-carousel-arrow {
    }

    .main-carousel-arrow-left {
    }

    .main-carousel-arrow-right {
    }
  }
`;
