import React, { Fragment, useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { Badge, Button, Card, Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";
import DashboardStyle from "styles/dashboardStyles";
import Avatar from "antd/lib/avatar/avatar";
import { CallMadeRounded } from "@material-ui/icons";
import { format, isToday, parse } from "date-fns";
import { useHistory } from "react-router-dom";
import { getDashboardNotifications } from "services/notification";
import { UserOutlined } from "@ant-design/icons";
import { fullImageUrl } from "utils";
import { DashboardKpis } from "components";
import { HandShakeIcon } from "components/icons";

const Dashboard = () => {
  const { t } = useTranslation(["common", "dashboard"]);
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getDashboardNotifications().then((resp) => {
      const notificationsList = resp.reduce((acc, item) => {
        const key = format(new Date(item.createdAt), "dd/MM/yyyy");

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      setNotifications(notificationsList);
    }).catch((e) => console.log(e));
  }, []);

  const NOTIFICATION_TYPE = {
    ACTIVITY: {
      color: "blue-icon-button",
      link: (id) => () => history.push(`/annonces/${id}`)
    },
    MESSAGE: {
      color: "pink-icon-button",
      link: () => () => history.push("/")
    },
    USER: {
      color: "yellow-icon-button",
      link: (id) => () => history.push(`/utilisateurs/${id}`)
    },
    RATING: {
      color: "blue-icon-button",
      link: (id) => () => history.push(`/evaluations/${id}`)
    },
    TRANSACTION: {
      color: "blue-icon-button",
      link: (id) => () => history.push(`/transaction`)
    }
  }

  const NOTIFICATION_DETAILS = {
    ACTIVITY_EXPIRED: {
      getDescription: (notification) =>
        t("dashboard:notifications.description.ACTIVITY_EXPIRED", {
          name: notification.objectDescription,
          pro: notification.activityCreatedBy
        }),
    },
    NEW_ACTIVITY_CREATED: {
      getDescription: (notification) =>
        t("dashboard:notifications.description.NEW_ACTIVITY_CREATED", {
          name: notification.objectDescription
        })
    },
    NEW_USER_PRO_CREATED: {
      getDescription: (notification) =>
        t("dashboard:notifications.description.NEW_USER_PRO_CREATED", {
          subscriptionDate: format(new Date(notification?.objectCreatedAt), "dd/MM/yyyy")
        })
    },
    NEW_RATING_CREATED: {
      getDescription: (notification) =>
        t("dashboard:notifications.description.NEW_RATING_CREATED", {
          name: notification.objectDescription
        })
    },
    TRANSACTION: {
      getDescription: "",
    },
    MESSAGE: {
      getDescription: "",
    }
  }
  const firstname = localStorage.getItem("userfirstname");

  return (
    <MainLayout>
      <DashboardStyle />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={16}>
          <Card className="dashboard-header">
            <div className="dashboard-greeting">
              <div className="dashboard-greeting-text">
                <h3>{t("dashboard:welcome.hello", { username: firstname })}</h3>
                <p>{t`dashboard:welcome.content`}</p>
              </div>
              <div className="handshake">
                <HandShakeIcon />
              </div>
            </div>
          </Card>
          <DashboardKpis />
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={8}>
          <Card className="dashboard-my-notifications">
            <section className="dashboard-my-notifications-header">
              <h3>{t`dashboard:notifications.title`}</h3>
              <div className="dashboard-notification-calendar-container">
                <div className="calendar-vertical-bar vertical-bar-left"></div>
                <div className="calendar-vertical-bar vertical-bar-right"></div>
                <div className="dashboard-notification-calendar-date">
                  <label className="calendar-date">{format(new Date(), "dd")}</label>
                  <label className="calendar-month">{format(new Date(), "MMM")}</label>
                </div>
              </div>
            </section>
            {Object.entries(notifications).map(([key, items]) =>
              <Fragment key={key}>
                <div className="dashboard-notification-date-separator">
                  {isToday(parse(key, 'dd/MM/yyyy', new Date())) ? t`dashboard:notifications.today` : key}
                </div>
                {items.map((notification) =>
                  <Fragment key={notification.id}>
                    <section className="dashboard-notification-item">
                      <div className="dashboard-notification-avatar-container">
                        <Avatar src={fullImageUrl(notification.imageUrls, 'sm')} size={60} className="dashboard-notification-avatar" icon={<UserOutlined />} />
                        <Badge color={notification.isOnlineOrActive ? `green` : `red`} />
                      </div>
                      <div className="dashboard-notification-content-container">
                        <label>{t(`dashboard:notifications.types.${notification.title}`, { date: notification.objectAdditionalData ? notification.objectAdditionalData : "" })}</label>
                        <p>{NOTIFICATION_DETAILS[notification.title].getDescription(notification)}</p>
                      </div>
                      <Button type="primary"
                        icon={<CallMadeRounded />}
                        className={NOTIFICATION_TYPE[notification.objectType].color}
                        onClick={NOTIFICATION_TYPE[notification.objectType].link(notification.objectId)}
                      />
                    </section>
                    <label className="dashboard-notification-date">{format(new Date(notification.createdAt), "H:mm")}</label>
                    <Divider />
                  </Fragment>
                )}
              </Fragment>
            )}
            <a className="dashboard-notification-see-all" href="/notifications">{t`dashboard:notifications.seeAll`}</a>
          </Card>
        </Col>
      </Row>

    </MainLayout>
  );
};

export default Dashboard;
