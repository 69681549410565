import MainLayout from "layouts/main"
import { Card } from "components";
import AdvertStyle from "styles/AdvertStyle";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  exportAdverts,
  fetchAdvertsByCategory,
  fetchAdvertsByLocation,
  getAdvertList,
  reindexActivityList,
  updateActivity,
} from "services/advert";
import { getFullDateWithTime } from "utils/validators";
import { Select, Switch, Table, Row, Col, Dropdown, Menu, Space, Button, Radio, message, Progress } from "antd";
import { getCategoryList } from "services/category";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { DownloadOutlined, DownOutlined, SyncOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { MenuItem } from "@material-ui/core";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const { Option } = Select;
const PROGRESS_BAR_COLORS = [
  "#282eff",
  "#707070",
  "#ffc764",
  "#f8b7a2",
  "#64a882",
  "#26a6b8",
  "#335c84",
  "#ff0268",
  "#d6532c",
  "#02dcff",
];

const AdvertList = () => {

  const { t } = useTranslation(["common", "advert"]);
  const { colors } = useTheme();
  let history = useHistory();
  const [allAdvert, setAllAdvert] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listCategories, setListCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reviewed, setReviewed] = useState("");
  const [popular, setPopular] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [filterChoice, setFilterChoice] = useState(null);
  const [enabledButton, setEnabledButton] = useState(false);
  const [totalAdvertsByLocation, setTotalAdvertsByLocation] = useState(0);
  const [advertsByLocation, setAdvertsByLocation] = useState([]);
  const [advertsByCategory, setAdvertsByCategory] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const columns = [
    {
      key: "Id",
      title: t`advert:table.headers.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      key: "Nom",
      title: t`advert:table.headers.title`,
      dataIndex: "activityName",
    },
    {
      key: "Vendeur",
      title: t`advert:table.headers.createdBy`,
      dataIndex: "creatorName",
      align: "center" as "center",
    },
    {
      key: "Prix",
      title: t`advert:table.headers.price`,
      dataIndex: "price",
      align: "right" as "right",
      render: (price) => (price ? `${price}€` : ""),
    },
    {
      key: "Date de creation",
      title: t`advert:table.headers.createdDate`,
      dataIndex: "activityCreatedDate",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Date de modification",
      title: t`advert:table.headers.updatedDate`,
      dataIndex: "activityLastUpdatedDate",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Relecture",
      title: t`advert:table.headers.review`,
      dataIndex: "isReviewed",
      align: "center" as "center",
      // sorter: (a, b) => a.isReviewed - b.isReviewed,
      sorter: true,
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`advert:table.checkboxStatus.yes`}
            unCheckedChildren={t`advert:table.checkboxStatus.no`}
            checked={record.isReviewed}
            key={`${record.id}${reviewed}` || record.id}
            onChange={(e) => onHandleActivityReviewed(e, record)}
            onClick={(e, event) => event.stopPropagation()}
          />
        );
      },
    },
    {
      key: "Statut",
      title: t`advert:table.headers.status`,
      dataIndex: "isPublished",
      align: "center" as "center",
      // sorter: (a, b) => a.isPublished - b.isPublished,
      sorter: true,
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`advert:table.checkboxStatus.el`}
            unCheckedChildren={t`advert:table.checkboxStatus.hl`}
            checked={record.isPublished}
            key={`${record.id}${selectedStatus}` || record.id}
            onChange={(e) => onHandleActivityStatus(e, record)}
            onClick={(e, event) => event.stopPropagation()}
          />
        );
      },
    },
    {
      key: "Popular",
      title: t`advert:table.headers.popularActivity`,
      dataIndex: "isPopular",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren="O"
            unCheckedChildren="N"
            checked={record.isPopular}
            key={`${record.id}${popular}` || record.id}
            onChange={(e) => onHandleActivityPopular(e, record)}
            onClick={(e, event) => event.stopPropagation()}
          />
        );
      },
    },
    {
      key: "Dates Available",
      title: t`advert:table.headers.availableDates`,
      dataIndex: "datesAvailable",
      align: "center" as "center",
      sorter: true,
      render: (checked, record) => {
        return (
          <div
            style={{
              width: "46px",
              maxWidth: "100%",
              height: "22px",
              borderRadius: "20px",
              backgroundColor: checked ? colors.green : colors.darkBlue,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "10pt",
            }}
          >
            {checked ? t`advert:table.availableDates.yes` : t`advert:table.availableDates.no`}
          </div>
        );
      },
    },
    {
      key: "Category",
      title: t`advert:table.headers.category`,
      dataIndex: "categoryId",
      align: "center" as "center",
      // sorter: (a, b) => a?.categoryName?.localeCompare(b?.categoryName),
      sorter: true,
      render: (text, record) => {
        return (
          <div 
          onClick={(e) => e.stopPropagation()}>
          <Select
            className="select-responsible"
            onChange={(value) => handleOnChange(value, record)}
            key={`${record.id}${selectedCategory}` || record.categoryId}
            value={record.categoryId}
          >
            {listCategories?.map((item) => (
              <Option
                style={{ textAlign: "center" }}
                key={item.id}
                value={item.id}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          </div>
        );
      },
    },
    {
      key: "Neo",
      title: t`advert:table.headers.responsibleNeo`,
      dataIndex: "responsibleNeo",
      align: "center" as "center",
    },
  ];

  const CATEGORY_FILTER_ITEMS = [
    {
      label: t`advert:categories.filters.none`,
      value: "",
    },
    {
      label: t`advert:categories.filters.categoryDesc`,
      value: "CATEGORY DESC",
    },
    {
      label: t`advert:categories.filters.categoryAsc`,
      value: "CATEGORY ASC",
    },
    {
      label: t`advert:categories.filters.percentageDesc`,
      value: "PERCENTAGE DESC",
    },
    {
      label: t`advert:categories.filters.percentageAsc`,
      value: "PERCENTAGE ASC",
    },
  ];

  const FILTER_CHOICE = [
    {
      label: t("advert:filters.new"),
      value: "NEW"
    },
    {
      label: t("advert:filters.old"),
      value: "OLD"
    },
  ];

  let params = {
    "offset": 0,
    "limit": limit,
  };

  const onHandleActivityStatus = (checked, record) => {

    let payload = null;
    if (checked)
      payload = {
        isPublished: true,
        publishedDate: new Date().toISOString(),
        status: "VALIDATED"
      };
    else
      payload = {
        isPublished: false,
      };

    updateActivity(record.id, payload);
    const idx = allAdvert.findIndex(x => x.id === record.id);
    const list = allAdvert.find(x => x.id === record.id);
    list.isPublished = checked;
    allAdvert[idx] = list;
    setSelectedStatus(`${record.id}${checked}`)
  }

  const onHandleActivityReviewed = (checked, record) => {
    updateActivity(record.id, { isReviewed: checked });
    const idx = allAdvert.findIndex(x => x.id === record.id);
    const list = allAdvert.find(x => x.id === record.id);
    list.isReviewed = checked;
    allAdvert[idx] = list;
    setReviewed(`${record.id}${checked}`)
  }

  const onHandleActivityPopular = (checked, record) => {
    updateActivity(record.id, { isPopular: checked });
    const idx = allAdvert.findIndex(x => x.id === record.id);
    const list = allAdvert.find(x => x.id === record.id);
    list.isPopular = checked;
    allAdvert[idx] = list;
    setPopular(`${record.id}${checked}`)
  }

  const handleOnChange = (value, record) => {
    if (record) {
      updateActivity(record.id, { categoryId: value });
      const idx = allAdvert.findIndex(x => x.id === record.id);
      record.categoryId = value;
      const list = listCategories.find(x => x.id === value);
      record.categoryName = list.name;
      allAdvert[idx] = record;
      setSelectedCategory(`${record.id}${value}`);
    }
  };

  const fetchAdsByLocationData = (params) => {
    fetchAdvertsByLocation(params).then((resp) => {
      const totalAds = +(resp.find(r => r.city === "ALL")?.count || 0);
      const adsByLocation = resp.filter(r => r.city !== "ALL");
      setTotalAdvertsByLocation(totalAds);
      setAdvertsByLocation(adsByLocation);
    });
  };

  const fetchAdsByCategoryData = (params) => {
    fetchAdvertsByCategory(params).then((resp) => {
      setAdvertsByCategory(resp);
    });
  };

  useEffect(() => {
    async function fetchAdverts() {
      getCategoryList().then((cat) => {
        setListCategories(cat);
      });
      await fetchData(params);
    }
    fetchAdverts();
    fetchAdsByLocationData({});
    fetchAdsByCategoryData({});
  }, []);

  const fetchData = async (params) => {
    const response = await getAdvertList(params);
    const adverts = response.activities;
    setAllAdvert(adverts);
    setTotalRecords(response.totalRecords);
  };

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    let filterParams = {
      "where": {
        "sorting": event.target.value
      }
    }
    fetchData(filterParams);
  }

  const handleDownload = async () => {
    const result = await exportAdverts();
    if (result.response) {
      const users = result.response;
      if (users) {
        exportToCSV(users, "export_annonces");
        message.success("Export reussi", 5);
      } else {
        message.warning("Pas de données a exporter", 5);
      }
    } else {
      message.error(
        "Une erreur est survenue. Veuillez réessayer plus tard.",
        5
      );
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }


  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("advert:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {FILTER_CHOICE.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const searchParams = {
      where: {
        search: searchTerm,
      },
    };

    fetchData(searchParams);
  };

  const handleReindexing = async () => {

    try {
      setEnabledButton(false);
      const result = await reindexActivityList();
      if (result) {
        fetchData(null);
        message.success(t`advert:messages.operationSucess`, 5);
      } else {
        message.error(t`advert:messages.error`, 5);
      }
    } catch (error) {
      message.error(t`advert:messages.error`, 5);
    } finally {
      setEnabledButton(true);
    }

  }

  const onChangeCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);

    const filterParams = event.target.value ? {
      "order": event.target.value
    } : {};

    fetchAdsByCategoryData(filterParams);
  }

  const onTableChange = (pagination, b, sort) => {
    if (sort && sort.field && sort.order) {
      sortColumn(sort.field, sort.order, pagination.current - 1);
    } else {
      setOffset(pagination.current - 1);
      let params = null;
  
      if (searchTerm && searchTerm.length > 0) {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            search: searchTerm,
            sorting: filterChoice
          },
        };
      } else {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            sorting: filterChoice
          },
        };  
      }
  
      fetchData(params);
    }
  };

  const sortColumn = (field, order, currentPage) => {
    setSortField(field);
    setSortOrder(order);

    order = order === "ascend" ? 'ASC' : 'DESC'; 
    let params = null;
    let sortOrder = null;

    if (field === "isPublished") {
      sortOrder = `PUBLISHED${order}`;
    }
    else if (field === "isReviewed") {
      sortOrder = `REVIEWED${order}`;
    }
    else if (field === "datesAvailable") {
      sortOrder = `DATESAVAIL${order}`;
    }
    else if (field === "categoryId") {
      sortOrder = `CATEGORY${order}`;
    }

    if (searchTerm && searchTerm.length > 0) {
      params = {
        offset: currentPage,
        limit: limit,
        where: {
          search: searchTerm,
          sorting: sortOrder
        },
      };
    } else {
      params = {
        offset: currentPage,
        limit: limit,
        where: {
          sorting: sortOrder
        },
      };  
    }
    fetchData(params);
  };


  const renderLocationProgressBar = (ad, index) => {
    let percent = +((ad.count / totalAdvertsByLocation).toFixed(2)) * 100;
    percent = +percent.toFixed(0);
    const config = {
      height: 16,
      percent,
      strokeColor: PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length],
    };

    return (
      <Col key={`loc-ad-${index}`} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <div className="advert-by-location-row-container">
          <div className="advert-by-location-container">
            <div className="advert-by-location-text-container">
              <p className="advert-by-location-city">{ad.city}</p>
              <p className="advert-by-location-count">{t("advert:locations.activities", { count: +ad.count })}</p>
            </div>
          </div>
          <Progress {...config} />
        </div>
      </Col>
    );
  };

  const renderCategoryProgressBar = (ad, index) => {
    const progressColor = PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length];

    const config = {
      height: 16,
      percent: +ad.percentage,
      strokeColor: progressColor,
    };

    return (
      <Col key={`cat-ad-${index}`} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <div className="advert-by-category-row-container">
          <div className="advert-by-category-circle" style={{ background: progressColor }}></div>
          <div className="advert-by-category-container">
            <div className="advert-by-category-text-container">
              <p className="advert-by-category-city">{ad.category}</p>
              <p className="advert-by-category-count">{t("advert:categories.activities", { count: +ad.activitiesCount })}</p>
            </div>
          </div>
          <Progress {...config} />
        </div>
      </Col>
    );
  };

  const renderAdvertsByLocation = () => (
    <Row gutter={[16, 16]} className="adverts-by-location-container">
      {advertsByLocation.map(renderLocationProgressBar)}
    </Row>
  );

  const renderAdvertsByCategory = () => (
    <Row gutter={[16, 16]} className="adverts-by-category-container">
      {advertsByCategory.map(renderCategoryProgressBar)}
    </Row>
  );

  const renderCategoryFilterMenu = () => (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("advert:categories.filters.title")}</p>
      <Radio.Group value={categoryFilter} onChange={onChangeCategoryFilter}>
        {CATEGORY_FILTER_ITEMS.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  return (
    <MainLayout>
      <AdvertStyle />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="top-card-container">
          <Card>
            <h2 className="page-title">{t`advert:locations.title`}</h2>
            <Row gutter={[16, 16]} className="advert-by-location-main-container">
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <div className="advert-by-location-total-container">
                  <p className="advert-by-location-total">{totalAdvertsByLocation}</p>
                  <p className="advert-by-location-total-adverts">{t("advert:locations.adverts", { count: totalAdvertsByLocation })}</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                {renderAdvertsByLocation()}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="top-card-container">
          <Card>
            <h2 className="page-title">{t`advert:categories.title`}</h2>
            <Dropdown overlay={renderCategoryFilterMenu()} placement="bottomLeft" arrow>
              <p className="filter-dropdown">
                {t("advert:categories.filters.title")}{"   "}<DownOutlined />
              </p>
            </Dropdown>
            {renderAdvertsByCategory()}
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <h2 className="page-title">{t`advert:title`}</h2>
            <Row justify="space-between" gutter={[16, 8]} style={{ marginBottom: "10px" }}>
              <Col>
                <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
                  <p className="filter-dropdown" style={{ cursor: "pointer" }}>
                    {t("advert:filters.title")}{"   "}<DownOutlined />
                  </p>
                </Dropdown>
              </Col>
              <Col className="search-text-container">
                <Space><p className="search-text">{t("advert:search")}:</p>
                  <TextField radius="0.8125rem" onPressEnter={handleSearch} />
                  <Button icon={<DownloadOutlined />} size="large" onClick={handleDownload} style={{ color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt" }}>{t("advert:export")}</Button>
                  <Button
                    icon={<SyncOutlined />}
                    size="large"
                    style={{ color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt" }}
                    onClick={handleReindexing}
                  // loading={!enabledButton}
                  >{t("advert:reIndex")}</Button>
                </Space>

              </Col>
            </Row>
            <Table
              rowKey={record => record.id}
              columns={columns}
              dataSource={allAdvert}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/annonces/${record.id || ""}`);
                  },
                };
              }}
              locale={{ emptyText: t`common:table.emptyText` }}
              onChange={onTableChange}
              pagination={{
                pageSize: 10,
                defaultPageSize: 10,
                total: totalRecords,
                showSizeChanger: false
              }}
            />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  )
}
export default AdvertList;
