import React, { useContext, useEffect, useState } from "react";

import { Logo } from "components";
import { Avatar, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { fullImageUrl } from "utils";
import { GlobalContext } from "contexts";

export const Header = styled(({ children, ...rest }) => {
  const {
    avatar,
  } = useContext(GlobalContext);

  return (
    <Row {...rest} justify="space-between" align="middle" data-testid="container">
      <Logo style={{ maxWidth: "13rem", flex: 1 }} />
      <Avatar src={fullImageUrl(avatar, "sm")} size={{ xs: 50, sm: 60, md: 60, lg: 60, xl: 60, xxl: 60 }} icon={<UserOutlined />} />
    </Row>
  );
})`
  box-shadow: var(--box-shadow);
  background: ${({ theme }) => theme.colors.white};
  padding: 0 1rem;
  height: var(--header-height);
  gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 12;
`;
