import React from "react";
import styled from "styled-components";
import { Avatar, Row, Space, Switch, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFullDateWithTime } from "utils/validators";
import { fullImageUrl } from "utils";

export const AdvertInfoBanner = styled(({ columns, data, advert, ...rest }) => {
  const { t } = useTranslation(["common", "advert"]);

  const defaultColumns = [
    {
      key: "Id",
      title: t`advert:table.headers.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      key: "Nom",
      title: t`advert:table.headers.title`,
      dataIndex: "activityName",
      // ellipsis: true,
    },
    {
      key: "Vendeur",
      title: t`advert:table.headers.createdBy`,
      dataIndex: "creatorName",
      align: "center" as "center",
    },
    {
      key: "Prix",
      title: t`advert:table.headers.price`,
      dataIndex: "price",
      align: "center" as "center",
      render: (price) => (price ? `${price}€` : ""),
    },
    {
      key: "Date de creation",
      title: t`advert:table.headers.createdDate`,
      dataIndex: "activityCreatedDate",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Date de modification",
      title: t`advert:table.headers.updatedDate`,
      dataIndex: "activityLastUpdatedDate",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Relecture",
      title: t`advert:table.headers.review`,
      dataIndex: "isReviewed",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`advert:table.checkboxStatus.yes`}
            unCheckedChildren={t`advert:table.checkboxStatus.no`}
            checked={record.isReviewed}
          />
        );
      },
    },
    {
      key: "Statut",
      title: t`advert:table.headers.status`,
      dataIndex: "isPublished",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`advert:table.checkboxStatus.el`}
            unCheckedChildren={t`advert:table.checkboxStatus.hl`}
            checked={record.isPublished}
          />
        );
      },
    },
    {
      key: "Popular",
      title: t`advert:table.headers.popularActivity`,
      dataIndex: "isPopular",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren="O"
            unCheckedChildren="N"
            checked={record.isPopular}
          />
        );
      },
    },
    {
      key: "Dates Available",
      title: t`advert:table.headers.availableDates`,
      dataIndex: "datesAvailable",
      align: "center" as "center",
      render: (checked) => {
        return (
          <div>
            {checked ? t`advert:table.availableDates.yes` : t`advert:table.availableDates.no`}
          </div>
        );
      },
    },
    {
      key: "Category",
      title: t`advert:table.headers.category`,
      dataIndex: "categoryName",
      align: "center" as "center",
    },
    {
      key: "Neo",
      title: t`advert:table.headers.responsibleNeo`,
      dataIndex: "responsibleNeo",
      align: "center" as "center",
    },
  ];

  return (
    <Row {...rest} align="middle">
      <Space className="user-avatar-container">
        <Avatar size={76} src={advert && advert[0] ? fullImageUrl(advert[0]?.imageUrls, 'sm') : null} className="user-avatar" icon={<UserOutlined />} />
      </Space>
      <Space className="table-container">
        <Table
          rowKey={record => record.id}
          columns={defaultColumns}
          dataSource={advert || []}
          pagination={false}
          locale={{ emptyText: t`common:table.emptyText` }}
        />
      </Space>
    </Row>
  );
})`
  background: ${({ theme }) => theme.colors.salmon};
  margin: 0 0 0 -5.91rem;
  padding: 1rem 1rem 1rem 5.4rem;
  width: 100vw;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  .user-avatar-container {
    min-width: 4.75rem;

    .user-avatar {
      border: ${({ theme }) => `0.15rem solid ${theme.colors.white}`};
    }
  }

  .table-container {
    min-width: 0;
    flex: 1;

    .ant-space-item {
      width: 100%;
    }

    * {
      background: ${({ theme }) => theme.colors.transparent};
    }

    tbody td {
      border: none;
    }

    th, td {
      white-space: nowrap;
    }

    tr > td {
      text-align: center;
    }
  }

  .ant-table-thead > tr > th, td.ant-table-cell {
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ theme }) => theme.colors.transparent};
  }

  .ant-switch-handle {
    position: absolute;
    top: 1.7px;
  }

  .ant-switch {
    border: 1px solid #fff;
    height: 24px;
  }

  .ant-table-content {
    overflow-y: hidden;
  }
`;
