import styled from "styled-components";
import { Divider, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { TextButton } from "components";
import { useHistory } from "react-router-dom";
import { BackIcon } from "components/icons";

export const DetailHeader = styled(({
  icon,
  title,
  goBack,
  submitIsDisabled,
  handleCancel,
  handleSave,
  variant = "darkBlue",
  isNewRecord,
  className,
  showFormButtons,
  ...rest
}) => {
    const { t, i18n } = useTranslation(["common", "parameters"]);
    const { colors } = useTheme();
    let history = useHistory();

    return (
      <div className={className}>
        <div className="first-row">
          <TextButton
            variant={variant}
            rounded
            height="2.5rem"
            width="2.5rem"
            onClick={() => history.push(goBack)}
          >
            <BackIcon />
          </TextButton>
          {showFormButtons &&
            <div className="form-btns-container">
              <TextButton
                variant={variant}
                width="12rem"
                onClick={handleCancel}
                rounded
              >
                {t`common:cancel`}
              </TextButton>
              <TextButton
                variant={variant}
                width="12rem"
                disabled={submitIsDisabled}
                onClick={handleSave}
                type="outlined"
                rounded
              >
                {t(`common:${isNewRecord ? "create" : "save"}`)}
              </TextButton>
            </div>
          }
        </div>
        <Row align="middle" className="second-row" style={{background: colors[variant]}}>
          <Space>
            {icon}
            <Divider type="vertical" className="vertical-divider" />
            <p className="title">{`${title} `}</p>
          </Space>
        </Row>
      </div>
    );
  }
)`
  .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 1rem 0;

    .form-btns-container {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;
      padding: 0 1rem;
    }
  }

  .second-row {
    margin: 0 0 1rem -5.4rem;
    padding: 1rem 1rem 1rem 5.4rem;
    width: 100vw;
    flex-wrap: nowrap;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    font-family: var(--font-glacial);
    font-size: 1.66rem;

    .vertical-divider {
      border-left: 1px solid white;
      height: 50px;
    }

    .title {
      font-weight: 800;
    }

    .ant-space {
      .ant-space-item {
        align-items: center;
        display: flex;
      }
    }
  }
`;
