import styled from "styled-components";
import { TextField as BaseTextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const TextField = styled(({type="", handleClickShowPassword = () => undefined, showPassword = false, ...rest}) => {

  return (
    type === "password" ? 
      <BaseTextField
        {...rest}
        type={showPassword ? "text" : type}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: 
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>,
        }}
      />
      : 
      <BaseTextField
      {...rest}
      type={type}
      InputLabelProps={{ shrink: true }}
    />
  );
})`
  width: 100%;
  margin: 0 auto;

  label {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  input {
    font-family: var(--font-glacial);
  }

  p.MuiFormHelperText-root {
    color: ${({ theme }) => `${theme.colors.shades.red[0]} !important`};
  }
`;