import { createGlobalStyle } from "styled-components";

const UserStyle = createGlobalStyle`
.select-responsible {
  width: 100%;
  max-width: 8.875rem;
  text-align: center;
}

.user-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
}

.search-text {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
}

.search-text-container {
  display: flex;
  align-items: center;
}

.page-header {
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem 3rem;
  box-shadow: var(--box-shadow);
}

.filter-dropdown {
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.75rem;
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);
}

.filter-menu-title {
  color: ${({ theme }) => theme.colors.shades.grey[2]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.9375rem;
}

.MuiButtonBase-root.filter-menu-item {
  border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  height: 2.75rem;
  background-color: ${({ theme }) => `${theme.colors.white}80`};
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  font-size: 12px;
  vertical-align: sub;
}

.page-header-container {
  max-width: 100%;

  .ant-space-item {
    min-width: 0;

    &:first-child {
      min-width: 2.5rem;
    }
  }
}

.user-info-container {
  margin: 1.5rem 0 0 0;
}

.user-info-space-col {
  width: 100%;
}

.user-card {
  box-shadow: var(--box-shadow);
  padding: 1rem 0;
  /* height: 100%; */

  .user-card-heading {
    padding: 1rem ;
  }
}

.personal-profile-icon {
  margin: 0 auto;
  display: block;
}

.professional-info-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item {
    width: 100%;

    .ant-form-item-label > label {
      font-family: var(--font-glacial);
      font-weight: 800;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.darkBlue};
    }

    .ant-form-item-control {
      .ant-form-item-explain {
        min-height: 0;
      }
    }
  }
}

.personal-profile-card {
  padding: 1rem 0 0.5rem 0;
}

.users-icon {
  margin-top: 1rem;
}

.evaluations-links-container {
  padding: 2rem 0.5rem 1rem 0.5rem;
}

.evaluations-link {
  font-family: var(--font-glacial);
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  text-decoration: underline;
  cursor: pointer;
}

.member {
  font-family: var(--font-glacial);
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.shades.grey[36]};
  text-align: center;
}

.rating-text {
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 3.375rem;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
  line-height: 1;
}

.rating-count {
  font-family: var(--font-glacial);
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
}

.user-avatar {
  margin: 0 auto;
  display: block;
}

.profile-picture-image-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-picture-image-uploader {
    width: var(--avatar-size);
    height: var(--avatar-size);

    div.ant-upload.ant-upload-select.ant-upload-select-picture-card {
      border-radius: 50%;
      margin: 0;
      width: 100%;
      height: 100%;
      border: none;

      span.ant-upload {
        border-radius: calc(var(--avatar-size) / 2);

        img.avatar-img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          padding: 5px;
          background: ${({ theme }) => theme.colors.blue};
          box-shadow: var(--box-shadow);
        }
      }
    }
  }
}

.avatar-btn-container {
  margin: 1rem 0 0 0;
  padding: 0 1rem;
}

.edit-photo-container {
  margin: 1rem 0 1rem 0;
}

.form-container {
  margin: 2rem 0;
}

.MuiPickersToolbar-toolbar {
  background-color: ${({ theme }) => theme.colors.darkBlue};
}

.MuiPickersDay-daySelected {
  background-color: ${({ theme }) => theme.colors.darkBlue};
}

.MuiButton-textPrimary {
  color: ${({ theme }) => theme.colors.darkBlue};
}

.form-textfield-container {
  padding: 0 1rem;

  label {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .MuiTextField-root {
    width: 100%;
  }

  input {
    font-family: var(--font-glacial);
  }

  .MuiSelect-select {
    font-family: var(--font-glacial);
  }
}

.user-card .personal-profile-heading {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
}

.avatar-btns-container {
  padding: 0 0.5rem;
}

.avatar-file-input {
  display: none;
}

.full-height {
  height: 100%;
}

.ReactCrop__image {
  margin: 0 auto;
}

.react-crop-btn-container {
  min-width: 16rem;
  padding: 1rem;
}

.user-card-table-container {
  padding: 0 1rem;
}

.user-latest-ads-activity-title {
  color: inherit;
  text-decoration: underline;
}

.user-latest-ads-online {
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  white-space: nowrap;

  &.user-latest-ads-online-true {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.green}
  }

  &.user-latest-ads-online-false {
    color: inherit;
    background: ${({ theme }) => theme.colors.shades.grey[60]}
  }
}

.users-others-form {
  width: 100%;

  .user-account-btns-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0 0 2rem 0;
    padding: 0 1rem;
  }

  .next-level-content-container {
    .next-level-btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 1rem 1rem 1rem;
    }

    & > .ant-card {
      margin: 0;
    }
  }

  .user-docs-container {
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    & > .user-doc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      background: transparent;
      border: none;
      padding: 0.5rem;
      transition: all ease 300ms;

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.colors.shades.grey[70]}
      }

      & > .user-doc-icon {
        font-size: 5rem;
        color: ${({ theme }) => theme.colors.shades.grey[35]};
      }

      & > .user-doc-created-at {
        font-family: var(--font-glacial);
        font-weight: 400;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.shades.grey[32]};
        text-align: center;
      }
    }

    & > .no-doc {
      font-family: var(--font-glacial);
      font-weight: 400;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.shades.grey[50]};
      text-align: center;
    }
  }

  .user-settings-form-fields-container {
    padding: 0 1rem 1rem 1rem;
  }
}
`;

export default UserStyle;
