import styled from "styled-components";
import { Avatar, Row, Space, Switch, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFullDateWithTime } from "utils/validators";
import { fullImageUrl } from "utils";

export const RatingInfoBanner = styled(({ columns, data, rating, ...rest }) => {
  const { t, i18n } = useTranslation(["common", "rating"]);

  const defaultColumns = [
    {
      key: "Commandes",
      title: t`rating:table.headers.order`,
      dataIndex: "id",
      render: (value, record) => createOrderRef(record),
      align: "left" as "left",
    },
    {
      key: "Client",
      title: t`rating:table.headers.client`,
      dataIndex: "buyer",
      align: "center" as "center",
    },
    {
      key: "Vendeur",
      title: t`rating:table.headers.seller`,
      dataIndex: "seller",
      align: "center" as "center",
    },
    {
      key: "Note",
      title: t`rating:table.headers.note`,
      dataIndex: "note",
      align: "center" as "center",
    },
    {
      key: "Visible",
      title: t`rating:table.headers.visible`,
      dataIndex: "isVisible",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`rating:table.checkboxStatus.yes`}
            unCheckedChildren={t`rating:table.checkboxStatus.no`}
            checked={record.isVisible}
          />
        );
      },
    },
    {
      key: "Role",
      title: t`rating:table.headers.role`,
      dataIndex: "seller",
      render: (value) => {return 'Vendeur'},
      align: "center" as "center",
    },
    {
      key: "Statut",
      title: t`rating:table.headers.status`,
      dataIndex: "status",
      render: (status: string) => {return t(`rating:status.${status}`) },
      align: "center" as "center",
    },
    {
      key: "Evalué",
      title: t`rating:table.headers.createdAt`,
      dataIndex: "createdAt",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Neo",
      title: t`rating:table.headers.responsibleNeo`,
      dataIndex: "adminNeo",
      align: "center" as "center",
    }
  ];

  const createOrderRef = (record) => {
    return (
      <div>
        <p>{record.bookingReference}</p>
        <small>{record.activityName} {t`rating:table.headers.with`} {record.seller}</small>
      </div>
    )
  }


  return (
    <Row {...rest} align="middle">
      <Space className="user-avatar-container">
        <Avatar size={76} src={rating && rating[0] ? fullImageUrl(rating[0]?.imageUrls, 'sm') : null} className="user-avatar" icon={<UserOutlined />} />
      </Space>
      <Space className="table-container">
        <Table
          rowKey={record => record.id}
          columns={defaultColumns}
          dataSource={rating || []}
          pagination={false}
          locale={{ emptyText: t`common:table.emptyText` }}
        />
      </Space>
    </Row>
  );
})`
  background: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 0 0 -5.91rem;
  padding: 1rem 1rem 1rem 5.4rem;
  width: 100vw;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  .user-avatar-container {
    min-width: 4.75rem;

    .user-avatar {
      border: ${({ theme }) => `0.15rem solid ${theme.colors.white}`};
    }
  }

  .table-container {
    min-width: 0;
    flex: 1;

    .ant-space-item {
      width: 100%;
    }

    * {
      background: ${({ theme }) => theme.colors.transparent};
    }

    tbody td {
      border: none;
    }

    th, td {
      white-space: nowrap;
    }

    tr > td {
      text-align: center;
    }
  }

  .ant-table-thead > tr > th, td.ant-table-cell {
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ theme }) => theme.colors.transparent};
  }

  .ant-switch-handle {
    position: absolute;
    top: 1.7px;
  }

  .ant-switch {
    border: 1px solid #fff;
    height: 24px;
  }

  .ant-table-content {
    overflow-y: hidden;
  }
`;
