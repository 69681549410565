import * as React from "react";

function SvgDashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Group_1414"
      data-name="Group 1414"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="Group_1413" data-name="Group 1413">
        <g id="Group_1412" data-name="Group 1412">
          <path
            id="Path_420"
            data-name="Path 420"
            d="M10.156,17.187a.738.738,0,0,1,.781.781h0v6.25a.738.738,0,0,1-.781.781H.781A.738.738,0,0,1,0,24.219H0v-6.25a.738.738,0,0,1,.781-.781h9.375ZM24.219,12.5a.738.738,0,0,1,.781.781h0V24.219a.738.738,0,0,1-.781.781H14.844a.738.738,0,0,1-.781-.781h0V13.281a.738.738,0,0,1,.781-.781h9.375ZM9.375,18.75H1.562v4.688H9.375Zm14.063-4.687H15.625v9.375h7.813ZM10.156,0a.738.738,0,0,1,.781.781h0v12.5a.738.738,0,0,1-.781.781H.781A.738.738,0,0,1,0,13.281H0V.781A.738.738,0,0,1,.781,0h9.375ZM9.375,1.562H1.562V12.5H9.375ZM24.219,0A.738.738,0,0,1,25,.781h0V8.594a.738.738,0,0,1-.781.781H14.844a.738.738,0,0,1-.781-.781h0V.781A.738.738,0,0,1,14.844,0h9.375Zm-.781,1.563H15.625v6.25h7.813Z"
            transform="translate(0 0)"
            fill="#b0b0b0"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDashboardIcon;
