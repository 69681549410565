import * as React from "react";

function SvgGreenCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12.472'
      height='12.472'
      viewBox='0 0 12.472 12.472'
    >
      <circle
        id='Ellipse_500'
        data-name='Ellipse 500'
        cx='6.236'
        cy='6.236'
        r='6.236'
        fill='#55ae85'
      />
    </svg>
  );
}

export default SvgGreenCircleIcon;
