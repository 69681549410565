/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { Card, Col, Progress, Row } from "antd";
import { Spacer } from "components";
import { KpiToggle } from "components/kpi-toggle";
import { useTranslation } from "react-i18next";
import { advertDashboardKpi } from "services/advert";
import { userDashboardKpis } from "services/user";
import { transactionDashboardKpis } from "services/transaction";

export const DashboardKpis = () => {
  const { t } = useTranslation(["common", "dashboard"]);
  const [currentFilter, setCurrentFilter] = useState("week");
  const [allNewUserKpis, setAllNewUserKpis] = useState(null);
  const [currentNewUserKpi, setCurrentNewUserKpi] = useState(null);
  const [allNewTransactionKpis, setAllNewTransactionKpis] = useState(null);
  const [currentNewTransactionKpi, setCurrentNewTransactionKpi] =
    useState(null);
  const [allNewAdKpi, setAllNewAdKpi] = useState(null);
  const [currentAdKpi, setCurrentAdKpi] = useState(null);



  useEffect(() => {
    async function fetchData() {
      await retrieveAllData();
    }
    fetchData();
  }, []);

  const retrieveAllData = async() => {
    await retrieveNewUserKpis();
    await retrieveNewTransactionKpis();
    await retrieveTokenUseKpis();
  }


  const retrieveNewUserKpis = async() => {
    const response = await userDashboardKpis();
    if (response) {
      setAllNewUserKpis(response);
      if (currentFilter === "week") {
        setCurrentNewUserKpi(response?.week);
      }
    }
  };

  const retrieveNewTransactionKpis = async() => {

    const response = await transactionDashboardKpis();
    if (response) {
      setAllNewTransactionKpis(response);
      if (currentFilter === "week") {
        setCurrentNewTransactionKpi(response?.week);
      }
    }
  };

  const retrieveTokenUseKpis = async() => {

    const response = await advertDashboardKpi();
    if (response) {
      setAllNewAdKpi(response);
      if (currentFilter === "week") {
        setCurrentAdKpi(response?.week);
      }
    }
  };

  const handleChange = (e) => {
    setCurrentFilter(e.target.value);
    handleCurrentSelectionNewUserKpi(e.target.value);
  };

  const handleCurrentSelectionNewUserKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setCurrentNewUserKpi(allNewUserKpis?.month);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.month);
      setCurrentAdKpi(allNewAdKpi?.month);
    } else if (toggleValue === "year") {
      setCurrentNewUserKpi(allNewUserKpis?.year);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.year);
      setCurrentAdKpi(allNewAdKpi?.year);
    } else {
      setCurrentNewUserKpi(allNewUserKpis?.week);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.week);
      setCurrentAdKpi(allNewAdKpi?.week);
    }
  };

  const renderLocationProgressBar = (percentageUsed, strokeColor) => {
    const percent = +Number(percentageUsed ?? 0).toFixed(0);
    const config = {
      height: 16,
      percent,
      strokeColor: strokeColor,
    };

    return (
      <Col key={`loc-ad`} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div>
          <Progress {...config} />
        </div>
      </Col>
    );
  };

  return (
    <Card className={"dashboard-stats kpi-card"}>
      <Row
        gutter={[8, 8]}
        style={{
          marginBottom: 10,
        }}
      >
        <Col xs={24}>
          <div className={"d-flex flex-row justify-content-between"}>
            <h4 className="stats-header">{t`dashboard:kpi.title`}</h4>
            <div className="display-end">
              <KpiToggle
                opts={["week", "month", "year"]}
                value={currentFilter}
                handleChange={handleChange}
                color="salmon"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Card className="kpi-card">
                <div>
                  <h1 className="yellow-text">
                    {currentAdKpi ?? 0}
                  </h1>
                </div>
                <h3 className="blue-text">
                  {t(`dashboard:kpi.adCreated`, {
                    count: +currentAdKpi ?? 0,
                  })}
                </h3>
              </Card>
            </Col>
            <Col xs={24}>
              <Card className="kpi-card">
                <div>
                  <h1 className="yellow-text">
                    {currentNewTransactionKpi ?? 0}
                  </h1>
                </div>
                <h3 className="blue-text">
                  {t(`dashboard:kpi.newTransaction`, {
                    count: +currentNewTransactionKpi ?? 0,
                  })}
                </h3>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
          <Card className="kpi-card">
            <div>
              <h1 className="yellow-text">{currentNewUserKpi?.total ?? 0}</h1>
            </div>
            <h3 className="blue-text">
              {t(`dashboard:kpi.newUser`, {
                count: +currentNewUserKpi?.total ?? 0,
              })}
            </h3>
            <div className="kpi-additional-text-container">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="dashboard-kpi-total-text">{t`dashboard:kpi.neos`}</p>
                  <p className="dashboard-kpi-count-text">
                    {t("dashboard:kpi.seniors", {
                      count: +currentNewUserKpi?.neo ?? 0,
                    })}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="kpi-progress-style">
                    {renderLocationProgressBar(
                      currentNewUserKpi?.percentageNeo, "#0000FF"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
            <div className="kpi-additional-text-container">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="dashboard-kpi-total-text">{t`dashboard:kpi.pros`}</p>
                  <p className="dashboard-kpi-count-text">
                    {t("dashboard:kpi.professionals", {
                      count: +currentNewUserKpi?.pro ?? 0,
                    })}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="kpi-progress-style">
                    {renderLocationProgressBar(
                      currentNewUserKpi?.percentagePro, "#FFC764"
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Spacer space={20} />
    </Card>
  );
};

export default DashboardKpis;
