import React, { useState } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { useUpdateEffect } from "react-use";

const SwatchButton = styled((props) => {
  return <button {...props}></button>
})`
  height: 2rem;
  width: 2rem;
  height: 100%;
  border: none;
  border-radius: 0.25rem;
  background: ${({ color }) => color};
`;

export const CustomColorPicker = styled(({ className, initialColor, onChange = (v) => undefined, ...rest }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor);

  useUpdateEffect(() => {
    onChange(color);
  }, [color]);

  const handleChangeColor = (value) => setColor(value.hex);

  const closeColorPicker = () => setShowColorPicker(false);

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  return (
    <div className={className}>
      <SwatchButton color={color} onClick={toggleColorPicker} type="button" />
      {
        showColorPicker &&
        <div className="popover">
          <div className="cover" onClick={closeColorPicker}/>
          <ChromePicker color={color} onChange={handleChangeColor} />
        </div>
      }
    </div>
  );
})`
  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
