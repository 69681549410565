import * as React from "react";

function SvgAccountIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37.042"
      viewBox="0 0 37 37.042"
    >
      <g
        id="Group_2193"
        data-name="Group 2193"
        transform="translate(0.052 -0.15)"
      >
        <g id="Group_2183" data-name="Group 2183" transform="translate(0 0)">
          <g
            id="Ellipse_431"
            data-name="Ellipse 431"
            transform="translate(-0.051 0.151)"
            fill="none"
            stroke="#fff"
            strokeWidth="1.5"
          >
            <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
            <circle cx="18.5" cy="18.5" r="17.75" fill="none" />
          </g>
          <path
            id="Intersection_39"
            data-name="Intersection 39"
            d="M0,5.716C.1,1.738,5.046,0,9.419,0h6.625c4.373,0,10.763,2.977,9.908,5.206s-7.524,6.077-12.6,6.077S3.171,9.577,0,5.716Z"
            transform="translate(5.47 25.159)"
            fill="none"
            stroke="#fff"
            strokeWidth="1.5"
          />
          <g id="noun_avatar_2696029" transform="translate(11.072 6.062)">
            <g
              id="Ellipse_430"
              data-name="Ellipse 430"
              fill="#003466"
              stroke="#fff"
              strokeWidth="1.5"
            >
              <circle cx="7.412" cy="7.412" r="7.412" stroke="none" />
              <circle cx="7.412" cy="7.412" r="8.162" fill="none" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAccountIcon;
