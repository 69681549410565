import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

// const authorizationHeader = `Bearer ${localStorage.getItem("authKey")}`;
const axiosCustom = axios.create();

interface payload {
  [key: string]: unknown;
}

const authHeader = () => {
  return `Bearer ${localStorage.getItem("authKey")}`;
}

// axiosCustom.interceptors.response.use(handleSuccess, handleError)
// const resInterceptor = axiosCustom.interceptors.response.use(
//   (response) => {
//     console.log('INTERCEPTIOR: >>> ', response);
//     return response;
//   }, 
//   // (error) => {
//   //   // console.log('INTERCEPTOR ERROR >>>>>> ', error);
//   //   // const ttt = 'rrr';
//   //   // return ttt;
//   // }
// )

// axios.interceptors.request.eject(resInterceptor);


export const get = (path: string): Promise<any> =>
  new Promise((resolve, reject) => {
    axiosCustom
      .get(path, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });


export const post = (
  path: string,
  payload: payload | FormData,
  headers = {}
): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .post(path, payload, {
        headers: {
          ...headers,
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const postImages = (
  path: string,
  payload: payload | FormData,
  headers = {}
): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .post(path, payload, {
        headers: {
          Authorization: authHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const patch = (path: string, payload: payload): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .patch(path, payload, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const put = (path: string, payload: payload): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .put(path, payload, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

  export const del = (path: string, payload: payload = {}): Promise<any> =>
  new Promise((resolve, reject) => {
      axios
        .delete(path, {
          headers: {
            Authorization: authHeader(),
          },
        })
        .then((res) => resolve(res?.data))
        .catch((e) => reject(e));
  });