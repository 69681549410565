import { get, patch } from "./axios-service";

export async function getRatingList(queryString) {

  let params = '?filter=' + JSON.stringify(queryString);

  const response = await get(`admin/ratings${params}`);
  return response;
}

export async function getUserRatings(user_id, eval_type) {
  let query = {
    "where": {
      "eval_type": eval_type
    }
  }
  let params = '?filter=' + JSON.stringify(query);

  const response = await get(`admin/ratings/user/${user_id}/list${params}`,);
  return response;
}

export async function getAdminSummaryById(id) {

  const response = await get(`admin/rating/summary/${id}`);
  return response;
}

export async function getRatingById(id) {

  const response = await get(`admin/rating/${id}`);
  return response;
}


export const updateRating = async (id, payload) => {

  try {
    const response = await patch(`admin/rating/${id}`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function exportRatings() {
  const result = {
    response: null,
    error: null
  };

  try {
    const response = await get('/admin/rating/export');
    result.response = response;
    return result;
  } catch (error) {
    result.error = error;
    return result;
  }
}