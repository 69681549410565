import React from "react";
import styled from "styled-components";
import { message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { useRef } from "react";
import { FILE_UPLOAD_STATUS_DONE } from "../../constants";

export const FileUploader = styled(
  ({
    acceptMultipleFiles = false,
    acceptedFileExts = [".png", ".jpg", ".jpeg"],
    cropProps = {
      rotate: true,
    },
    maxFileSize = 2 * 1024 * 1024,
    onFileBrowsed,
    uploadFile,
    includeCropper = false,
    uploadRef = useRef(),
    children,
    ...rest
  }) => {
    const { t, i18n } = useTranslation(["common"]);
    const accept = acceptedFileExts.join(",");

    const handleBeforeUpload = (fileObj) => {
      const imageIsTooLarge = fileObj.size > maxFileSize;

      if (imageIsTooLarge) {
        message.error(
          t("common:fileTooLarge", {
            size: `${maxFileSize / 1024 / 1024}MB`,
          }),
          10
        );
      }

      return includeCropper;
    };

    const handleUploadFile = ({ file }) =>
      new Promise((resolve, reject) => {
        if (!includeCropper || file.status === FILE_UPLOAD_STATUS_DONE) {
          if (file.size <= maxFileSize) {
            let base64;
            const fileObj = includeCropper ? file.originFileObj : file;
            const blob = new Blob([fileObj]);
            const fileReader = new FileReader();

            // On file load, read its contents
            fileReader.onload = (fileLoadedEvent) => {
              if (!includeCropper) {
                base64 = fileLoadedEvent.target.result;
              }
            };

            // display browsed image
            fileReader.onloadend = function (e) {
              if (includeCropper) {
                base64 = fileReader.result;
              }

              resolve(
                uploadFile({
                  blob,
                  base64,
                  file,
                })
              );
            };

            // Convert data to base64
            fileReader.readAsDataURL(blob);
          } else {
            resolve(
              uploadFile({
                blob: null,
                base64: null,
                file,
              })
            );
          }
        }
      });

    const renderSimpleUploader = () => (
      <Upload
        accept={accept}
        multiple={acceptMultipleFiles}
        beforeUpload={onFileBrowsed || handleBeforeUpload}
        onChange={handleUploadFile}
        {...rest}
      >
        <button ref={uploadRef} className="browse-btn" />
        {children}
      </Upload>
    );

    const renderCropUploader = () => (
      <ImgCrop {...cropProps}>{renderSimpleUploader()}</ImgCrop>
    );

    return includeCropper ? renderCropUploader() : renderSimpleUploader();
  }
)`
  .browse-btn {
    display: none;
  }
`;
