import React from "react";
import styled from "styled-components";
import { Avatar, Row, Space, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GreenCircleIcon, RedCircleIcon, UnfilledCircleIcon, WhiteCircleIcon } from "components/icons";
import { format } from "date-fns";
import { useTheme } from "styled-components";
import { LOCALE_MAP } from "../../constants";
import { fullImageUrl } from "utils";

export const UserInfoBanner = styled(({ columns, data, avatar, ...rest }) => {
  const { t, i18n } = useTranslation(["common", "users"]);
  const { colors } = useTheme();

  const defaultColumns = [
    {
      key: "id",
      title: t`users:columns.id`,
      dataIndex: "id",
      align: 'center' as 'center'
    },
    {
      key: "name",
      title: t`users:columns.name`,
      dataIndex: "fullname",
      align: 'left' as 'left',
    },
    {
      key: "email",
      title: t`users:columns.email`,
      dataIndex: "email",
      align: 'left' as 'left',
    },
    {
      key: "postalCode",
      title: t`users:columns.postalCode`,
      dataIndex: "postalCode",
      align: 'center' as 'center'
    },
    {
      key: "phone",
      title: t`users:columns.phone`,
      dataIndex: "phoneNumber",
      align: 'left' as 'left',
    },
    {
      key: "userGroup",
      title: t`users:columns.userGroup`,
      dataIndex: "role",
      render: (tag) => {
        let role = tag === "NEO" ? "Neo" : "Professionnel";
        return (
          <>
            <div
              style={{
                width: "150px",
                height: "30px",
                backgroundColor: colors.transparent,
                borderRadius: "25px",
                border: `1px solid ${colors.white}`,
                color: colors.white,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span style={{ paddingLeft: 10 }}>{role}</span>
              <span style={{ paddingRight: 10 }}>
                <WhiteCircleIcon />
              </span>
            </div>
          </>
        );
      },
    },
    {
      key: "upToDate",
      title: t`users:columns.upToDate`,
      dataIndex: "role",
      align: 'center' as 'center',
      render: (tag, record) => (
        <>
         {tag === "NEO" ? <UnfilledCircleIcon /> : record.isUpdated ?  <GreenCircleIcon /> : <RedCircleIcon />}
        </>
      ),
    },
    {
      key: "ads",
      title: t`users:columns.ads`,
      dataIndex: "role",
      align: 'center' as 'center',
      render: (text, record) => (
        <>
         {text === "NEO" ? <UnfilledCircleIcon /> : record?.advertCount}
        </>
      ),
    },
    {
      key: "mutual",
      title: t`users:columns.mutual`,
      dataIndex: "mutualMembershipCode",
    },
    {
      key: "createdAt",
      title: t`users:columns.createdAt`,
      dataIndex: "createdAt",
      align: 'center' as 'center',
      render: (date: string) => date && `${format(new Date(date), "dd/MM/yyyy", { locale: LOCALE_MAP[i18n.language] })} ${t("common:at")} ${format(new Date(date), "p", { locale: LOCALE_MAP[i18n.language] })}`,
    },
    {
      key: "neoManager",
      title: t`users:columns.neoManager`,
      dataIndex: "adminUser",
      // align: 'center' as 'center',
      render: (adminUser) => {
        return (
          <>
            <div
              style={{
                width: "150px",
                height: "30px",
                backgroundColor: colors.transparent,
                borderRadius: "25px",
                border: `1px solid ${colors.white}`,
                color: colors.white,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {adminUser}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Row {...rest} align="middle">
      <Space className="user-avatar-container">
        <Avatar size={76} src={avatar} className="user-avatar" icon={<UserOutlined />} />
      </Space>
      <Space className="table-container">
        <Table
          rowKey={record => record.id}
          columns={defaultColumns}
          dataSource={data || []}
          pagination={false}
          locale={{ emptyText: t`common:table.emptyText` }}
        />
      </Space>
    </Row>
  );
})`
  background: ${({ theme, variant = "salmon" }) => theme.colors[variant]};
  margin: 0 0 0 -5.91rem;
  padding: 1rem 1rem 1rem 5.4rem;
  width: 100vw;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  .user-avatar-container {
    min-width: 4.75rem;

    .user-avatar {
      border: ${({ theme }) => `0.15rem solid ${theme.colors.white}`};
    }
  }

  .table-container {
    min-width: 0;
    flex: 1;

    .ant-space-item {
      width: 100%;
    }

    * {
      background: ${({ theme }) => theme.colors.transparent};
    }

    tbody td {
      border: none;
    }

    th, td {
      white-space: nowrap;
    }

    tr > td {
      text-align: center;
    }
  }

  .ant-table-thead > tr > th, td.ant-table-cell {
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ theme }) => theme.colors.transparent};
  }

  .ant-table-content {
    overflow-y: hidden;
  }
`;
