import * as React from "react";

function SvgNeoSilverLogoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="21.6875rem"
      // height="2.03rem"
      viewBox="0 0 239.028 26.131"
      style={props.style || {}}
    >
      <g id="Group_1115" data-name="Group 1115" transform="translate(0)">
        <path
          id="Path_206"
          data-name="Path 206"
          d="M-979.921,97.818l-8.661-15.693V97.818h-5.552V72.649h5.552l8.661,15.694V72.649h5.551V97.818Z"
          transform="translate(994.134 -72.279)"
          fill="#003466"
        />
        <path
          id="Path_207"
          data-name="Path 207"
          d="M-889.393,97.963a13.214,13.214,0,0,1-12.955-12.955,13.214,13.214,0,0,1,12.955-12.954A13.213,13.213,0,0,1-876.44,85.009,13.214,13.214,0,0,1-889.393,97.963Zm0-20.394a7.677,7.677,0,0,0-7.4,7.4,7.71,7.71,0,0,0,7.4,7.439,7.71,7.71,0,0,0,7.4-7.439A7.677,7.677,0,0,0-889.393,77.57Z"
          transform="translate(959.459 -72.055)"
          fill="#003466"
        />
        <path
          id="Path_208"
          data-name="Path 208"
          d="M-829.585,90.709c0-4.886-14.546-1.74-14.546-11.029,0-5.182,4.812-7.625,9.4-7.625,5.182,0,9.845,2.295,10.03,8.032h-5.367c-.3-2.628-1.814-3.479-4.589-3.479-1.554,0-3.738.74-3.738,2.628,0,4.478,14.546,1.258,14.546,10.845,0,5.922-5.293,8.106-10.438,8.106-5.552,0-10.623-2.739-10.66-8.883h5.626c.148,3,2.517,4.367,5.256,4.367C-832.323,93.67-829.585,92.967-829.585,90.709Z"
          transform="translate(937.773 -72.055)"
          fill="#003466"
        />
        <path
          id="Path_209"
          data-name="Path 209"
          d="M-793.788,97.818V72.649h5.552V97.818Z"
          transform="translate(918.446 -72.279)"
          fill="#003466"
        />
        <path
          id="Path_210"
          data-name="Path 210"
          d="M-765.83,72.649h5.552V92.784h9.031v5.034H-765.83Z"
          transform="translate(907.884 -72.279)"
          fill="#003466"
        />
        <path
          id="Path_211"
          data-name="Path 211"
          d="M-723.573,97.818h-4.774l-10.178-25.169h6l5.182,14.139,1.369,3.96,1.37-3.96,5.219-14.139h6Z"
          transform="translate(897.569 -72.279)"
          fill="#003466"
        />
        <path
          id="Path_212"
          data-name="Path 212"
          d="M-634.347,97.818H-639.9V72.649h9.438a7.8,7.8,0,0,1,7.7,8.069c0,4.257-2.554,7.07-5.737,7.847l7.217,9.253h-7.032l-6-8.994h-.037Zm0-20.8v7.439h2.961a3.471,3.471,0,0,0,3.331-3.738,3.506,3.506,0,0,0-3.331-3.7Z"
          transform="translate(860.31 -72.279)"
          fill="#003466"
        />
        <rect
          id="Rectangle_384"
          data-name="Rectangle 384"
          width="14.583"
          height="5.034"
          transform="translate(31.609 20.505)"
          fill="#3e9363"
        />
        <rect
          id="Rectangle_385"
          data-name="Rectangle 385"
          width="14.583"
          height="5.034"
          transform="translate(31.609 0.37)"
          fill="#ffb93e"
        />
        <rect
          id="Rectangle_386"
          data-name="Rectangle 386"
          width="14.213"
          height="5.048"
          transform="translate(31.609 10.386)"
          fill="#f7a58d"
        />
        <rect
          id="Rectangle_387"
          data-name="Rectangle 387"
          width="14.583"
          height="5.034"
          transform="translate(193.984 20.505)"
          fill="#003466"
        />
        <rect
          id="Rectangle_388"
          data-name="Rectangle 388"
          width="14.583"
          height="5.034"
          transform="translate(193.984 0.37)"
          fill="#003466"
        />
        <rect
          id="Rectangle_389"
          data-name="Rectangle 389"
          width="14.213"
          height="5.048"
          transform="translate(193.984 10.386)"
          fill="#003466"
        />
        <path
          id="Path_213"
          data-name="Path 213"
          d="M-718.36,90.749l2.406,7.069,10.178-25.169h-6Z"
          transform="translate(889.951 -72.28)"
          fill="#022c49"
        />
        <path
          id="Path_214"
          data-name="Path 214"
          d="M-985.211,72.649v9.475l8.661,15.693V88.343Z"
          transform="translate(990.763 -72.28)"
          fill="#022c49"
        />
        <path
          id="Path_215"
          data-name="Path 215"
          d="M-881.528,72.055a13.213,13.213,0,0,1,12.954,12.954,13.214,13.214,0,0,1-12.954,12.955m0-5.552a7.71,7.71,0,0,0,7.4-7.439,7.677,7.677,0,0,0-7.4-7.4"
          transform="translate(951.593 -72.055)"
          fill="#022c49"
        />
      </g>
    </svg>
  );
}

export default SvgNeoSilverLogoIcon;
