import styled from "styled-components";
import { Avatar, Row, Space, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFullDateWithTime } from "utils/validators";
import { format } from "date-fns";
import { fullImageUrl } from "utils";

export const TransactionInfoBanner = styled(({ columns, data, transaction, ...rest }) => {
  const { t, i18n } = useTranslation(["common", "transaction"]);

  const defaultColumns = [
    {
      key: "Commandes",
      title: t`transaction:table.headers.order`,
      dataIndex: "bookingReference",
    },
    {
      key: "Client",
      title: t`transaction:table.headers.client`,
      dataIndex: "buyerFirstname",
      align: "center" as "center",
    },
    {
      key: "Vendeur",
      title: t`transaction:table.headers.seller`,
      dataIndex: "sellerFirstname",
      align: "center" as "center",
    },
    {
      key: "Amount",
      title: t`transaction:table.headers.total`,
      dataIndex: "grossAmount",
      align: "center" as "center",
    },
    {
      key: "Statut",
      title: t`transaction:table.headers.status`,
      dataIndex: "status",
      align: "center" as "center",
      render: (status: string) => createStatusTag(status),
    },
    {
      key: "Date",
      title: t`transaction:table.headers.activityDate`,
      dataIndex: "activityDateTime",
      render: (date: string) => date ? format(new Date(date), "dd/MM/yyyy") : '',
      align: "center" as "center",
    },
    {
      key: "Time",
      title: t`transaction:table.headers.activityTime`,
      dataIndex: "activityDateTime",
      render: (date: string) => date ? format(new Date(date), "HH:mm") : '',
      align: "center" as "center",
    },
    {
      key: "OrderDate",
      title: t`transaction:table.headers.orderDate`,
      dataIndex: "transactionDateTime",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Neo",
      title: t`transaction:table.headers.responsibleNeo`,
      dataIndex: "adminNeoFirstName",
      align: "center" as "center",
    },
  ];

  const createStatusTag = (status) => {

    let displayValue = status;

    if (status === "CREATED") {
      displayValue = t("transaction:status.created");
    }
    else if (status === "PAID") {
      displayValue = t("transaction:status.paid");
    }
    else if (status === "CONFIRMED") {
      displayValue = t("transaction:status.confirmed");
    }
    else if (status === "REFUSED") {
      displayValue = t("transaction:status.refused");
    }
    else if (status === "ABANDONNED") {
      displayValue = t("transaction:status.abandonned");
    }
    else if (status === "PAYMENT_FAILED") {
      displayValue = t("transaction:status.paymentFailed");
    }
    else if (status === "WAITING_FOR_CONFIRMATION") {
      displayValue = t("transaction:status.waitingForConfirmation");
    }

    return displayValue;

  }


  return (
    <Row {...rest} align="middle">
      <Space className="user-avatar-container">
        <Avatar size={76} src={transaction && transaction[0] ? fullImageUrl(transaction[0]?.imageUrls, 'sm') : null} className="user-avatar" icon={<UserOutlined />} />
      </Space>
      <Space className="table-container">
        <Table
          rowKey={record => record.id}
          columns={defaultColumns}
          dataSource={transaction || []}
          pagination={false}
          locale={{ emptyText: t`common:table.emptyText` }}
        />
      </Space>
    </Row>
  );
})`
  background: ${({ theme }) => theme.colors.salmon};
  margin: 0 0 0 -5.91rem;
  padding: 1rem 1rem 1rem 5.4rem;
  width: 100vw;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  .user-avatar-container {
    min-width: 4.75rem;

    .user-avatar {
      border: ${({ theme }) => `0.15rem solid ${theme.colors.white}`};
    }
  }

  .table-container {
    min-width: 0;
    flex: 1;

    .ant-space-item {
      width: 100%;
    }

    * {
      background: ${({ theme }) => theme.colors.transparent};
    }

    tbody td {
      border: none;
    }

    th, td {
      white-space: nowrap;
    }

    tr > td {
      text-align: center;
    }
  }

  .ant-table-thead > tr > th, td.ant-table-cell {
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ theme }) => theme.colors.transparent};
  }

  .ant-switch-handle {
    position: absolute;
    top: 1.7px;
  }

  .ant-switch {
    border: 1px solid #fff;
    height: 24px;
  }
  
  .ant-table-content {
    overflow-y: hidden;
  }
`;
