import { createGlobalStyle } from "styled-components";

const AccountActivationStyle = createGlobalStyle`
.content-container {
  padding: 0 0 2rem 0;
  background-color: white;
}

.ant-card {
  max-width: 61rem;
  width: 100%;
  margin: 2rem auto 0 auto;
  box-shadow: var(--box-shadow);
}

.page-description-container {
  max-width: 62.5rem;
  margin: auto;
  padding: 1.3125rem 1rem 0 1rem;

  .page-description-title {
    font-family: var(--font-glacial);
    font-size: 1.75rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.shades.grey[8]};
    width: 100%;
    display: block;
    text-transform: uppercase;
  }

  .page-description-subtitle {
    font-family: var(--font-gilroy);
    font-size: 1.0625rem;
    font-weight: 200;
    max-width: 78ch;
    line-height: 1.2352941176470589em;
    color: ${({ theme }) => theme.colors.shades.grey[17]};
  }
}

.content-paragraph-container {
  .content-paragraph {
    color: ${({ theme }) => theme.colors.shades.grey[25]};
    font-size: 1.75rem;
    font-family: var(--font-glacial);
    font-weight: 200;
    margin-bottom: 2rem;
  }

  .content-title {
    font-family: var(--font-glacial);
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: 800;
  }
}

.svg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .confirm-email-svg {
    max-width: 34.06rem;
    width: 100%;
  }
}

.anchor-text {
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  font-size: 1.75rem;
  font-family: var(--font-glacial);
  font-weight: 200;
}`;

export default AccountActivationStyle;
