import * as React from "react";

function SvgParameterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.3"
      height="23"
      viewBox="0 0 28.3 23"
    >
      <g
        id="Group_1437"
        data-name="Group 1437"
        transform="translate(-3.474 -2.084)"
      >
        <g
          id="Group_1478"
          data-name="Group 1478"
          transform="translate(3.624 2.084)"
        >
          <path
            id="Path_396"
            data-name="Path 396"
            d="M83.128,49.322H81.186a.562.562,0,1,1,0-1.122h1.942a.567.567,0,0,1,.586.561A.59.59,0,0,1,83.128,49.322Z"
            transform="translate(-55.715 -37.085)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_397"
            data-name="Path 397"
            d="M23.752,49.322H7.924a.562.562,0,0,1,0-1.122H23.785a.537.537,0,0,1,.524.561A.615.615,0,0,1,23.752,49.322Z"
            transform="translate(-7.4 -37.085)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_398"
            data-name="Path 398"
            d="M63.808,47.021a3.264,3.264,0,1,1,3.408-3.261A3.33,3.33,0,0,1,63.808,47.021Z"
            transform="translate(-42.917 -32.085)"
            fill={props.fill?? "#828282"}
          />
          <path
            id="Path_454"
            data-name="Path 454"
            d="M81.186,49.322h1.942a.562.562,0,1,0,0-1.122H81.186a.567.567,0,0,0-.586.561A.59.59,0,0,0,81.186,49.322Z"
            transform="translate(-80.6 -29.021)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_455"
            data-name="Path 455"
            d="M7.955,49.322H23.714a.562.562,0,0,0,0-1.122H7.922a.536.536,0,0,0-.522.561A.614.614,0,0,0,7.955,49.322Z"
            transform="translate(3.764 -29.021)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_456"
            data-name="Path 456"
            d="M63.808,47.021A3.264,3.264,0,1,0,60.4,43.761,3.33,3.33,0,0,0,63.808,47.021Z"
            transform="translate(-56.699 -24.021)"
            fill={props.fill?? "#b0b0b0"}
          />
          <g
            id="Path_457"
            data-name="Path 457"
            transform="translate(-56.333 -23.66)"
            fill="#fff"
          >
            <path
              d="M 63.43568801879883 45.8089599609375 C 62.75994491577148 45.8089599609375 62.12195587158203 45.55459976196289 61.63922500610352 45.09273147583008 C 61.16252517700195 44.63663864135742 60.89999771118164 44.03707122802734 60.89999771118164 43.40447998046875 C 60.89999771118164 42.07865142822266 62.03750610351562 41 63.43568801879883 41 C 64.83385467529297 41 65.97135925292969 42.07865142822266 65.97135925292969 43.40447998046875 C 65.97135925292969 44.73030853271484 64.83385467529297 45.8089599609375 63.43568801879883 45.8089599609375 Z"
              stroke="none"
            />
            <path
              d="M 63.43568801879883 45.3089599609375 C 64.55815887451172 45.3089599609375 65.47135925292969 44.45460891723633 65.47135925292969 43.40447998046875 C 65.47135925292969 42.35435104370117 64.55815887451172 41.5 63.43568801879883 41.5 C 62.31320571899414 41.5 61.39999771118164 42.35435104370117 61.39999771118164 43.40447998046875 C 61.39999771118164 44.43680953979492 62.33222579956055 45.3089599609375 63.43568801879883 45.3089599609375 M 63.43568801879883 46.3089599609375 C 61.77094650268555 46.3089599609375 60.39999771118164 44.99724960327148 60.39999771118164 43.40447998046875 C 60.39999771118164 41.81169891357422 61.73829650878906 40.5 63.43568801879883 40.5 C 65.10040283203125 40.5 66.47135925292969 41.78047943115234 66.47135925292969 43.40447998046875 C 66.47135925292969 44.99724960327148 65.133056640625 46.3089599609375 63.43568801879883 46.3089599609375 Z"
              stroke="none"
              fill={props.fill?? "#b0b0b0"}
            />
          </g>
          <path
            id="Path_458"
            data-name="Path 458"
            d="M81.186,49.322h6.527a.562.562,0,1,0,0-1.122H81.186a.567.567,0,0,0-.586.561A.59.59,0,0,0,81.186,49.322Z"
            transform="translate(-80.6 -45.5)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_459"
            data-name="Path 459"
            d="M7.821,49.322H19.779c.223,0,.4-.245.4-.561s-.173-.561-.4-.561H7.8c-.223,0-.4.245-.4.561C7.425,49.041,7.6,49.322,7.821,49.322Z"
            transform="translate(7.825 -45.5)"
            fill={props.fill?? "#b0b0b0"}
            stroke={props.stroke?? "#b0b0b0"}
            strokeWidth="0.3"
          />
          <path
            id="Path_460"
            data-name="Path 460"
            d="M63.808,47.021A3.264,3.264,0,1,0,60.4,43.761,3.33,3.33,0,0,0,63.808,47.021Z"
            transform="translate(-52.645 -40.5)"
            fill={props.fill?? "#b0b0b0"}
          />
          <g
            id="Path_461"
            data-name="Path 461"
            transform="translate(-52.286 -40.138)"
            fill="#fff"
          >
            <path
              d="M 63.43568801879883 45.8089599609375 C 62.75994491577148 45.8089599609375 62.12195587158203 45.55459976196289 61.63922500610352 45.09273147583008 C 61.16252517700195 44.63663864135742 60.89999771118164 44.03707122802734 60.89999771118164 43.40447998046875 C 60.89999771118164 42.07865142822266 62.03750610351562 41 63.43568801879883 41 C 64.83385467529297 41 65.97135925292969 42.07865142822266 65.97135925292969 43.40447998046875 C 65.97135925292969 44.73030853271484 64.83385467529297 45.8089599609375 63.43568801879883 45.8089599609375 Z"
              stroke="none"
            />
            <path
              d="M 63.43568801879883 45.3089599609375 C 64.55815887451172 45.3089599609375 65.47135925292969 44.45460891723633 65.47135925292969 43.40447998046875 C 65.47135925292969 42.35435104370117 64.55815887451172 41.5 63.43568801879883 41.5 C 62.31320571899414 41.5 61.39999771118164 42.35435104370117 61.39999771118164 43.40447998046875 C 61.39999771118164 44.43680953979492 62.33222579956055 45.3089599609375 63.43568801879883 45.3089599609375 M 63.43568801879883 46.3089599609375 C 61.77094650268555 46.3089599609375 60.39999771118164 44.99724960327148 60.39999771118164 43.40447998046875 C 60.39999771118164 41.81169891357422 61.73829650878906 40.5 63.43568801879883 40.5 C 65.10040283203125 40.5 66.47135925292969 41.78047943115234 66.47135925292969 43.40447998046875 C 66.47135925292969 44.99724960327148 65.133056640625 46.3089599609375 63.43568801879883 46.3089599609375 Z"
              stroke="none"
              fill={props.fill?? "#b0b0b0"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgParameterIcon;
