import * as React from "react";

function SvgTransactionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="20"
      viewBox="0 0 30 20"
    >
      <g
        id="Group_1438"
        data-name="Group 1438"
        transform="translate(-3.148 -3.295)"
      >
        <g
          id="Group_1477"
          data-name="Group 1477"
          transform="translate(3.148 3.295)"
        >
          <path
            id="Path_449"
            data-name="Path 449"
            d="M116,171.77c0-.017,0-.034-.007-.051s0-.009,0-.013c0-.021-.009-.041-.015-.061h0q-.009-.03-.02-.059h0a.562.562,0,0,0-.025-.054l0,0c-.008-.016-.017-.031-.026-.046s-.02-.03-.03-.044l-.011-.015-.026-.031-.014-.016,0,0-2.911-3.02a.6.6,0,0,0-.874.006.689.689,0,0,0,.006.93l1.84,1.921h-7.937a7.956,7.956,0,0,0-9.871,0h-7.5a.65.65,0,0,0,0,1.3h6.167a8.644,8.644,0,0,0-2.013,5.662,8.851,8.851,0,0,0,2.007,5.652H88.125l1.84-1.9a.665.665,0,0,0,.006-.912.6.6,0,0,0-.874,0l-2.911,3.022h0a.662.662,0,0,0-.18.4c0,.016,0,.032,0,.048s0,.011,0,.017h0c0,.022,0,.043,0,.065s0,.034.007.051,0,.008,0,.012q.006.031.015.061h0a.667.667,0,0,0,.045.114l0,0c.008.016.018.031.026.046a.458.458,0,0,0,.028.041l.012.017.026.031.014.016,0,0,2.911,3.02a.6.6,0,0,0,.874-.006.689.689,0,0,0-.006-.93l-1.84-1.921h7.937a7.956,7.956,0,0,0,9.871,0h7.5a.65.65,0,0,0,0-1.3h-6.167a8.644,8.644,0,0,0,2.013-5.662,8.851,8.851,0,0,0-2.007-5.652h6.605l-1.84,1.9a.667.667,0,0,0-.137.7.612.612,0,0,0,.571.4.6.6,0,0,0,.434-.189l2.911-3.021h0a.661.661,0,0,0,.18-.4c0-.016,0-.032,0-.048s0-.011,0-.017h0Q116,171.8,116,171.77ZM105.217,184.1a6.779,6.779,0,0,1-8.738-.256,7.663,7.663,0,0,1-1.775-9,6.939,6.939,0,0,1,7.92-3.887,7.339,7.339,0,0,1,5.423,7.205A7.471,7.471,0,0,1,105.217,184.1Z"
            transform="translate(-86.003 -168.166)"
            fill="#b0b0b0"
          />
          <path
            id="Path_450"
            data-name="Path 450"
            d="M206.258,223.188a3.07,3.07,0,0,1,1.922.661.6.6,0,0,0,.869-.134.675.675,0,0,0-.127-.913,4.286,4.286,0,0,0-4.017-.683,4.582,4.582,0,0,0-2.874,3.028h-.351a.65.65,0,0,0,0,1.3h.139a1.288,1.288,0,0,0,0,.185h-.139a.65.65,0,0,0,0,1.3h.351a4.57,4.57,0,0,0,2.874,3.031,4.275,4.275,0,0,0,4.018-.695.668.668,0,0,0,.12-.9.6.6,0,0,0-.861-.126,3.1,3.1,0,0,1-2.706.545,3.27,3.27,0,0,1-2.119-1.85h3.424a.65.65,0,0,0,0-1.3h-3.726a1.279,1.279,0,0,1,0-.185h4.5a.65.65,0,0,0,0-1.3h-4.2A3.128,3.128,0,0,1,206.258,223.188Z"
            transform="translate(-190.909 -216.908)"
            fill="#b0b0b0"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgTransactionIcon;
