import * as React from "react";

function SvgMaintenanceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.925"
      height="37.236"
      viewBox="0 0 39.925 37.236"
    >
      <g id="noun_maintenance_3370538" transform="translate(0.327 0.25)">
        <path
          id="Path_1445"
          data-name="Path 1445"
          d="M20.8,35.29l-4.723,0-.506-4.045-.421-.12a11.922,11.922,0,0,1-4.891-2.828l-.314-.3L6.2,29.578,3.839,25.49l3.255-2.472-.1-.421a12.092,12.092,0,0,1-.358-2.811,12.006,12.006,0,0,1,.358-2.822l.1-.422L3.837,14.076,6.2,9.989l3.757,1.587.314-.3a11.926,11.926,0,0,1,4.888-2.828l.418-.121.506-4.043h4.725l.5,4.046.418.121a11.793,11.793,0,0,1,4.885,2.822l.314.3,3.761-1.584,2.36,4.092-2.96,2.242a8.938,8.938,0,0,1,1.2.814c-.006-.02-.006-.038-.011-.058l3.5-2.646L31.249,8.316l-4.04,1.7A13.169,13.169,0,0,0,22.516,7.3l-.539-4.349H14.915L14.368,7.3A13.255,13.255,0,0,0,9.675,10.02L5.638,8.313,2.1,14.425l3.5,2.652a12.961,12.961,0,0,0-.3,2.712,13.2,13.2,0,0,0,.3,2.7l-3.5,2.653,3.529,6.113,4.035-1.705a13.241,13.241,0,0,0,4.7,2.715l.548,4.347,7.061,0,.517-4.176a8.968,8.968,0,0,1-1.262-.58Z"
          transform="translate(-2.103 -2.956)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <path
          id="Path_1446"
          data-name="Path 1446"
          d="M15.845,11.017a5.98,5.98,0,0,1,4.291,1.818l.058-.02a9.024,9.024,0,0,1,1.391-.337A7.337,7.337,0,1,0,14.56,24.254a9.012,9.012,0,0,1-.369-1.463,6,6,0,0,1,1.653-11.775Z"
          transform="translate(0.505 -0.205)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <path
          id="Path_1447"
          data-name="Path 1447"
          d="M35.325,29.967l-8.6-8.6a6.272,6.272,0,0,0-1.636-6.056,6.37,6.37,0,0,0-6.447-1.524l-1.018.337,4.17,4.171-.56,2.084-2.083.558L14.98,16.764l-.335,1.022a6.286,6.286,0,0,0,1.521,6.446,6.359,6.359,0,0,0,6.06,1.636l8.6,8.6a3.183,3.183,0,1,0,4.5-4.5Zm-.935,3.566a1.909,1.909,0,0,1-2.635,0l-9.162-9.166-.387.13a5.037,5.037,0,0,1-5.1-1.2,4.953,4.953,0,0,1-1.438-3.973l3.091,3.09,3.559-.955.955-3.562-3.086-3.088a4.979,4.979,0,0,1,5.171,6.539l-.13.387L34.389,30.9A1.862,1.862,0,0,1,34.39,33.532Z"
          transform="translate(2.885 1.338)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <path
          id="Path_1448"
          data-name="Path 1448"
          d="M26.988,26.133a.965.965,0,1,0,1.365,0A.964.964,0,0,0,26.988,26.133Z"
          transform="translate(7.941 6.391)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}

export default SvgMaintenanceIcon;
